import { useGeoMap } from "../../context/GeoMapContext";
import { LightPoint } from "../../models";
import { useApiClient } from "../apiClient/apiClient";
import { ApiItemPath, useApiItemPath } from "../apiClient/apiItem";
import { LightPointRequestQuery, LightPointQueryResponse } from "./lightPointsApi.types";

export function useLightPointsApi() {
  const { 
    allLightPoints, 
    setMapIsLoading,
    center,
    setCenter,
    setNewLightPoints
  } = useGeoMap();
  const { get, post } = useApiClient();
  const { leuchtstellen } = useApiItemPath();

  async function getLightPointsByKommune() {
    return fetchLightPoints(get, leuchtstellen.byKommune);
  }

  async function getLightPointsByQuery(query?: LightPointRequestQuery) {
    return fetchLightPoints(post, leuchtstellen.byQuery, query);
  }

  type Get = typeof get;
  type Post = typeof post;
  async function fetchLightPoints(clbk: Get | Post, url: ApiItemPath, body?: LightPointRequestQuery): Promise<LightPoint[]> {
    if (body?.nummer) {
      const l = allLightPoints.filter((al) => sameLightingNo(body.nummer!, al.nummer));
      if (l.length) return l;
    }
    try {
      setMapIsLoading(true);
      let res: LightPointQueryResponse;
      if (body) {
        res = await (clbk as Post)({url, body});
      } else {
        res = await (clbk as Get)({url});
      }
      if (res.zentrumLocation && !center) {
        setCenter({ lat: res.zentrumLocation.latitude, lng: res.zentrumLocation.longitute });
      }
      setNewLightPoints(res.leuchtstellen);
      return res.leuchtstellen;
    } catch (e) {
      console.error("Error Fetching LightPoints", e);
      throw new Error("Leuchtstellen konnten nicht geladen werden");
    } finally {
      setMapIsLoading(false);
    }
  }

  return { 
    getLightPointsByKommune, 
    getLightPointsByQuery 
  }
}

export const sameLightingNo = (n1: string, n2: string) => {
  const paramsSorted = [n1, n2].sort((a,b) => a.length - b.length);
  n1 = paramsSorted[0]; n2 = paramsSorted[1];
  n2 = n2.slice(n2.length - n1.length, n2.length);
  return n1 === n2;
};