import { WithId } from ".";

export enum ArtikelKategorie {
  Leuchtstellen = "Leuchtstellen",
  Sicherheitspruefung = "Sicherheitspruefung"
}

export interface Artikel extends BasicDetail {
  id: ArtikelId;
  bestellbar: boolean;
  kategorie: ArtikelKategorie;
}

export type ArtikelId = "MT" | "MV" | "MH" | "SSP";

export interface ArtikelDetails extends Artikel, ArtikelDetailsProps { }

export interface ArtikelDetailsProps {
  optionen: ArtikelOption[];
  eigenschaften?: ArtikelEigenschaft[];
  parameter: ArtikelParameter[];
}

export interface ArtikelOption extends WithId, ArtikelAuswahl {
  auswahlen: ArtikelAuswahl[]
}

export interface ArtikelAuswahl extends BasicDetail, WithId {
  sequenzNummer: number;
}

export interface ArtikelEigenschaft extends WithId, ArtikelAuswahl {
  verpflichtend: boolean;
  typ: WerteTyp;
  standardWert: string;
}

export enum WerteTyp {
  Unspecified = 0,
  Bool = 1,
  DateTime = 2,
  String = 3,
}

export interface ArtikelParameter extends WithId, BasicDetail{
  typ: WerteTyp;
  metaDaten?: any;
}

interface BasicDetail {
  name: string;
  beschreibung?: string;
}