import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { SSPBasketPosition, SSPParameterValue, WarenkorbPosition, WarenkorbPositionOption } from "../../../models/Warenkorb";
import theme from "../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { v4 as uuid } from 'uuid';
import { Utils } from "../../../utils/Utils";
import { LightPointAddress } from "../../../parts/LightPointAddress";
import { useCartOverviewContext } from "../../../context/CartOverviewContext/CartOverviewContext";
import EditMenu from "../EditMenu";
import { LightpointBasketPositionTableRowProps, isWarenKorbRowProps } from "../LightpointBasket/LightpointBasketPositionTableRow";
import { TableCellBorderless } from "../../../parts/TableCellBorderless";
import { useWarenkorbApi } from "../../../dataQuery/warenkorb/warenkorbApi";

interface RowProps {
  warenkorbPosition: WarenkorbPosition;
  ColapsableComopnent: ReactNode | null;
}

interface EditableSSPPositionTableRowProps extends RowProps{
  editable: true;
  onEdit: (position: SSPBasketPosition) => void;
  onDelete: (position: SSPBasketPosition) => Promise<void>;
}


interface SSPBasketPositionTableRowProps {
  warenkorbPosition: WarenkorbPosition;
  index: number;
  editable: boolean;
  expandable: boolean;

}
export function SSPBasketPositionTableRow(props: SSPBasketPositionTableRowProps) {
  const [rowOpen, setUnfoldRow] = useState(false);
  const { lightpointBasketTableHeadPosition: tableHeadPosition } = useCartOverviewContext();
  const { warenkorbPosition } = props;
  const warenkorbApi = useWarenkorbApi();

  if (!tableHeadPosition) {
    return null;
  }


  function getSSPPositionData(position: SSPBasketPosition) : SSPParameterValue | undefined {
    const sspPositionData = position.konfiguration.parameter?.find(p => p.parameterId === "BSTMNG")?.wert as SSPParameterValue | undefined;
    if (!sspPositionData) {
      return undefined;
    }
    return sspPositionData;
  }
  const sspPositionData = getSSPPositionData(warenkorbPosition);

  return <>
    <TableRow id="cart-tabel" key={warenkorbPosition.id}>
      {props.editable ? null :
        <TableCell>{props.index}</TableCell>
      }
      <TableCell>
        {warenkorbPosition.konfiguration.artikelName}
      </TableCell>
      <TableCell>
        { sspPositionData ?
        sspPositionData.jahr
        : null
        }
      </TableCell>
      <TableCell>
        { sspPositionData ?
        sspPositionData.anzahlStandartMast
        : null
        }
      </TableCell>
      <TableCell>
        { sspPositionData ?
        sspPositionData.anzahlHolzMast
        : null
        }
      </TableCell>

      <TableCell>
        {warenkorbPosition.bearbeitetVon?.vorname + " " + warenkorbPosition.bearbeitetVon?.nachname}
      </TableCell>
      <TableCell className="text-right">
        {Utils.formatToLocalDate(new Date(warenkorbPosition.bearbeitetAm))}
      </TableCell>
      <TableCell id="cart-netto-price" className="whitespace-nowrap text-right">
        {Utils.formatToEUR(warenkorbPosition?.nettoPreis)}
      </TableCell>
      { !props.editable ? null :
        <TableCell>
          <EditMenu 
            isEditable={false}
            onEdit={()=> { }}
            onDelete={()=> { warenkorbApi.deleteBasketPosition(warenkorbPosition.id)  }}
          />
        </TableCell>
      }
      {!props.expandable ? null :
      <TableCell>
          <IconButton
            size="small"
            onClick={() => setUnfoldRow(!rowOpen)}
          >
            {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
      </TableCell>
      }
    </TableRow>
    {!props.expandable ? null :
      <TableRow>
        <TableCell style={{ padding: 0, backgroundColor: theme.palette.grey[300] }} colSpan={12}>
          <Collapse className="px-4" in={rowOpen} timeout="auto" unmountOnExit>
          <Table>
      <TableHead sx={{ borderBottom: "solid 2px white" }}>
        <TableRow>
          <TableCell width={200}>
            Leistung
          </TableCell>
          <TableCell>
            Anmerkung
          </TableCell>
          <TableCell>
            Anzahl
          </TableCell>
          <TableCell>
            Preis/Mast
          </TableCell>
          <TableCell className="text-right" sx={{ width: "120px", minWidth: "120px"}}>
            Preis
          </TableCell>
          <TableCell sx={{ width: "40px" }}/>
        </TableRow>
      </TableHead>
      <TableBody>
      {warenkorbPosition.preisKomponenten?.map((preisKomponente, index) => (
                  <TableRow>
                  <TableCellBorderless>
                   {preisKomponente.beschreibung}
                  </TableCellBorderless>
                  <TableCellBorderless>
                  {index === 0 ?  warenkorbPosition.konfiguration?.eigenschaften && warenkorbPosition.konfiguration?.eigenschaften[0].wert : ""}      
                  </TableCellBorderless>
                  <TableCellBorderless>
                  {preisKomponente.anzahl}
                  </TableCellBorderless>
                  <TableCellBorderless>
                  {Utils.formatToEUR(preisKomponente.einzelpreis)}     
                  </TableCellBorderless>
                  <TableCellBorderless className="">     
                    {Utils.formatToEUR(preisKomponente.nettoPreis)}
                  </TableCellBorderless>
                </TableRow>
      ))}
      </TableBody>
    </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    }
  </>;
}
