import { useAppContext } from "../../context";
import { WarenkorbKonfiguration, WarenkorbPosition } from "../../models/Warenkorb";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";
import { useWarenkorb } from "./useWarenkorb";

export function useWarenkorbApi() {
  const { refetch } = useWarenkorb();
  const { setIsLoading } = useAppContext();
  const apiClient = useApiClient();
  const { warenkorb } = useApiItemPath();

  async function deleteBasketPosition(id: string) {
    try {
      setIsLoading(true);
      await apiClient.remove({url: `${warenkorb.add}/${id}`});
      try {
        await refetch();
      } catch (error) {
        console.error("Refetch Warenkorb:", error);
        throw new Error();
      }
    } catch (error) {
      console.error("Delete Basket:", error);
      throw new Error("Warenkorb Position konnte nicht gelöscht werden");
    } finally {
      setIsLoading(false);
    }
  }

  return {
    upsertBasket: (body: WarenkorbKonfiguration, id?: string) => id 
      ? apiClient.put<WarenkorbPosition, WarenkorbKonfiguration>({url: `${warenkorb.add}/${id}`, body})
      : apiClient.post<WarenkorbPosition, WarenkorbKonfiguration>({url: warenkorb.add, body}),
    deleteBasketPosition
  };
}