import { Box, BoxProps } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";

interface StickyBoxProps extends BoxProps {
  // if dependency changes Box checks if sticky state needs to change
  stateHandlerDependency?: unknown;
}

export const StickyBox = ({ stateHandlerDependency, className, ...props }: StickyBoxProps) => {
  const boxRef = useRef<HTMLDivElement>();
  const stickyBoxRef = useRef<HTMLDivElement>();

  const setSticky = useCallback((sticky: boolean) => {
    const stickyBox = stickyBoxRef.current;
    const box = boxRef.current;
    if (stickyBox === undefined || box === undefined) return;
    if (sticky) {
      if (className) {
        box.className = className;
      }
      box.classList.add("invisible");
      stickyBox.className = `${className} ${stickyClass}`;
    } else {
      stickyBox.className = "hidden";
      box.className = className || "";
    }
  }, [className]);

  const handleSticky = useCallback(() => {
    const box = boxRef.current;
    if (!box) return;
    const boxBottom = box.getBoundingClientRect().bottom;
    if (boxBottom > window.innerHeight) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [setSticky]);

  useEffect(() => {
    handleSticky();
  }, [stateHandlerDependency, handleSticky]);

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);
    return () => window.removeEventListener("scroll", handleSticky);
  }, [handleSticky]);

  return (
    <>
      <Box ref={stickyBoxRef} className={className} {...props} />
      <Box ref={boxRef} className={`${className} invisible`} {...props} />
    </>
  );
}

const stickyClass = "fixed bottom-0 left-16 bg-white z-10 w-content-full";