export interface IConfiguration {
    apiEndpointUrl: string;
    apiKey: string;
    buildVersion: string;
  }

  const defaultConfiguration: IConfiguration = {
   apiEndpointUrl: "https://dl-common-dev-apim.azure-api.net/wfkservice/v1/",
   apiKey: "SET_BY_PIPELINE",
   buildVersion: "local"
  }
  
  export function getConfiguration(): IConfiguration {
    return {
        apiEndpointUrl: process.env.REACT_APP_API_ENDPOINT || defaultConfiguration.apiEndpointUrl,
        apiKey: process.env.REACT_APP_API_KEY || defaultConfiguration.apiKey,
        buildVersion: process.env.REACT_APP_RELEASENUMBER || defaultConfiguration.buildVersion
    }
  }