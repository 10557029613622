import { Box, Button } from "@mui/material";
import { useAppNavigate } from "../../hooks/appNavigation";
import { StepHeader } from "../Stepper/StepHeader";

export const OrderConfirmation = () => {
  const { navigateHome } = useAppNavigate();

  return (
    <Box textAlign={"center"} display="flex" height="100%">
      <Box margin={"auto"}>
        <StepHeader 
          title="Bestellbestätigung"
          description={<span>
            Vielen Dank für Ihre Bestellung.<br />
            Sie erhalten in Kürze eine Auftragsbestätigung per E-Mail.
          </span>}
        />
        <Button onClick={navigateHome} variant="contained">Zurück zur Startseite</Button>
      </Box>
    </Box>
  );
}