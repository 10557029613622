import { Box, Card, styled, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { DoubleLightPointVector } from "../../assets/vectors/DoubleLightPointVector";
import { HistoricalLightPointVector } from "../../assets/vectors/HistoricalLightPointVector";
import { LightPointVector } from "../../assets/vectors/LightPointVector";
import { RightArrowVector } from "../../assets/vectors/RightArrowVector";
import { useAppContext } from "../../context";
import { useArtikel } from "../../dataQuery/artikel/useArtikel";
import { Artikel, ArtikelId, ArtikelKategorie } from "../../models";
import { Spinner } from "../Spinner";
import { LoadingProvider } from "../Spinner/LoadingProvider";
import { useWarenkorb } from "../../dataQuery/warenkorb/useWarenkorb";
import { ProductUtils } from "../../utils/ProductUtils";
import { MaxLightPointsDialog } from "./MaxLightPointsDialog";
import { ContactLink } from "../ContactView/ContactLink";
import { useAppNavigate } from '../../hooks/appNavigation';
import { SSPVector } from "../../assets/vectors/SSPVector";

interface ProductSelectionProps {
  onSelectArtikel: (product: Artikel) => void;
  selectedArtikel?: Artikel;
}

const MAX_LIGHTPOINT_ORDERS = 3;

export const ProductSelection = (props: ProductSelectionProps) => {
  const { navigateToStandsicherheit } = useAppNavigate();
  const { toggleContactView: openContactView } = useAppContext();
  const [selectedProduct, setSelectedProduct] = useState<Artikel>();
  const { data: artikels, isLoading } = useArtikel();
  const { data: warenkorb, isLoading: isLoadingWarenkorb } = useWarenkorb();
  const [openMaxLightPointsDialog, setOpenMaxLightPointsDialog] = useState(false);
  
  function handleOnProductSelect(a: Artikel) {
      if (!a.bestellbar) {
        openContactView("besondere-mast");
        return;
      }

      if (a.kategorie === ArtikelKategorie.Leuchtstellen){
      
        const lightPointPositions = warenkorb?.positionen.filter(ProductUtils.isLightPointPosition).length || 0;
        if (lightPointPositions >= MAX_LIGHTPOINT_ORDERS) {
          return setOpenMaxLightPointsDialog(true);
        }
        props.onSelectArtikel(a);
        setSelectedProduct(a);
      }
      if(a.kategorie === ArtikelKategorie.Sicherheitspruefung) {
        navigateToStandsicherheit();
      }

    }

  useEffect(() => {
    setSelectedProduct(props.selectedArtikel);
  }, [ props.selectedArtikel ]);
  

  if (!artikels) {
    return null;
  }

  if (isLoading || isLoadingWarenkorb) {
    return <Spinner />;
  }

  return (
    <LoadingProvider isLoading={!artikels || isLoading}>
      <Box textAlign={"center"}>
        <Typography variant="h2" marginBottom={"5px"}>Produktauswahl</Typography>
        <Typography>
          Hier können Sie das Austauschen und Versetzen von Lichtmasten bestellen und bei uns in Auftrag geben.
        </Typography>
        <Typography>
          Wenn Sie Unterstützung bei der Bestellung benötigen, wenden Sie sich bitte an Ihre zuständige Netze-BW <ContactLink type="anprech-person" />
        </Typography>
        <Box display="flex" flexDirection="row" padding={8}>

        <Box  minWidth={150} display="flex" flexDirection="column" justifyContent={"center"} >
          <Typography align="left" variant="h2" marginBottom={"5px"}>Lichtmasten</Typography>
        </Box>
       
          {artikels.filter(a => a.kategorie === ArtikelKategorie.Leuchtstellen).map(artikel => (
            <ProductBox 
              key={artikel.id}
              label={artikel.beschreibung || ""} 
              selected={!selectedProduct ? false :selectedProduct.id === artikel.id}
              onClick={() => handleOnProductSelect(artikel)}
              icons={artikelIcons[artikel.id]}
            />
          ))}
        </Box>
        <Box display="flex" flexDirection="row"  paddingLeft={8} paddingRight={8}>
        <Box minWidth={150} display="flex" flexDirection="column" justifyContent={"center"} >
          <Typography align="left" variant="h2" marginBottom={"5px"}>Standsicherheit</Typography>
        </Box>
          {artikels.filter(a => a.kategorie === ArtikelKategorie.Sicherheitspruefung).map(artikel => (
            <ProductBox 
              key={artikel.id}
              label={artikel.beschreibung || ""} 
              selected={!selectedProduct ? false :selectedProduct.id === artikel.id}
              onClick={() => handleOnProductSelect(artikel)}
              icons={artikelIcons[artikel.id]}
            />
          ))}
        </Box>
      </Box>
      <MaxLightPointsDialog open={openMaxLightPointsDialog} onClose={() => setOpenMaxLightPointsDialog(false)} />
    </LoadingProvider>
  );
}

type ArtikelIcon = {
  [k in ArtikelId]: ReactNode
}

const artikelIcons: ArtikelIcon = {
    "MT": <>
        <LightPointVector />
        <RightArrowVector />
        <DoubleLightPointVector />
    </>,
    "MV": <>
        <LightPointVector />
        <RightArrowVector />
        <LightPointVector /> 
    </>,
    "MH": <>
      <HistoricalLightPointVector />
    </>,
    "SSP": <>
    <SSPVector />
  </>,
}

interface ProductBoxProps {
  selected: boolean;
  label: string;
  icons: ReactNode;
  onClick: () => void;
}
export const ProductBox = (props: ProductBoxProps) => {
  return (
    <NavigationButtonContainer active={props.selected.valueOf().toString() as ("true" | "false")} onClick={props.onClick}>
      <Box display={"flex"} flexDirection="row" justifyContent={"space-evenly"} alignItems="center">
        {props.icons}
      </Box>
      <Typography marginTop={2}>
        {props.label}
      </Typography>
    </NavigationButtonContainer>
  );
}

export const NavigationButtonContainer = styled(Card)<{active: "false" | "true"}>((props) => ({
  minWidth: 300,
  maxWidth: 300,
  border:`solid ${props.active === "true" ? props.theme.palette.secondary.main : props.theme.palette.grey[400]} 2px`, 
  padding: "calc(10px + 2vw) calc(10px + 2vw) calc(10px + 1vw) calc(10px + 2vw)",
  margin: "10px 30px",
  display: "flex",
  justifyItems: "center",
  flexDirection: "column",
  textAlign: "center",
  flex: 1,
  ":hover": { 
    cursor: "pointer",
    backgroundColor: props.theme.palette.grey[100]
  }
}));
