import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { PreisKomponente } from "../../models/Warenkorb";
import { TableCellBorderless } from "../../parts/TableCellBorderless";
import { Utils } from "../../utils/Utils";

interface PreisKomponentenTableProps {
  positionId: string;
  preisKomponenten: PreisKomponente[];
}

export const PreisKomponentenTable = (props: PreisKomponentenTableProps) => {
  return (
    <Table>
      <TableHead sx={{ borderBottom: "solid 2px white" }}>
        <TableRow>
          <TableCell width={200}>
            Leistung
          </TableCell>
          <TableCell>
            Beschreibung
          </TableCell>
          <TableCell className="text-right" sx={{ width: "120px", minWidth: "120px"}}>
            Preis
          </TableCell>
         <TableCell sx={{ width: "40px" }}/>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.preisKomponenten.map((p, i) => (
          <TableRow key={props.positionId + p.leistung + i}>
            <TableCellBorderless>
              {p.leistung}
            </TableCellBorderless>
            <TableCellBorderless>
              {p.beschreibung}
            </TableCellBorderless>
            <TableCellBorderless className="text-right">
              {Utils.formatToEUR(p.nettoPreis)}
            </TableCellBorderless>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}