import { TableCell, TableHead, TableRow } from "@mui/material";
import { forwardRef } from "react";
import { Else, If, Then } from "react-if";
import { WarenkorbPosition } from "../../../models/Warenkorb";

interface WarenkorbTableHeadProps {
  warenkorbPosition: WarenkorbPosition;
  editable: boolean;
}

export const LightpointBasketTableHead = forwardRef<HTMLTableCellElement, WarenkorbTableHeadProps>((props, ref) => {
  return (
    <TableHead>
      <TableRow>
        {props.editable ? null :
          <TableCell>Position</TableCell>
        }
        <TableCell>Produkt</TableCell>
        <TableCell>Standort</TableCell>
        {props.warenkorbPosition.konfiguration.optionen.map(o => (
          <TableCell key={o.optionName}>{o.optionName}</TableCell>
        ))}
        <TableCell className="whitespace-nowrap">Bearbeitet von</TableCell>
        <TableCell className="whitespace-nowrap text-right">Bearbeitet am</TableCell>
        <TableCell ref={ref} align="right" sx={{ minWidth: "120px", width: "120px" }}>Preis</TableCell>
        <If condition={props.editable}>
          <Then>
            <TableCell sx={{ width: "40px" }}/>
            <TableCell sx={{ width: "40px" }}/>
          </Then>
          <Else>
            <TableCell sx={{ minWidth: "68px", width: "68px" }}/>
          </Else>
        </If>
      </TableRow>
    </TableHead>
  );
});