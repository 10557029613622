import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import theme from "../../theme";

interface Props {
  isLoading: boolean;
}

export const MapLoadingSpinner = ({isLoading}: Props) => {
  return !isLoading ? null : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="10%"
      width="10%"
      position="absolute"
      left="0"
      top="5px"
    >
      <CircularProgress
        variant="indeterminate"
        style={{
          color: theme.palette.secondary.main,
          width: 30,
          height: 30,
          boxShadow: "1px 1px 5px rgba(0,0,0,.5)",
          borderRadius: 24,
        }}
      />
    </Box>
  );
};
