import { Box, Card, CardMedia, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { ArtikelDetails, ArtikelId, ArtikelOption } from "../../models";
import theme from "../../theme";
import { Spinner } from "../Spinner";
import { ImageUploader } from "./ImageUploader";
import { File } from "../../models/Image";
import "./Produktspezifikation.css";
import { ContactLink } from "../ContactView/ContactLink";
import { EigenschaftsKonfiguration, WarenkorbKonfigurationOption, WarenkorbKonfiguration } from "../../models/Warenkorb";
import { useImageApi } from "../../dataQuery/file/imageApi";
import { StepHeader } from "../Stepper/StepHeader";
import { useAppContext } from "../../context";
import { useCartContext } from "../../context/CartContext/CartContext";
import { useArtikelDetails } from "../../dataQuery/artikel/useArtikelDetails";
import { QueryClient } from "react-query";
import { OrderNotice } from "../OrderNotice/OrderNotice";
import { InputDescription } from "../../parts/InputDescription";

const imageSrc = {
  "MXO1": "/unbefestigt.png",
  "MXO2": "/pflaster.png",
  "MXO3": "/asphalt.jpg"
};

const OBERFLAECHE_ID = "MXO";
const BEMERKUNG_ID = "MVZKOMM";

type Optionen<T> = {
  optionen: T[];
  oberflaeche?: T;
}

const queryClient = new QueryClient();

export function Produktspezifikation() {
  const { setIsLoading } = useAppContext();
  const imageApi = useImageApi();
  const { 
    setWarenkorbKonfiguration, 
    warenkorbKonfiguration,
    warenkorbPosition,
    selectedArtikel
  } = useCartContext();
  const { data: artikelDetails } = useArtikelDetails(warenkorbPosition?.konfiguration.artikelId as ArtikelId || selectedArtikel?.id);

  useEffect(() => {
    if (warenkorbKonfiguration) return;
    setWarenkorbKonfiguration(
      warenkorbKonfiguration ? warenkorbKonfiguration :
      artikelDetails ? buildWarenkorbKonfiguration(artikelDetails) : undefined
    );
  }, [artikelDetails, setWarenkorbKonfiguration, warenkorbKonfiguration])

  const artikelDetailsOptionen: Optionen<ArtikelOption> = {
    optionen: artikelDetails?.optionen?.filter(o => o.id !== OBERFLAECHE_ID) || [],
    oberflaeche: artikelDetails?.optionen?.find(o => o.id === OBERFLAECHE_ID)
  };

  const warenkorbOptionen: Optionen<WarenkorbKonfigurationOption> = {
    optionen: warenkorbKonfiguration?.optionen?.filter(o => o.optionId !== OBERFLAECHE_ID) || [],
    oberflaeche: warenkorbKonfiguration?.optionen?.find(o => o.optionId === OBERFLAECHE_ID)
  }

  async function handleOnImageUpload(images: File[]) {
    if (!warenkorbKonfiguration) return;
    const storedImageIds = await imageApi.post(images);
    setIsLoading(true);
    if (warenkorbKonfiguration.bildIds?.length) {
      storedImageIds.forEach(id => warenkorbKonfiguration.bildIds!.push(id));
    } else {
      warenkorbKonfiguration.bildIds = storedImageIds;
    }
    setWarenkorbKonfiguration({ ...warenkorbKonfiguration });
    setIsLoading(false);
  }

  function handleOnOptionenChange(newOption: WarenkorbKonfigurationOption) {
    if (!artikelDetails) return;
    const w = buildWarenkorbKonfiguration(artikelDetails, warenkorbKonfiguration);
    const optionen = w.optionen.filter(o => o.optionId !== newOption.optionId);
    setWarenkorbKonfiguration({
      ...w,
      optionen: [ ...optionen, newOption ]
    });
  }

  function handleOnEigenschaftenChange(newEigenschaft: EigenschaftsKonfiguration) {
    if (!artikelDetails) return;
    const w = buildWarenkorbKonfiguration(artikelDetails, warenkorbKonfiguration);
    const eigenschaften = w?.eigenschaften?.filter(e => e.eigenschaftId !== newEigenschaft.eigenschaftId);
    setWarenkorbKonfiguration({
      ...w,
      eigenschaften: !eigenschaften ? [newEigenschaft] : [ ...eigenschaften, newEigenschaft ]
    });
  }

  function handleOnDeleteImages(imageIds: string[]) {
    if (!warenkorbKonfiguration) return;
    setWarenkorbKonfiguration({ 
      ...warenkorbKonfiguration, 
      bildIds: warenkorbKonfiguration?.bildIds?.filter(id => !imageIds.includes(id)) ?? []
    });
    for (let id of imageIds) {
      queryClient.removeQueries(id, { exact: true })
    }
  }

  return !artikelDetails ? <Spinner /> : (
    <Box className="mb-8">
      <Box className="flex items-end">
        <Box>
          <StepHeader 
            title="Produktspezifikation" 
            description={<span>
              Bitte beantworten Sie die folgenden Fragen,<br/>
              damit wir abschätzen können, worum<br/>
              es bei Ihnen geht:
            </span>}
          />
          <InputDescription
            className="Produktspezifikation--input-description--container"
            title="Oberfäche" 
            description="In welcher Oberfläche befindet sich der Mast?" 
          />
        </Box>
        <Grid container>
          {artikelDetailsOptionen?.oberflaeche?.auswahlen.map(a => (
            <Grid className="h-48" item md={4} key={a.id}>
              <ImageButton
                className="h-48"
                width="160px"
                imageLabel={a.name} imageSrc={imageSrc[a.id as keyof typeof imageSrc]} 
                selected={warenkorbOptionen?.oberflaeche?.auswahlId === a.id}
                onClick={() => handleOnOptionenChange({ optionId: OBERFLAECHE_ID, auswahlId: a.id })}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className="flex items-end">
        <Box>
          {artikelDetailsOptionen.optionen.map(o => (
            <Box className="Produktspezifikation--row" key={o.id}>
              <InputDescription
                className="Produktspezifikation--input-description--container"
                title={o.name}
                description={o.beschreibung || ""}
              />
              <FormControl>
              <InputLabel id={o.id}>{"Bitte auswählen..."}</InputLabel>
              <Select
                labelId={o.id}
                className="Produktspezifikation--input"
                size="small"
                label={`${o.name} auswählen...`}
                value={warenkorbOptionen.optionen.find(wo => wo.optionId === o.id)?.auswahlId || ''}
                onChange={(e, _) => handleOnOptionenChange({ optionId: o.id, auswahlId: e.target.value })}
              >
                {o.auswahlen.map(a => (
                  <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>
                ))}
              </Select>
              </FormControl>
            </Box>
          ))}
        </Box>
        <Box className="ml-3 mt-5">
          <OrderNotice className={artikelDetailsOptionen.optionen.length < 2 ? "mb-0" : "mb-2"}>
            <span>
              {`Bei Lichtmasten einer Höhe größer als 8 Meter${artikelDetails.id === 'MV' ? "oder Entfernung größer als 5 Meter" : ""}, 
              erstellen wir Ihnen ein individuelles Angebot.`}
              <ContactLink label=" Klicken Sie dafür bitte hier." type="besondere-mast" />
            </span>
          </OrderNotice>
          {artikelDetails?.eigenschaften?.find(e => e.id === BEMERKUNG_ID) && 
            <TextField 
              value={warenkorbKonfiguration?.eigenschaften?.find(e => e.eigenschaftId === BEMERKUNG_ID)?.wert} 
              onChange={e => handleOnEigenschaftenChange({ eigenschaftId: BEMERKUNG_ID, wert: e.target.value })} 
              variant="outlined" 
              fullWidth 
              placeholder={artikelDetails?.eigenschaften?.find(e => e.id === "MVZKOMM")?.beschreibung}
            />
          }
        </Box>
      </Box>
      <Box className="Produktspezifikation--row">
        <InputDescription
          className="Produktspezifikation--input-description--container"
          title="Foto (optional)"
          description="Bitte fügen Sie ein Foto hinzu, damit wir die Situation vor Ort besser einschätzen können"
        />
        <ImageUploader 
          onDeleteImages={handleOnDeleteImages} 
          uploadedImageIds={warenkorbKonfiguration?.bildIds || []} 
          onUploadImages={handleOnImageUpload} 
        />
      </Box>
    </Box>
  );
}

interface ImageButtonProps {
  className?: string;
  width: string;
  imageSrc: string;
  imageLabel: string;
  selected: boolean;
  onClick: () => void;
}
const ImageButton = (props: ImageButtonProps) => (
  <Card
    className={`flex flex-col ${props.className}`}
    onClick={props.onClick}
    sx={{ 
      width: props.width, 
      border: props.selected ? `solid ${theme.palette.secondary.main} 2px` : undefined,
      ":hover": { border: `solid ${theme.palette.secondary.main} 2px`, cursor: "pointer" }
    }}
  >
    <CardMedia
      component="img"
      className="h-full bg-gray-300"
      width={props.width}
      image={props.imageSrc}
      alt={props.imageLabel}
    />
    <Typography className="text-center m-1 font-bold justify-self-end" gutterBottom>
      {props.imageLabel}
    </Typography>
  </Card> 
)

export const buildWarenkorbKonfiguration = (ad: ArtikelDetails, w?: WarenkorbKonfiguration): WarenkorbKonfiguration => (
  !!w ? w : { artikelId: ad.id, optionen: [] }
);