import theme from "../../theme";
import { SidebarIconButton } from './SidebarIconButton'
import { useLocation } from "react-router-dom";
import { CockpitIcon } from "../../assets/icons/CockpitIcon";
import { PlusIcon } from "../../assets/icons/PlusIcon";
import { ArchiveIcon } from "../../assets/icons/ArchiveIcon";
import { AppRoute } from "../../hooks/appNavigation";
import { ListIcon } from "../../assets/icons/ListIcon";

export const Sidebar = () => {
  const { pathname } = useLocation();

  const options: SidebarOption[] = [
    { icon: <CockpitIcon />, alt: "cockpit-icon", target: "/", disabled: false },
    { icon: <ListIcon />, alt: "Warenkorb", target: "/warenkorb", disabled: false},
    { icon: <ArchiveIcon />, alt: "my-orders-icon", target: "/meine-bestellungen", disabled: false },
    { icon: <PlusIcon />, alt: "new-order-icon", target: "/einkaufswagen", disabled: false }
  ];

  return (
    <div 
      className="flex fixed flex-col justify-start h-full w-16 left-0 top-0" 
      style={{ backgroundColor: theme.palette.primary.main}}
    >
      {options.map((option, index) => (
        <SidebarIconButton
          key={index}
          icon={option.icon}
          alt={option.alt}
          target={option.target}
          selected={option.target === pathname}
          disabled={option.disabled}
        />
      ))}
    </div>
  );
};

interface SidebarOption {
  icon: JSX.Element;
  alt: string;
  target: AppRoute;
  disabled: boolean;
}
