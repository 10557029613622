import React from "react";
import { Typography } from "@mui/material";
import hintIcon from "./HintIcon";

export const HintInfoBox = ({ label }: { label: string }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        border: "1px solid #E3E3E3",
        height: "25px",
        justifyContent: "flex-start",
        backgroundColor: "white",
      }}
    >
      <div style={{ marginLeft: 5, alignSelf: "center" }}>{hintIcon()}</div>

      <Typography
        style={{
          marginLeft: 5,
          marginRight: 5,
          alignSelf: "center",
          fontSize: 11,
        }}
      >
        {label}
      </Typography>
    </div>
  );
};
