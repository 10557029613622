import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { WarenkorbPosition, WarenkorbKonfiguration } from "../../models/Warenkorb";
import { useWarenkorbApi } from "../../dataQuery/warenkorb/warenkorbApi";
import { useCartContext } from "../../context/CartContext/CartContext";
import { useAppNavigate } from "../../hooks/appNavigation";
import { Image } from "../../models/Image";
import { useImageApi } from "../../dataQuery/file/imageApi";
import { ImageDialog } from "../ImageDialog/ImageDialog";
import { NonspecificBasket } from "../NonspecificBasket/NonspecificBasket";

export const WarenkorbTable = () => {
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const warenkorbApi = useWarenkorbApi();
  const { navigateToProduktSpecifikation } = useAppNavigate();
  const [images, setImages] = useState<Image[]>([]);
  const imageApi = useImageApi();
  const { 
    setWarenkorbPosition, 
    setWarenkorbKonfiguration, 
    setIsEditing,
  } = useCartContext();

  function handleOnEditWarenkorbPosition(wp: WarenkorbPosition) {
    setIsEditing(true);
    setWarenkorbPosition(wp);
    const wpk = wp.konfiguration;
    const wk: WarenkorbKonfiguration = { 
      artikelId: wpk.artikelId,
      bildIds: wpk?.bildIds,
      eigenschaften: wpk?.eigenschaften,
      parameter: wpk?.parameter,
      pdfDokumentId: wpk?.pdfDokumentId,
      optionen: wpk.optionen.map(o => ({ 
        auswahlId: o.auswahlId, 
        optionId: o.optionId 
      }))
    };
    setWarenkorbKonfiguration(wk);
    navigateToProduktSpecifikation();
  }

  async function handleOnShowImage(ids: string[]) {
    const res = await imageApi.get(ids);
    setImages(res);
    setOpenImageDialog(true);
  }

  return (
    <>
      <NonspecificBasket
        editable={true}
        expandable={false}

       
        getBasketPositionTableRowProps={(wp) => ({
          warenkorbPosition: wp,
          editable: true,
          onEdit: handleOnEditWarenkorbPosition,
          onDelete: () => warenkorbApi.deleteBasketPosition(wp.id),
          onShowImages: () => handleOnShowImage(wp.konfiguration.bildIds!),
          ColapsableComopnent: !wp.konfiguration.eigenschaften?.length ? null : (
            <Box marginTop={1} marginBottom={2}>
              <Typography variant="h6">Bemerkungen</Typography>
              <Typography>
                {wp.konfiguration.eigenschaften?.[0]?.wert}
              </Typography>
            </Box>
          )   
        })}
      />
      <ImageDialog
        open={openImageDialog} 
        onClose={() => setOpenImageDialog(false)} 
        images={images}
      />
    </>
  );
}