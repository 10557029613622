import { Box, CircularProgress } from "@mui/material";

interface SpinnerProps {
  fullscreen?: boolean;
}

export function Spinner(props: SpinnerProps) {
  return (
    <Box className={`flex justify-center items-center h-full ${props.fullscreen ? "min-h-screen" : ""}`}>
      <CircularProgress />
    </Box>
  );
}
