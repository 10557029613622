import { useKeycloak } from "../context/KeycloakContext";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { hasGroupsInScope } from "../dataQuery/auth";
import { Environment } from "../context/AppContext/AppContext";
import { CustomerScopeUtils as Utils } from "../utils/CustomerScopeUtils";

export function useCustomerScope(env: Environment) {
  const keycloak = useKeycloak();
  const { search } = useLocation();

  const [customerScope, setCustomerScope] = useState<string>();

  const initCustomerScope = useCallback((hasAdminRole: boolean) => {
    const customerScopeSearchParam = Utils.getCustomerScopeFromRequestParams(
      new URLSearchParams(search), 
      env
    );
    const customerScopesInGroups = Utils.getCustomerScopesByGroups(keycloak.tokenParsed);

    if (customerScopeSearchParam) {
      if (hasAdminRole || customerScopesInGroups.includes(customerScopeSearchParam)) {
        return setCustomerScope(customerScopeSearchParam)
      }
    }
    if (customerScopesInGroups.length === 0) {
      throw new Error(
        hasAdminRole 
          ? "ags oder zv konnte nicht in der URL gefunden werden"
          : "Nicht autorisiert"
      )
    }
    return setCustomerScope(customerScopesInGroups?.[0]);
  }, [search, keycloak, env])

  useEffect(() => {
    if (!keycloak.authenticated || customerScope) return;
    if (!hasGroupsInScope(keycloak.tokenParsed)) {
      keycloak.login({ scope: "groups" }).catch(() => { 
        throw new Error("Nicht autorisiert"); 
      });
    } else {
      initCustomerScope(Utils.hasAdminRole(keycloak.tokenParsed));
    }
  }, [keycloak, initCustomerScope, customerScope])

  return customerScope;
}