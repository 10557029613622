import { LightPointInfo } from "../models/Warenkorb";
import { Utils } from "../utils";

interface LightPointAddressProps {
  lightPointInfo: LightPointInfo;
  cleanBreak?: boolean;
}

export function LightPointAddress({ lightPointInfo, cleanBreak }: LightPointAddressProps) {
  const { street, city } = Utils.formatLightPointInfoAddress(lightPointInfo);
  let streetSuffix = "";
  if (city) {
    streetSuffix = cleanBreak ? "," : ", ";
  }

  return (
    <span>
      <span className="whitespace-nowrap">{street + streetSuffix}</span>
      {cleanBreak && city  ? <br /> : null}
      <span>{city}</span>
    </span>
  );
}