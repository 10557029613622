import { AppBar, Box, Button, Container, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { useGemeinde } from '../../dataQuery/gemeinde/useGemeinde';
import { vertriebsTeamLabel } from '../../models';
import theme from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import { VertriebsteamImage } from '../../assets/icons/VertriebsteamImage';
import { HistoricalLightPointVector } from '../../assets/vectors/HistoricalLightPointVector';
import { useAppNavigate } from '../../hooks/appNavigation';

export type ContactViewType = "besondere-mast" | "anprech-person";

interface ContactViewProps {
  open: boolean;
  onClose: () => void;
  type: "besondere-mast" | "anprech-person";
}

export default function ContactView(props: ContactViewProps) {
  const { data } = useGemeinde();
  const { navigateHome } = useAppNavigate();

  function handleOnBackToHome() {
    navigateHome();
    props.onClose();
  }

  return (
    <Dialog
      open={props.open}
      fullScreen
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!data ? <h2>Gemeinde nicht gefunden</h2> : (
        <Container sx={{ paddingY: "5vh!important", position: "relative" }}>
          {props.type === "besondere-mast" &&
            <Box 
              sx={{ 
                position: "absolute", 
                width: "100%", 
                height: "100%", 
                opacity: 0.2, 
                display: "flex", 
                justifyContent: "center", 
                left: 0 
              }}
            >
              <HistoricalLightPointVector fullwidth/>
            </Box>
          }
          <Box display="flex" alignItems={"center"} flexDirection="column">
            <Typography variant="h1" marginBottom="5vh">Bestellkorb Öffentliche Beleuchtung: {content.title[props.type]}</Typography>
            {content.subTitle[props.type]}
            <br/>
            {content.description[props.type]}
            <Box marginY={7} display="flex" alignItems={"center"} justifyContent="center" zIndex={1}>
              <div style={{ borderRadius: "50%"}}>
                <VertriebsteamImage />
              </div>
              <div style={{marginLeft: "1em", textAlign: "left"}}>
                <Typography fontWeight={"bold"} variant="body1" color={theme.palette.primary.main}>{`Postfach Netzgebiet ${vertriebsTeamLabel[data!.vertriebsteam]}`}</Typography>
                <Typography color="black" component={"a"} href={`mailto:${data.eMailAdresse}`} >{data.eMailAdresse}</Typography>
              </div>
            </Box>
            <Button onClick={handleOnBackToHome} variant="contained">Zurück zur Startseite</Button>
          </Box>
        </Container>
      )}
    </Dialog>
  );
}

type ContentPropType = {
  [t in "title" | "subTitle" | "description"]: {
    [k in ContactViewType]: any;
  }
}

const content: ContentPropType = {
  title: {
    "anprech-person": "Ansprechperson",
    "besondere-mast": "Besondere Mastausführung"
  },
  subTitle: {
    "anprech-person": 
      <Typography textAlign={"center"} variant='h4' fontWeight={500}>
        Sie benötigen Unterstützung bei der<br/>Bestellung eines Lichtmastes?
      </Typography>,
    "besondere-mast": <>
      <Typography textAlign={"center"} variant='h4' fontWeight={500}>
        Sie möchten Arbeiten an besondere Mastausführung durchführen lassen?
      </Typography>
      <Typography className="font-bold" variant="h4">
        <br/><span className="underline">Hierzu zählen unter anderem</span>
        <ul className='leading-7'>
          <li>Historische Masten</li>
          <li>{"Besondere Höhen (> 8 Meter)"}</li>
          <li>{"Besondere Versetzungsweiten  (> 5 Meter)"}</li>
          <li>Sonderfarben</li>
        </ul>
      </Typography>
  </>},
  description: {
    "anprech-person":           
      <Typography textAlign={"center"}>
        Unsere neue Bestellkorb-Umgebung kann ungewohnt sein. Gehen Sie<br/>
        deshalb gerne auf Ihren bekannten Ansprechpartner zu oder melden sich<br/>
        bei folgender Adresse:
      </Typography>,
    "besondere-mast": 
      <Typography textAlign={"center"}>
        Angebotserstellungen für besondere Mastausführungen sind leider sehr knifflig. Wenden Sie sich in diesem Fall<br/> 
        bitte direkt an Ihren bekannten Ansprechpartner oder an unser für Ihr Netzgebiet zuständiges Postfach.
      </Typography>
  }
}