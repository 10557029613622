import { styled } from "@mui/material/styles";
import { LightPoint } from "../../models";
import { Typography } from "@mui/material";
import { InfoWindow as GoogleInfoWindow } from "@react-google-maps/api";

interface Props {
  lightPoint: LightPoint;
  lat: number;
  lng: number;
  onClose: () => void;
  onShowFaultReport?: (incidentUuid: string) => void;
}

export const InfoWindow = ({ lightPoint, onClose, lat, lng, onShowFaultReport }: Props) => {
  return (
    <StyledGoogleInfoWindow
      position={{ lat: lat + 0.00005, lng }}
      onCloseClick={onClose}
      options={{ disableAutoPan: true }}
    >
      <div className={classes.infoWindow}>
        <div style={{ fontSize: 16 }}>{lightPoint.nummer}</div>
        <Typography style={{ color: "grey", fontSize: 14 }}>
          {lightPoint.strasse ? `${lightPoint.strasse} ${!!lightPoint.hausnummer ? lightPoint.hausnummer : ""}` : ""}
        </Typography>
      </div>
    </StyledGoogleInfoWindow>
  );
};

const PREFIX = "InfoWindow";

const classes = {
  infoWindow: `${PREFIX}-infoWindow`,
  header: `${PREFIX}-header`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const StyledGoogleInfoWindow = styled(GoogleInfoWindow)({
  [`& .${classes.infoWindow}`]: {
    maxWidth: 250,
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    zIndex: 100,
  },
  [`& .${classes.header}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.closeIcon}`]: {
    cursor: "pointer",
  },
});
