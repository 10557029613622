import { getScaledIconSize } from ".."
import { IconProps } from "../index.types"

export const EinkaufswagenIcon = (props: IconProps) => (
  <svg
    version="1.1"
    id="svg2"
    xmlSpace="preserve"
    {...getScaledIconSize({ width: 58, height: 47 }, props)}
    viewBox="40 35 50 70"
    xmlns="http://www.w3.org/2000/svg"
    fill={props.color}
  >
    <defs id="defs6">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath18">
        <path
          d="m 510.234,510.234 v 0 0 0 z M 492.738,307.258 c 0,29.984 -24.343,54.32 -54.34,54.32 -30.031,0 -54.375,-24.336 -54.375,-54.32 0,-30.051 24.344,-54.387 54.375,-54.387 29.997,0 54.34,24.336 54.34,54.387 m 234.684,0 c 0,29.984 -24.348,54.32 -54.344,54.32 -30.027,0 -54.375,-24.336 -54.375,-54.32 0,-30.051 24.348,-54.387 54.375,-54.387 29.996,0 54.344,24.336 54.344,54.387 m 56.453,318.719 H 391.313 l -30.27,84.308 c -4.98,13.875 -18.031,23.215 -32.766,23.449 l -132.828,2.145 c -19.558,0.316 -35.672,-15.285 -35.988,-34.848 -0.316,-19.558 15.285,-35.676 34.848,-35.988 l 108.332,-1.75 33.605,-93.602 56.645,-166.781 c 2.355,-6.941 8.875,-11.613 16.211,-11.613 h 299.132 c 7.125,0 13.504,4.41 16.02,11.074 l 75.641,200.442 c 4.222,11.199 -4.051,23.164 -16.02,23.164"
          id="path16" 
        />
      </clipPath>
    </defs>   
    <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,136.06667)">
      <g id="g10"transform="scale(0.1)">
        <g id="g12">
          <g id="g14" clipPath="url(#clipPath18)">
            <path 
              d="m 510.234,510.234 v 0 0 0 z M 492.738,307.258 c 0,29.984 -24.343,54.32 -54.34,54.32 -30.031,0 -54.375,-24.336 -54.375,-54.32 0,-30.051 24.344,-54.387 54.375,-54.387 29.997,0 54.34,24.336 54.34,54.387 m 234.684,0 c 0,29.984 -24.348,54.32 -54.344,54.32 -30.027,0 -54.375,-24.336 -54.375,-54.32 0,-30.051 24.348,-54.387 54.375,-54.387 29.996,0 54.344,24.336 54.344,54.387 m 56.453,318.719 H 391.313 l -30.27,84.308 c -4.98,13.875 -18.031,23.215 -32.766,23.449 l -132.828,2.145 c -19.558,0.316 -35.672,-15.285 -35.988,-34.848 -0.316,-19.558 15.285,-35.676 34.848,-35.988 l 108.332,-1.75 33.605,-93.602 56.645,-166.781 c 2.355,-6.941 8.875,-11.613 16.211,-11.613 h 299.132 c 7.125,0 13.504,4.41 16.02,11.074 l 75.641,200.442 c 4.222,11.199 -4.051,23.164 -16.02,23.164"
              style={{fillOpacity:1, fillRule:"nonzero", stroke:"none"}}
              id="path20" 
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
