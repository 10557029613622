import React, { useCallback, useEffect, useRef, useState } from "react";
import { LightPoint } from "../../models/LightPoint";
import { GeoMapContextValue, GeoMapContextProviderProps } from ".";
import { useJsApiLoader } from "@react-google-maps/api";
import { cancelable } from "cancelable-promise";
import { Spinner } from "../../components/Spinner";

const initValue: GeoMapContextValue = {
  setSelectedMarker: () => console.warn("setSelectedMarker is not implemented!"),
  allLightPoints: [],
  setNewLightPoints: () => console.warn("setNewLightPoints is not implemented!"),
  setMap: () => console.warn("setMap is not implemented!"),
  mapIsLoading: false,
  setMapIsLoading: () => console.warn("setMapIsLoading is not implemented!"),
  blockOnMoveMapEffects: () => console.warn("blockOnMoveMapEffects is not implemented!"),
  isOnMoveMapEffectsBlocked: () => false,
  setCenter: () => console.warn("setCenter is not implemented!"),
};

export const GeoMapContext = React.createContext<GeoMapContextValue>(initValue);

export const GeoMapProvider = (props: GeoMapContextProviderProps) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedMarker, setSelectedMarker] = useState<LightPoint>();
  const [allLightPoints, setAllLightPoints] = useState<LightPoint[]>([]);
  const [newLightPoints, setNewLightPoints] = useState<LightPoint[]>([]);
  const [mapIsLoading, setMapIsLoading] = useState(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [locality, setLocality] = useState<string>();
  const mapEffectsBlockedRef = useRef(false);

  const { isLoaded: googleApiIsLoaded } = useJsApiLoader({
    googleMapsApiKey: props.googleMapsApiKey,
    libraries: props.googleMapslibraries,
  });

  const getCitiesFromGeocoderResponce = useCallback((resp: google.maps.GeocoderResponse) =>
    resp.results
      .map((r) => r.address_components.filter((a) => a.types.includes("administrative_area_level_4")))
      .filter((a) => a.length > 0) 
  ,[]);

  useEffect(() => {
    if (!center || !googleApiIsLoaded) return;
    const geocoder = new google.maps.Geocoder();
    const { lat, lng } = center;
    const { cancel } = cancelable(
      geocoder.geocode({
        location: new google.maps.LatLng({ lat, lng }),
      })
    ).then((res) => {
      const citiesList = getCitiesFromGeocoderResponce(res);
      const firstCity = citiesList.shift()?.shift()?.long_name;
      setLocality(firstCity);
    });
    return () => {
      cancel();
    };
  }, [center, googleApiIsLoaded, getCitiesFromGeocoderResponce]);

  useEffect(() => {
    if (!newLightPoints.length) return;
    // because of performance issues
    for (const existingLightPoint of allLightPoints) {
      for (const newLightPoint of newLightPoints) {
        if (newLightPoint.nummer === existingLightPoint.nummer) {
          break;
        }
        const isLastLightPoint = newLightPoint.nummer === newLightPoints[newLightPoints.length - 1].nummer;
        if (isLastLightPoint) {
          newLightPoints.push(existingLightPoint);
        }
      }
    }
    setAllLightPoints(newLightPoints);
  }, [newLightPoints, allLightPoints]);

  const contextValue: GeoMapContextValue = {
    selectedMarker,
    setSelectedMarker,
    allLightPoints,
    map,
    setMap,
    setNewLightPoints,
    mapIsLoading,
    setMapIsLoading,
    blockOnMoveMapEffects: (v: boolean) => (mapEffectsBlockedRef.current = v),
    isOnMoveMapEffectsBlocked: () => mapEffectsBlockedRef.current,
    setCenter,
    locality,
  };

  return (
    <GeoMapContext.Provider value={contextValue}>
      {googleApiIsLoaded ? props.children : <Spinner />}
    </GeoMapContext.Provider>
  );
};

export const useGeoMap = () => {
  return React.useContext(GeoMapContext);
};
