import { useKeycloak } from "../KeycloakContext";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { ContactViewType } from "../../components/ContactView/ContactView";
import { MultiEnvValue } from "../../dataQuery/apiClient/apiClient";
import { useCustomerScope } from "../../hooks/customerScope";

const hostnames: MultiEnvValue<Array<string>> = {
  dev: ["wfk.dev.lighton.dienstleistungen.netze-bw.de", "localhost"],
  staging: ["wfk.staging.lighton.dienstleistungen.netze-bw.de"],
  prod: ["wfk.meine.lighton.dienstleistungen.netze-bw.de"]
};

export type Environment = keyof MultiEnvValue<string>;

interface User {
  firstname: string;
  surname: string;
  email: string;
}

interface AppContextValue {
  customerScope?: string;
  environment: Environment;
  toggleContactView: (t: ContactViewType) => void;
  contactViewConfig: ContactViewConfig;
  user?: User;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const defaultValue: AppContextValue = {
  environment: "dev", 
  toggleContactView: (_: ContactViewType) => {},
  contactViewConfig: { open: false, type: "anprech-person" },
  isLoading: false,
  setIsLoading: () => {}
}

const AppContext = createContext<AppContextValue>(defaultValue);

interface AppContextProviderProps {
  children: ReactNode;
}

export function getEnvironment(): Environment {
  const hostname = window.location.hostname;
  if (hostnames.staging.includes(hostname)) {
    return "staging";
  } else if (hostnames.prod.includes(hostname)) {
    return "prod";
  }
  return "dev";
}

interface WFKTokenParsed extends Keycloak.KeycloakTokenParsed {
  firstname: string;
  sn: string;
  sub: string;
}

export function AppContextProvider(props: AppContextProviderProps) {
  const [isLoading, setIsLoading] = useState(defaultValue.isLoading);
  const [contactViewConfig, setContactViewConfig] = useState<ContactViewConfig>(defaultValue.contactViewConfig);
  const keycloak = useKeycloak();
  const environment = useMemo(() => getEnvironment(), 
    // eslint-disable-next-line
  []);
  const customerScope = useCustomerScope(environment);
  
  const user: User | undefined = useMemo(() => {
    if (!keycloak.tokenParsed) {
      return undefined;
    } else {
      const wfkTokenParsed = keycloak.tokenParsed as WFKTokenParsed;
      return {
        firstname: wfkTokenParsed.firstname,
        surname: wfkTokenParsed.sn,
        email: wfkTokenParsed.sub
      }
    }
  }, [keycloak])
  
  const value: AppContextValue = { 
    customerScope,
    environment,
    toggleContactView: (t: ContactViewType) => setContactViewConfig(c => ({ open: !c.open, type: t})),
    contactViewConfig,
    user,
    isLoading,
    setIsLoading,
  }

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);

interface ContactViewConfig {
  open: boolean;
  type: ContactViewType;
}