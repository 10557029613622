import { Environment } from "../context";
import { getGroups } from "../dataQuery/auth";

const PREFIX_AGS = "PAM-AGS";
const PREFIX_ZWECKVERBAND = "PAM-ZV";
const AGS_SEARCH_PARAM_NAME = "ags";
const ZV_SEARCH_PARAM_NAME = "zv";

export namespace CustomerScopeUtils {
  export function getCustomerScopesByGroups(token?: { [prop: string]: any }): string[] {
    return getGroups(token)
      .filter((group) => group.startsWith(PREFIX_AGS) || group.startsWith(PREFIX_ZWECKVERBAND))
      .map((group) => transformGroupToCustomerScope(group))
      .filter(isString);
  }
  
  export function transformGroupToCustomerScope(group: string | null): string | undefined {
    if (!group) return undefined;
    if (group.startsWith(PREFIX_ZWECKVERBAND)) {
      // PAM-ZV-20800001_Laiern -> ZV_Laiern
      return "ZV_" + group.replace("PAM-ZV-", "").split("_")[1];
    }
    // PREFIX_AGS - only AGS left
    return group.replace("PAM-", "").split("_")[0].replace("-", "_");
  }
  
  export function isString(value: string | undefined): value is string {
    return typeof value === "string";
  }
  
  export function hasAdminRole(tokenParsed?: Keycloak.KeycloakTokenParsed) {
    const groups = getGroups(tokenParsed);
    return groups.includes("FGM-ADMIN_USER") || groups.includes("FGM-ADMIN_READER");
  }

  export function getCustomerScopeFromRequestParams(searchParams: URLSearchParams, env: Environment): string | null {
    if (!!searchParams.get(AGS_SEARCH_PARAM_NAME)) {
      // e.g. ags=08317114 (Rust) -> AGS_08317114
      return `AGS_${searchParams.get(AGS_SEARCH_PARAM_NAME)}`;
    }
    if (!!searchParams.get(ZV_SEARCH_PARAM_NAME)) {
      // e.g. zv=ZV_Laiern -> ZV_Laiern
      return searchParams.get(ZV_SEARCH_PARAM_NAME);
    }
    if (env === "dev" || env === "staging") {
      return "AGS_08116033";
    }
    return null;
  }
}