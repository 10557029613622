import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useWarenkorbApi } from "../../dataQuery/warenkorb/warenkorbApi";
import { Artikel } from "../../models";
import { File } from "../../models/Image";
import { WarenkorbKonfiguration, WarenkorbPosition } from "../../models/Warenkorb";
import { useAppContext } from "../AppContext/AppContext";
import { useGeoMap } from "../GeoMapContext";

export enum CartStep {
  PRODUKT_AUSWAHL, LEUCHTSTELLE, PRODUKT_SPEZIFIKATION, WARENKORB, BESTELLUEBERSICHT, BESTELL_BESTAETIGUNG
}

interface CartContextValue {
  warenkorbPosition?: WarenkorbPosition;
  setWarenkorbPosition: (wp?: WarenkorbPosition) => void;
  warenkorbKonfiguration?: WarenkorbKonfiguration;
  setWarenkorbKonfiguration: (wk?: WarenkorbKonfiguration) => void;
  image?: File;
  setImage: (img?: File) => void;
  activeStep: CartStep;
  setActiveStep: (s: CartStep) => void;
  upsertWarenkorbKonfiguration: (wk: WarenkorbKonfiguration, id?: string) => Promise<WarenkorbPosition>;
  selectedArtikel?: Artikel;
  setSelectedArtikel: (a?: Artikel) => void;
  deleteAll: () => void;
  isEditing: boolean;
  setIsEditing: (v: boolean) => void;
  isBasketConfirmationSet: boolean;
  setBasketConfirmation: (v: boolean) => void;
}

const initValue: CartContextValue = {
  setWarenkorbPosition: (_?: WarenkorbPosition) => undefined,
  setWarenkorbKonfiguration: (_?: WarenkorbKonfiguration) => undefined,
  setImage: (_?: File) => undefined,
  activeStep: 0,
  setActiveStep: () => undefined,
  upsertWarenkorbKonfiguration: async () => { throw new Error("upsertWarenkorbKonfiguration not implemented") },
  setSelectedArtikel: () => undefined,
  deleteAll: () => undefined,
  isEditing: false,
  setIsEditing: (v: boolean) => {},
  isBasketConfirmationSet: false,
  setBasketConfirmation: (v: boolean) => {},
}

const CartContext = createContext<CartContextValue>(initValue);

export function CartContextProvider(props: { children: ReactNode }) {
  const [warenkorbPosition, setWarenkorbPosition] = useState<WarenkorbPosition|undefined>();
  const [warenkorbKonfiguration, setWarenkorbKonfiguration] = useState<WarenkorbKonfiguration|undefined>();
  const [image, setImage] = useState<File|undefined>();
  const [activeStep, setActiveStep] = useState<CartStep>(0);
  const [selectedArtikel, setSelectedArtikel] = useState<Artikel|undefined>();
  const { selectedMarker, setSelectedMarker } = useGeoMap();
  const { setIsLoading } = useAppContext();
  const warenkorbApi = useWarenkorbApi();
  const [isEditing, setIsEditing] = useState(false);
  const [isBasketConfirmationSet, setBasketConfirmation] = useState(false);

  async function upsertWarenkorbKonfiguration(wk: WarenkorbKonfiguration, id?: string) {
    const lightingNr = (
      warenkorbPosition?.konfiguration.parameter?.find(p => p.parameterId === "LSTNR")?.wert || 
      selectedMarker?.nummer
    );
    if (!lightingNr) {
      throw new Error(
        "Error: No selected lightpoint (at upsertWarenkorbKonfiguration in CartContext)"
      );
    }
    setIsLoading(true);
    const newWk: WarenkorbKonfiguration = {
      ...wk,
      parameter: [{ parameterId: "LSTNR", wert: lightingNr }]
    }
    try {
      const wp = await warenkorbApi.upsertBasket(newWk, id);
      setWarenkorbPosition(wp);
      return wp;
    } catch (error) {
      console.error(error);
      throw new Error();
    } finally {
      setIsLoading(false);
    }
  }

  const handleOnSelectedMarkerChange = useCallback(() => {
    setWarenkorbKonfiguration(undefined);
    setWarenkorbPosition(undefined);
    setImage(undefined);
  }, []);

  const deleteAll = useCallback(() => {
    handleOnSelectedMarkerChange();
    setActiveStep(0);
    setSelectedMarker(undefined);
    setSelectedArtikel(undefined);
  }, [handleOnSelectedMarkerChange, setSelectedMarker]);

  useEffect(() => {
    handleOnSelectedMarkerChange();
  }, [selectedMarker, handleOnSelectedMarkerChange, selectedArtikel])

  return (
    <CartContext.Provider 
      value={{ 
        warenkorbPosition, 
        setWarenkorbPosition,
        warenkorbKonfiguration, 
        setWarenkorbKonfiguration,
        image,
        setImage,
        activeStep,
        setActiveStep,
        upsertWarenkorbKonfiguration,
        selectedArtikel,
        setSelectedArtikel,
        deleteAll,
        isEditing,
        setIsEditing,
        isBasketConfirmationSet,
        setBasketConfirmation
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export const useCartContext = () => useContext(CartContext);