import { TableCell, TableHead, TableRow } from "@mui/material";
import { forwardRef } from "react";
import { Else, If, Then } from "react-if";
import { WarenkorbPosition } from "../../../models/Warenkorb";

interface WarenkorbTableHeadProps {
  warenkorbPosition?: WarenkorbPosition | undefined;

  editable: boolean;
}

export const SSPBasketTableHead = forwardRef<HTMLTableCellElement, WarenkorbTableHeadProps>((props, ref) => {
  return (
    <TableHead>
      <TableRow>
        {props.editable ? null :
          <TableCell>Position</TableCell>
        }
        <TableCell>Produkt</TableCell>
        <TableCell>Jahr</TableCell>
        {props.warenkorbPosition?.konfiguration.parameter?.map(o => (

         o.parameterId === "BSTMNG" ? 
        <>
         <TableCell key={o.parameterId}>Stahl-/Aluminium-/Betonmast</TableCell>
         <TableCell key={o.parameterId}>Holzmast</TableCell>

         </> :
           null 
        
         
        ))}
        <TableCell className="whitespace-nowrap">Bearbeitet von</TableCell>
        <TableCell className="whitespace-nowrap text-right">Bearbeitet am</TableCell>
        <TableCell ref={ref} align="right"className="text-right" sx={{ minWidth: "120px", width: "120px" }}>Preis</TableCell>
        <If condition={props.editable}>
          <Then>
            <TableCell sx={{ width: "40px" }}/>
            <TableCell sx={{ width: "40px" }}/>
          </Then>
          <Else>
            <TableCell sx={{ minWidth: "68px", width: "68px" }}/>
          </Else>
        </If>
      </TableRow>
    </TableHead>
  );
});