import { Spinner } from "./Spinner";

interface LoadingProviderProps {
  isLoading: boolean;
  children: React.ReactNode;
}

export const LoadingProvider = (props: LoadingProviderProps) => {
  return <>
    {props.isLoading ? <Spinner /> : props.children}
  </>
}