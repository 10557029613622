export interface Gemeinde {
  vertriebsteam: keyof Vertriebsteam;
  eMailAdresse: string;
}

interface Vertriebsteam {
  Undefiniert: string;
  Nord: string;
  Mitte: string;
  Sued: string;
}

export const vertriebsTeamLabel: Vertriebsteam = {
    "Undefiniert": "Undefiniert",
    "Nord": "Nord",
    "Mitte": "Mitte",
    "Sued": "Süd"
}