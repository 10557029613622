import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { useAppContext } from "../../context";

export function MaxLightPointsDialog(props: DialogProps) {
  const { toggleContactView } = useAppContext();

  const handleOnClose = () =>  props.onClose?.({}, "backdropClick");

  function handleOnContact() {
    toggleContactView("anprech-person");
    handleOnClose();
  }

  return (
    <Dialog {...props}>
      <DialogTitle>Maximale Anzahl Lichtmasten pro Bestellung erreicht</DialogTitle>
      <DialogContent>Bei größeren Mengen als 3 Lichtmasten pro Bestellung bitte Ihren zuständigen Fachvertrieb kontaktieren</DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleOnClose}>Abbrechen</Button>
        <Button onClick={handleOnContact}>Kontaktieren</Button>
      </DialogActions>
    </Dialog>
  )
}