import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useBestellungen } from "../../dataQuery/bestellungen/bestellungen";
import MainBox from "../../layout/MainBox";
import { EmptyOrderTable } from "../EmptyOrderTable/EmptyOrderTable";
import { BestellungRow } from "./BestellungRow";

export const AlleBestellungen = () => {
  const { data: bestellungen, isLoading } = useBestellungen();

  return !bestellungen?.length 
    ? <EmptyOrderTable variant="bestellungen" isLoading={isLoading} /> 
    : (
    <MainBox title="Meine Bestellungen" description={"Hier finden Sie eine Übersicht über Ihre getätigten Bestellungen"}>
      <Divider className="mt-6 mb-10" />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="w-48">Datum</TableCell>
              <TableCell className="w-96">Bestellnummer</TableCell>
              <TableCell className="w-40">Preis</TableCell>
              <TableCell>Bestellt von</TableCell>
              <TableCell align="center">PDF</TableCell>
              <TableCell align="center">Produkte</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bestellungen?.map((bestellung) => 
              <BestellungRow key={bestellung.id + "bestellung"} bestellung={bestellung} />)
            }
          </TableBody>
        </Table>
      </TableContainer>
    </MainBox>
  );
}

