import { useKeycloak } from "../../context/KeycloakContext";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getConfiguration } from "../../config/Configuration";
import { ApiItemPath } from "./apiItem";

interface ApiRequestParamBodyless {
  url: ApiItemPath;
  config?: AxiosRequestConfig;
}

interface ApiRequestParamWithBody<B> extends ApiRequestParamBodyless{
  body?: B;
}

const config = getConfiguration();

const axiosClient = axios.create({ 
  baseURL: config.apiEndpointUrl,
  headers: {
    "Ocp-Apim-Subscription-Key": config.apiKey,
    "content-type": "application/json",
    "authorization": `Bearer `
  }
});

export function useApiClient() {
  const keycloak = useKeycloak();

  axiosClient.interceptors.request.use((config: any) => {
    config.headers.authorization = "Bearer " + keycloak.token;
    return config;
  });

  async function get<T>({url, config}: ApiRequestParamBodyless) {
    return handleRequest(axiosClient.get<T>(url, config));
  }

  async function post<T, B>({url, body, config}: ApiRequestParamWithBody<B>) {
    return handleRequest(axiosClient.post<T, AxiosResponse<T>, B>(url, body, config));
  }

  async function put<T, B>({url, body, config}: ApiRequestParamWithBody<B>) {
    return handleRequest(axiosClient.put<T, AxiosResponse<T>, B>(url, body, config));
  }

  async function remove<T>({url, config}: ApiRequestParamBodyless) {
    return handleRequest(axiosClient.delete<T>(url, config));
  }
    
  return {
    get, post, put, remove
  }
}

async function handleRequest<T, R = any>(axiosRes: Promise<AxiosResponse<T, R>>): Promise<T> {
  try {
    const res = await axiosRes;
    return res.data;
  } catch (error) {
    console.log(error);
    throw new Error((error as AxiosError)?.response?.statusText);
  }
}

export interface MultiEnvValue<T> {
  dev: T;
  staging: T;
  prod: T;
}