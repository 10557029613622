import format from "date-fns/fp/formatWithOptions";
import { de } from "date-fns/locale";
import { File } from "../models/Image";
import { LightPointInfo } from "../models/Warenkorb";

export namespace Utils {
  export const formatToLocalDate = format({ locale: de }, "dd.MM.yy HH:mm");

  export const formatToEUR = new Intl.NumberFormat(
    'de-DE',
    { style: 'currency', currency: 'EUR' }
  ).format;

  export function formatLightPointInfoAddress(info: LightPointInfo) {
    let street = "", city = "", address = "";
    if (info.Strasse) {
      street += info.Strasse;
      if (info?.Hausnummer) {
        street += " " + info.Hausnummer;
      }
      address = street;
    }
    if (info.Plz) {
      city += info.Plz;
    }
    if (info.Stadt) {
      if (city) {
        city += " ";
      }
      city += info.Stadt;
    }
    if (city) {
      if (address) {
        address += ", " + city;
      } else {
        address = city;
      }
    } 
    return {
      street,
      city,
      address
    };
  }

  export function getDataUrl(file: File) {
    return `data:${file.fileType};base64,${file.content}`;
  }

  export function downloadFile(file: File) {
    const link = document.createElement('a');
    link.style.display = "none";
    link.setAttribute("href", getDataUrl(file));
    link.setAttribute("download", file.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}