import { Typography } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import { FeedbackButton } from '../parts/FeedbackButton';

interface MainBoxProps extends BoxProps {
  title: string;
  description?: string | JSX.Element;
  alignLeft?: boolean;
}

export default function MainBox(props: MainBoxProps) {

  return (
    <Box className="h-full">
      <Box>
        <Box className={`mb-12 ${props.alignLeft ? "" : "text-center"}`}>
          <Box className="w-full text-right">
            <FeedbackButton />
          </Box>
          <Typography className="mb-4" variant='h1'>
            {'Bestellkorb Öffentliche Beleuchtung: ' + props.title}
          </Typography>
          <Typography>{props.description}</Typography>
        </Box>
        <Box>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}