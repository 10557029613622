import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material";
import { useGeoMap } from "../../context";

export function SpecificationExistsDialog({ onClose, ...props }: DialogProps) {
  const { setSelectedMarker } = useGeoMap();

  function handleOnClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    setSelectedMarker(undefined);
    onClose?.(event, reason);
  }

  return (
    <Dialog onClose={handleOnClose} {...props}>
      <DialogTitle>Leuchtstelle bereits im Warenkorb! </DialogTitle>
      <DialogContent>
        <Typography>
          Diese Leuchtstelle befindet sich bereits in Ihrem Warenkorb.<br/>
          Bitte wählen Sie eine andere aus oder gehen Sie zum Warenkorb.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClose({}, "backdropClick")}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}