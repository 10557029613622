import { 
  Box, 
  Button, 
  Typography 
} from "@mui/material";
import { useState } from "react";
import ImageUploading, { 
  ErrorsType, 
  ImageListType, 
  ImageType 
} from "react-images-uploading";
import { Image, File } from "../../models/Image";
import { useImageApi } from "../../dataQuery/file/imageApi";
import { useAppContext } from "../../context";
import Tooltip from '@mui/material/Tooltip';
import { When } from "react-if";
import { ImageDialog } from "../ImageDialog/ImageDialog";

interface ImageUploaderProps {
  onUploadImages: (images: File[]) => void;
  onDeleteImages: (ids: string[]) => void;
  uploadedImageIds: string[];
}

type ValidImage = Required<ImageType>;

export function ImageUploader({uploadedImageIds, ...props}: ImageUploaderProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [images, setImages] = useState<Image[]>([]);
  const imageApi = useImageApi();
  const { setIsLoading } = useAppContext();
  const [errorMessage, setErrorMessage] = useState("");

  const imageExists = uploadedImageIds.length;

  function handleOnError(errors: ErrorsType) {
    if (errors?.maxFileSize) {
      return setErrorMessage(errorMessageMap.maxFileSize);
    }
    if (errors?.maxNumber) {
      return setErrorMessage(errorMessageMap.maxNumber);
    }
    if (errors?.acceptType) {
      return setErrorMessage(errorMessageMap.acceptType);
    }
    return setErrorMessage("Fehler aufgetreten. Bitte den Admin kontaktieren.");
  }

  const onChange = (imageList: ImageListType) => {
    setErrorMessage("");
    const validImages = imageList.filter(i => !!i?.dataURL && !!i.file?.name) as ValidImage[];
    if (!validImages.length) {
      if (imageList.length) {
        setErrorMessage("Bilder konnten nicht hochgeladen werden, wählen Sie andere oder wenden Sie sich an den Administrator.");
      }
      return handleOnDelete(uploadedImageIds);
    }
    if (validImages.length + uploadedImageIds.length > MAX_IMAGE_NUMBER) {
      return setErrorMessage(errorMessageMap.maxNumber);
    }
    props.onUploadImages(
      validImages.map(img => buildImage(img.dataURL, img.file.name))
    );
  };

  async function handleOpenImageListDialog() {
    const res = await imageApi.get(uploadedImageIds);
    setImages(res);
    setOpenDialog(true);
  }

  function handleOnDelete(ids: string[]) {
    setIsLoading(true);
    setErrorMessage("");
    props.onDeleteImages(ids);
    setImages(imgs => imgs.filter(img => !ids.includes(img.id)));
    setIsLoading(false);
  }

  return (
    <ImageUploading
      onError={handleOnError}
      value={images}
      onChange={onChange}
      maxNumber={MAX_IMAGE_NUMBER}
      maxFileSize={MAX_FILE_SIZE}
      multiple
      acceptType={['jpg', 'gif', 'png', 'jpeg']}
    >
      {({
        onImageUpload,
        onImageRemoveAll,
        isDragging,
        dragProps
      }) => (
        <>
          <Tooltip 
            title={uploadedImageIds.length === 5
              ? "Maximal nur 5 Bilder erlaubt" 
              : "Klicken Sie hier, um Bilder hochzuladen"
            }
          >
            <Box className="flex-1 mr-3 relative">
                <Button
                  disabled={uploadedImageIds.length === 5}
                  variant="contained"
                  style={isDragging ? { color: "blue" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  color="inherit"
                  fullWidth
                >
                  Bild hochladen
                </Button>
              <When condition={!!errorMessage}>
                <Typography 
                  className="absolute mt-1 text-xs text-red-700" 
                  variant="body2"
                >
                  {errorMessage}
                </Typography>
              </When>
            </Box>
          </Tooltip>
          <Button
            className="flex-1 ml-3"
            variant="contained" 
            onClick={handleOpenImageListDialog} 
            disabled={!imageExists}
          >
            {uploadedImageIds.length} Bild
          </Button>
          <ImageDialog
            open={openDialog} 
            images={images}
            onClose={() => setOpenDialog(false)} 
            onDeleteAll={() => {
              onImageRemoveAll();
            }}
            onDelete={id => handleOnDelete([id])}
          />
        </>
      )}
    </ImageUploading>
  );
}

const buildImage = (dataURL: string, fileName: string): File => {
  const base64ContentArray = dataURL.split(",");
  return {
    fileType: base64ContentArray[0].substring(base64ContentArray[0].indexOf(":") + 1, base64ContentArray[0].indexOf(";")),
    content: base64ContentArray[1],
    fileName,
  }
}

const MAX_IMAGE_NUMBER = 5;
const MAX_FILE_SIZE = 3500000;
const errorMessageMap = {
  maxFileSize: "Die maximale Dateigröße beträgt 3,5 MB!",
  maxNumber: "Maximal 5 Bilder erlaubt!",
  acceptType: "Bitte ein Bild auswählen (falscher Datentyp)"
}