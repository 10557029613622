import { QueryClient } from "react-query";
import { useAppContext } from "../../context";
import { Image, File, StoredImage } from "../../models/Image";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";

const queryClient = new QueryClient();

export function useImageApi() {
  const apiClient = useApiClient();
  const {dateien, bilder} = useApiItemPath();
  const { setIsLoading } = useAppContext();

  async function get(ids: string[]) {
    setIsLoading(true);
    const images = await Promise.all(ids.map(id => 
      queryClient.fetchQuery(
        id, 
        () => apiClient.get<Image>({url: dateien.get(id)}),
        { staleTime: 1000 * 60 * 10 }
      ).then(res => {
        res.id = id;
        return res;
      })
    ));
    setIsLoading(false);
    return images;
  }

  async function post(images: File[]) {
    setIsLoading(true);
    const imageIds = await Promise.all(
      images.map(img =>
        apiClient.post<StoredImage, File>({url: bilder, body: img})
          .then(res => {
            queryClient.setQueryData(res.fileId, img);
            return res.fileId;
          })
      )
    );
    setIsLoading(false);
    return imageIds;
  }

  return { post, get }
}