import { getScaledIconSize } from "..";
import { IconProps } from "../index.types"

export const ZoomIcon = (props: IconProps) => (
<svg {...getScaledIconSize({width: 20, height: 20}, props)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.4081 16.5361L15.2 12.3281C15.052 12.1801 14.784 12.1801 14.636 12.3281L13.7682 13.2001L12.2961 11.7281C14.7641 8.96817 14.68 4.71623 12.0281 2.06424C9.28002 -0.683806 4.81178 -0.683806 2.06421 2.06424C-0.683837 4.81228 -0.683837 9.28052 2.06421 12.0281C3.44014 13.4 5.24416 14.0881 7.04807 14.0881C8.724 14.0881 10.4001 13.484 11.7319 12.2961L13.2 13.7641L12.3319 14.6322C12.1759 14.7922 12.1759 15.0442 12.3319 15.2002L16.536 19.4042C16.9198 19.7881 17.4279 20 17.9718 20C18.5119 20 19.0239 19.7881 19.4079 19.4041C19.7919 19.02 19.9998 18.5121 19.9998 17.972C19.9998 17.4281 19.7919 16.92 19.4079 16.5361H19.4081ZM11.4641 11.4601C9.02806 13.8961 5.0642 13.8961 2.63206 11.4601C0.196059 9.02817 0.196059 5.06415 2.63206 2.62815C3.84813 1.41208 5.44808 0.804193 7.04806 0.804193C8.64801 0.804193 10.2441 1.41225 11.4641 2.62815C13.9001 5.06415 13.9001 9.028 11.4641 11.4601Z" fill="white"/>
  <path d="M11.8682 6.964L10.8562 6.64006C10.8243 6.37204 10.7682 6.10803 10.6803 5.8601L11.4484 5.12819C11.6004 4.98823 11.6164 4.75219 11.4884 4.5881L10.4804 3.32819C10.3523 3.16814 10.1204 3.13215 9.94835 3.24817L9.06442 3.83209C8.83641 3.69214 8.59633 3.57612 8.34438 3.48807L8.2523 2.42804C8.23238 2.22397 8.06028 2.06409 7.85236 2.06409H6.2402C6.03228 2.06409 5.86018 2.22413 5.84428 2.42804L5.7522 3.48807C5.50025 3.57613 5.2602 3.69214 5.03216 3.83209L4.1442 3.24817C3.97227 3.13215 3.74024 3.16814 3.61218 3.32819L2.60821 4.5881C2.47629 4.75216 2.49219 4.98805 2.6442 5.12819L3.41228 5.8601C3.32825 6.10804 3.26831 6.37203 3.23633 6.64006L2.22435 6.964C2.02831 7.02795 1.9123 7.23202 1.95633 7.43594L2.31626 9.00391C2.3643 9.20799 2.55632 9.34392 2.76021 9.31195L3.81223 9.16797C3.95218 9.39197 4.12026 9.59989 4.31228 9.79193L3.93628 10.784C3.86429 10.98 3.94833 11.196 4.13634 11.2881L5.58829 11.988C5.64438 12.012 5.70431 12.028 5.76022 12.028C5.90018 12.028 6.03227 11.956 6.10425 11.832L6.64815 10.9199C6.92019 10.9479 7.17615 10.9479 7.4482 10.9199L7.98829 11.832C8.09627 12.0079 8.32027 12.0759 8.50828 11.988L9.9562 11.2881C10.1442 11.196 10.2323 10.98 10.1603 10.784L9.78427 9.79193C9.97227 9.59991 10.1404 9.39199 10.2843 9.16797L11.3323 9.31195C11.5364 9.34392 11.7323 9.20799 11.7763 9.00391L12.1362 7.43594C12.1842 7.23204 12.0642 7.02796 11.8682 6.964L11.8682 6.964ZM7.0485 9.23993C5.83646 9.23993 4.85259 8.25589 4.85259 7.04401C4.85259 5.83596 5.83663 4.85196 7.0485 4.85196C8.25656 4.85196 9.24056 5.836 9.24056 7.04401C9.24039 8.25606 8.25652 9.23993 7.0485 9.23993Z" fill="white"/>
</svg>
);

