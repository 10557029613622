import { Box, Paper, PaperProps, Typography } from "@mui/material";
import { When } from "react-if";
import { HistoricalLightPointVector } from "../../assets/vectors/HistoricalLightPointVector";

interface OrderProps extends PaperProps {
  historical?: boolean;
}

export const OrderNotice = ({ className, historical, ...paperProps}: OrderProps) => {
  return (
    <Paper {...paperProps} className={`p-4 items-start mt-2 bg-gray-200 flex flex-row border-red-500 border-solid border-2 ${className || ""}`}>
      <When condition={!!historical}>
        <HistoricalLightPointVector scaleFactor={1.3}/>
      </When>
      <Box className="ml-3 w-full">
        <Typography className="text-sm font-medium line" lineHeight={"16px"}>HINWEIS</Typography>
        <Typography fontSize={11} fontWeight={400}>
          {paperProps.children}
        </Typography>
      </Box>
    </Paper>
  );
}