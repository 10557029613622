import React, { memo, useCallback, useState } from "react";
import { createRoot } from 'react-dom/client';
import { Marker } from "./Marker";
import { LightPoint } from '../../models'
import { useGeoMap } from "../../context";
import { Button, Paper } from "@mui/material";
import { useLightPointsApi, useLightPointsByQuery } from "../../dataQuery/lightPoints";
import { HintInfoBox } from "./HintInfoBox";
import { GoogleMap } from "@react-google-maps/api";
import { styled } from "@mui/material/styles";
import { MapLoadingSpinner } from "./MapLoadingSpinner";
import { LightPointRequestQuery } from "../../dataQuery/lightPoints/lightPointsApi.types";

interface GeoMapProps {
  elevation?: number;
  style?: React.CSSProperties;
  showMaxMarkerInfo?: boolean;
  showAddNewLightPointInfo?: boolean;
  onShowFaultReport?: (id: string) => void;
  defaultCenter: google.maps.LatLngLiteral;
  defaultFetchType: "defaultCenter" | "customerScope";
}

type Bounds = google.maps.LatLngBounds;

// interface BoundsHistory {
//   prev?: Bounds;
//   current?: Bounds;
// }

/**
 * Renders a map, where light points of a specific municipality can be located.
 * This component currently interacts with the `reportCreationFormContext`.
 * Therefore, the TODO is to make it more generic so it could be used elsewhere
 * in the application without leading to invalid states or unexpected behaviors.
 */
export const GeoMap = (props: GeoMapProps) => {
  const {
    allLightPoints,
    setSelectedMarker,
    map,
    setMap,
    mapIsLoading,
    isOnMoveMapEffectsBlocked,
    blockOnMoveMapEffects,
  } = useGeoMap();

  // const queryClient = useQueryClient();

  const [query, setQuery] = useState<LightPointRequestQuery | undefined>();

  const { getLightPointsByKommune } = useLightPointsApi();

  useLightPointsByQuery(query);

  const handleZoomLevelChange = useCallback(() => {
    if (isOnMoveMapEffectsBlocked()) {
      blockOnMoveMapEffects(false);
      return;
    }
    blockOnMoveMapEffects(true);
    setQuery(buildLightpointsQuery(map));
  }, [map, blockOnMoveMapEffects, isOnMoveMapEffectsBlocked]);

  const handleMapDragend = useCallback(async () => {
    blockOnMoveMapEffects(true);
    setQuery(buildLightpointsQuery(map));
  }, [map, blockOnMoveMapEffects]);

  const handleMarkerClick = useCallback(
    (lightPoint: LightPoint) => {
      setSelectedMarker(lightPoint);
    },
    [setSelectedMarker]
  );

  const handleCloseMarkerInfoWindow = useCallback(() => {
    setSelectedMarker(undefined);
  }, [setSelectedMarker]);

  const onLoad = useCallback(
    async (map: google.maps.Map) => {
      pushSatelliteButtonOnMap(map);
      setMap(map);
      blockOnMoveMapEffects(true);
      if (allLightPoints?.length) {
        fitMapBounds({ map, lightPoints: allLightPoints });
        return;
      }
      try {
        const lightPoints = await getLightPointsByKommune();
        fitMapBounds({ map, lightPoints });
      } catch (error) {
        console.error(error);
        throw new Error("Leuchtstellen konnten nicht geladen werden");
      }
    },
    [setMap, blockOnMoveMapEffects, getLightPointsByKommune, allLightPoints]
  );

  function onUnmount() {
    blockOnMoveMapEffects(false);
  }

  // TODO
  // function handleOnBoundsChange() {
  //   if (preBounds.current.current) {
  //     preBounds.current.prev = preBounds.current.current;
  //   }
  //   preBounds.current.current = map?.getBounds();
  //   const direction = getMapDragDirection(preBounds.current.prev, preBounds.current.current);
  //   const directionCheck = 0;
  //   console.log(direction);
  // }

  return (
    <Paper
      elevation={props.elevation}
      style={{ display: "flex", position: "relative", flexDirection: "column", flex: 1, ...props.style }}
    >
      <GoogleMap
        center={props.defaultCenter}
        onUnmount={onUnmount}
        zoom={10}
        onLoad={onLoad}
        onZoomChanged={handleZoomLevelChange}
        onDragEnd={handleMapDragend}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          styles: [{ featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] }],
        }}
      >
        {allLightPoints?.map((lightPoint) => (
          <Marker
            key={lightPoint.nummer}
            lat={lightPoint.location.latitude}
            lng={lightPoint.location.longitute}
            lightingPoint={lightPoint}
            onClick={() => handleMarkerClick(lightPoint)}
            onCloseInfoWindow={handleCloseMarkerInfoWindow}
            onShowFaultReport={props?.onShowFaultReport}
          />
        ))}
      </GoogleMap>
      <MapLoadingSpinner isLoading={mapIsLoading} />
      {props.showMaxMarkerInfo && <HintInfoBox label=" Es werden maximal 500 Leuchtstellen auf der Karte angezeigt" />}
      {props.showAddNewLightPointInfo && (
        <HintInfoBox label="Leuchtstelle nicht gefunden? Einfach auf die Karte klicken und neuen Standort festlegen" />
      )}
    </Paper>
  );
};

export const fitMapBounds = (request: FitMapBoundsRequest): void => {
  if (!request.map) {
    return;
  }
  let bounds: Bounds;
  if (request.addressViewport) {
    bounds = request.addressViewport;
  } else if (request.lightPoints?.length) {
    bounds = new google.maps.LatLngBounds();
    request.lightPoints.forEach(( { location: { latitude, longitute } }) => {
      if (latitude && longitute) {
        bounds.extend(new google.maps.LatLng(latitude, longitute));
      }
    });
  } else {
    return console.warn("fitMapBounds needs either lightPoints or addressViewport to fit map bounds");
  }
  request.map.fitBounds(bounds, 10);
};

// interface Direction {
//   s: 0 | 1;
//   w: 0 | -3;
//   n: 0 | -1;
//   e: 0 | 3;
// }

// function getMapDragDirection(prevBounds?: Bounds, currentBounds?: Bounds): number {
//   const direction: Direction = { s: 0, w: 0, n: 0, e: 0 };
//   if (prevBounds && currentBounds) {
//     const prevBoundsNE: google.maps.LatLngLiteral = {
//       lat: prevBounds.getNorthEast().lat(),
//       lng: prevBounds.getNorthEast().lng(),
//     };
//     const currentBoundsNE: google.maps.LatLngLiteral = {
//       lat: currentBounds.getNorthEast().lat(),
//       lng: currentBounds.getNorthEast().lng(),
//     };

//     if (currentBoundsNE.lng !== prevBoundsNE.lng) {
//       if (currentBoundsNE.lng < prevBoundsNE.lng) {
//         direction.w = -3;
//       } else {
//         direction.e = 3;
//       }
//     }
//     if (currentBoundsNE.lat !== prevBoundsNE.lat) {
//       if (currentBoundsNE.lat > prevBoundsNE.lat) {
//         direction.n = -1;
//       } else {
//         direction.s = 1;
//       }
//     }
//   }
//   return direction.e + direction.n + direction.s + direction.w;
// }

interface FitMapBoundsRequest {
  map?: google.maps.Map;
  lightPoints?: LightPoint[];
  addressViewport?: google.maps.LatLngBounds;
}

export const SatelliteButton = ({ map }: { map: google.maps.Map }) => {
  const [onSatelliteView, setOnSatelliteView] = useState(false);

  function handleOnClick() {
    if (onSatelliteView) {
      map.setMapTypeId("roadmap");
    } else {
      map.setMapTypeId("satellite");
    }
    setOnSatelliteView(!onSatelliteView);
  }

  return (
    <WhiteButton variant="contained" onClick={handleOnClick}>
      {onSatelliteView ? "Straßenansicht" : "Satellitenansicht"}
    </WhiteButton>
  );
};

const WhiteButton = styled(Button)({
  color: "rgb(102, 102, 102)",
  backgroundColor: "white",
  fontWeight: 600,
  width: 180,
  marginBottom: "23px",
  "&:hover": {
    color: "black",
    backgroundColor: "white",
  },
});

const buildLightpointsQuery = (map?: google.maps.Map): LightPointRequestQuery | undefined => {
  if (!map) return undefined;
  const ne = map.getBounds()?.getNorthEast();
  const sw = map.getBounds()?.getSouthWest();
  return {
    nordOstLocation: !ne ? undefined : {
      latitude: ne?.lat(),
      longitute: ne?.lng()
    },
    suedWestLocation: !sw ? undefined : {
      latitude: sw?.lat(),
      longitute: sw?.lng()
    }
  };
};

function pushSatelliteButtonOnMap(map: google.maps.Map) {
  const controlButtonDiv = document.createElement("div");
  const modal = createRoot(controlButtonDiv);
  modal.render(<SatelliteButton map={map} />);
  map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(controlButtonDiv);
}

export default memo(GeoMap);