import theme from "../../theme";
import { Box, Typography } from "@mui/material";
import { GeoSearchBar } from "../GeoSearchBar";
import "./LightPointSelection.css";
import { GeoMap } from "../GeoMap";
import { ContactLink } from "../ContactView/ContactLink";
import { OrderNotice } from "../OrderNotice/OrderNotice";

const STUTTGART_COORDINATES = { lat: 48.783333, lng: 9.183333 };

export const LightPointSelection = () => {

  return (
    <Box className="LightPointSelection--container">
      <Box sx={{ flex: 2, marginRight: "20px" }}>
        <GeoSearchBar topComponent={<GeoSearchBarTopComponent />}/>
      </Box>
      <GeoMap 
        defaultCenter={STUTTGART_COORDINATES}
        elevation={0}
        style={{ flex: 5, backgroundColor: theme.palette.grey[200] }}
        defaultFetchType="customerScope"
      />
    </Box>
  );
};

function GeoSearchBarTopComponent() {
  return (
    <Box marginBottom={6}>
      <Typography marginBottom={1.5} variant="h3" fontWeight={500}>Lichtmast auswählen</Typography>
      <Typography fontSize={12} lineHeight={"20px"} marginBottom={3}>
        Bitte suchen und wählen Sie zunächst den auszutauschenden Lichtmast aus.
      </Typography>
      <Typography fontSize={12} lineHeight="20px">
        Klicken Sie anschließend ganz unten auf die Schaltfläche “Weiter zur Produktspezifikation”.
      </Typography>
      <OrderNotice historical>
        Wenn es sich bei dem Lichtmast um eine besondere Mastausführung handelt, dann <ContactLink label="klicken Sie bitte hier" type="besondere-mast"/>
      </OrderNotice>
    </Box>
  );
}
