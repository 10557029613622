import { Button, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Bestellung } from "../../models/Bestellung";
import { Utils } from "../../utils";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import { usePdfApi } from "../../dataQuery/file/pdfApi";
import { When } from "react-if";
import { TableCellBorderless } from "../../parts/TableCellBorderless";
import { LightPointAddress } from "../../parts/LightPointAddress";

interface BestellungRowProps {
  bestellung: Bestellung;
}

export const BestellungRow = ({ bestellung }: BestellungRowProps) => {
  const [rowOpen, setRowOpen] = useState(false);
  const pdfApi = usePdfApi();

  async function handleOnDownLoadPdf(id: string) {
    const pdf = await pdfApi.get(id);
    Utils.downloadFile(pdf);
  }

  return <>
    <TableRow key={bestellung.id}>
      <TableCell>{Utils.formatToLocalDate(new Date(bestellung.erzeugtAm))}</TableCell>
      <TableCell>{bestellung.bestellNr}</TableCell>
      <TableCell>{Utils.formatToEUR(bestellung.nettoPreis)}</TableCell>
      <TableCell>{`${bestellung.besteller.vorname} ${bestellung.besteller.nachname}`}</TableCell>
      <TableCell align="center">
        <When condition={!!bestellung.pdfZusammenfassungFileId}>
          <Button 
            onClick={() => handleOnDownLoadPdf(bestellung.pdfZusammenfassungFileId!)}
            className="p-0" 
            color="secondary" 
            startIcon={<DownloadIcon />}
          >
            PDF
          </Button>
        </When>
      </TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          onClick={() => setRowOpen(!rowOpen)}
        >
          {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow key={bestellung.id + "collapse"}>
      <TableCell className="p-0 bg-gray-200" colSpan={12}>
        <Collapse in={rowOpen} timeout="auto" unmountOnExit>
          <Table>
            <TableHead className="border-0 border-b-2 border-solid border-b-slate-100">
              <TableRow>
                <TableCell className="w-48">Produkt</TableCell>
                <TableCell className="w-96">Standort / Details</TableCell>
                <TableCell className="w-40">Preis</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { bestellung?.positionen?.map(position => (
                <TableRow key={position.id}>
                  <TableCellBorderless>{position.artikelName}</TableCellBorderless>
                  <TableCellBorderless> 
                    {position.zusatzInformationen.leuchtstelle ? <LightPointAddress lightPointInfo={position.zusatzInformationen.leuchtstelle} /> : null} 
                    {position.zusatzInformationen.jahr ? <span>{position.zusatzInformationen.jahr}: </span> : null}
                    {position.zusatzInformationen.standart ? <span>{position.zusatzInformationen.standart} Stahl-/Aluminium-/Betonmast </span> : null}
                    {position.zusatzInformationen.holz ? <span>{position.zusatzInformationen.jahr} Holzmast </span> : null}
                  </TableCellBorderless>
                  <TableCellBorderless>{Utils.formatToEUR(position.nettoPreis)}</TableCellBorderless>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
}