import { Dialog, DialogProps } from "@mui/material";
import { Spinner } from "./Spinner";

interface LoadingDialogProps extends DialogProps {
  open: boolean;
}

export const LoadingDialog = (props: LoadingDialogProps) => {
  return (
    <Dialog
      {...props}
      fullScreen
      PaperProps={{
        className: "bg-white w-screen h-screen bg-white bg-opacity-30 pl-16 flex justify-center",
      }}
    >
      <Spinner />
    </Dialog>
  );
};
