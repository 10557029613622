import { Box, Button, Typography } from "@mui/material";
import { EinkaufswagenIcon } from "../../assets/icons/EinkaufswagenIcon";
import { LoadingProvider } from "../Spinner/LoadingProvider";
import AddIcon from "@mui/icons-material/Add"
import theme from "../../theme";
import { useAppNavigate } from "../../hooks/appNavigation";

type EmptyOrderTableVariant = "warenkorb" | "bestellungen";

interface EmptyOrderTableProps {
  variant: EmptyOrderTableVariant;
  isLoading: boolean;
}

const variantLabel: { [k in EmptyOrderTableVariant]: string } = {
  bestellungen: "Noch keine Bestellungen vorhanden!",
  warenkorb: "Ihr Warenkorb ist leer!"
}

export const EmptyOrderTable = (props: EmptyOrderTableProps) => {
  const { navigateToNewOrder } = useAppNavigate();

  return (
    <Box className={`w-full min-h-full h-44 text-center`}>
      <LoadingProvider isLoading={props.isLoading}>
        <Box>
          <EinkaufswagenIcon scaleFactor={1.5} color={theme.palette.grey[600]}/>
          <Typography className="mt-2 text-black font-bold" variant="h4">
            {variantLabel[props.variant]}
          </Typography>
          <Typography className="mb-3 text-black font-bold" variant="h4">
            Klicken Sie unten um mit einer neuen Bestellung zu beginnen
          </Typography>
        </Box>
        <Button 
          onClick={navigateToNewOrder} 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
        >
          Produkt hinzufügen
        </Button>
      </LoadingProvider>
    </Box>
  );
}