
import { getScaledIconSize } from ".."
import { IconProps } from "../index.types"

export const HistoricalLightPointVector = (props: IconProps) => (
  <svg {...getScaledIconSize({width:11, height:52}, props)} viewBox="0 0 11 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.49437 6.58238L2.9342 14.0163H3.95719C4.149 14.3917 4.46493 14.6837 4.86087 14.8179L4.80778 22.7869C4.31799 23.0393 3.97963 23.544 3.97963 24.1332C3.97963 24.7146 4.30977 25.2137 4.78983 25.4703L4.73599 33.6284C4.30116 33.8183 3.95869 34.1869 3.82745 34.6644H2.84524C2.45864 34.6644 2.14458 34.9784 2.14458 35.365C2.14458 35.7516 2.45864 36.0657 2.84524 36.0657H8.15584C8.54244 36.0657 8.85649 35.7516 8.85649 35.365C8.85649 34.9784 8.54244 34.6644 8.15584 34.6644H7.17362C7.04239 34.1869 6.69991 33.819 6.26508 33.6284L6.21124 25.4703C6.69168 25.2149 7.02144 24.715 7.02144 24.1332C7.02144 23.5444 6.68308 23.0392 6.1933 22.7869L6.14021 14.8179C6.53652 14.6845 6.85208 14.3924 7.04388 14.0163H8.06792L9.50584 6.58238C9.76868 6.52555 9.96908 6.30272 9.96908 6.02381C9.96908 5.70152 9.7081 5.44054 9.38581 5.44054H9.30842L8.8878 3.75731C8.80069 3.40923 8.48887 3.16545 8.13069 3.16545H7.70334C7.51415 2.54331 7.05951 2.05842 6.48184 1.81914C6.52147 1.54321 6.65569 1.26617 6.90059 0.989111C6.70206 0.595783 6.19918 0.302658 5.59687 0.302658H5.40506C4.80461 0.302658 4.2987 0.595783 4.10134 0.989111C4.34511 1.26616 4.48045 1.54321 4.52009 1.81914C3.94132 2.05842 3.48781 2.54447 3.29859 3.16545H2.87124C2.51306 3.16545 2.20087 3.40923 2.11412 3.75731L1.69351 5.43939H1.61611C1.29383 5.43939 1.03285 5.70037 1.03285 6.02266C1.03098 6.3027 1.23138 6.52478 1.49422 6.58237L1.49437 6.58238ZM2.33486 6.98132H5.09564V13.2655H3.55263L2.33486 6.98132ZM7.44886 13.2667H5.90394V6.98132H8.66472L7.44886 13.2667Z" fill="#EE7700"/>
    <path d="M0.258667 48.9877H10.7413V51.2976H0.258667V48.9877Z" fill="#EE7700"/>
    <path d="M8.78104 46.9117L8.19105 36.5793H2.80971L2.21971 46.9117C1.59571 47.0033 1.1145 47.5357 1.1145 48.1862C1.1145 48.209 1.13357 48.2277 1.156 48.2277H9.84466C9.86746 48.2277 9.88616 48.2087 9.88616 48.1862C9.88616 47.5357 9.40497 47.0037 8.78095 46.9117H8.78104Z" fill="#EE7700"/>
  </svg>
);
