import MainBox from '../../layout/MainBox';
import { Stepper } from "../Stepper";
import { useEffect, useState } from 'react';
import { CurrentCartIcon, MyOrdersIcon, ProductSelectionIcon } from '../../assets/icons';
import { LightPointIcon } from '../../assets/icons/LightPointIcon';
import { ZoomIcon } from '../../assets/icons/ZoomIcon';
import { NavigationButton } from '../Home/Home';
import { ProductSelection } from '../ProductSelection';
import { Artikel } from '../../models';
import { LightPointSelection } from '../LightPointSelection/LightPointSelection';
import { useAppContext, useGeoMap } from '../../context';
import { Produktspezifikation } from '../Produktspezifikation/Produktspezifikation';
import { useArtikelDetails } from '../../dataQuery/artikel/useArtikelDetails';
import { useWarenkorb } from '../../dataQuery/warenkorb/useWarenkorb';
import { Warenkorb } from '../Warenkorb';
import { CartStep, useCartContext } from '../../context/CartContext/CartContext';
import { Bestelluebersicht } from '../Bestelluebersicht/Bestelluebersicht';
import { useAppNavigate } from '../../hooks/appNavigation';
import { OrderConfirmation } from '../OrderConfirmation';
import { useOrderClient } from '../../dataQuery/orderClient/orderClient';
import { AxiosError } from 'axios';
import { WarenkorbPositionParamterId } from '../../models/Warenkorb';
import { SpecificationExistsDialog } from './SpecificationExistsDialog';

const NEXT_LABEL = "Weiter";

export const stepLabels = {
  [CartStep.PRODUKT_AUSWAHL]: "Produktauswahl",
  [CartStep.LEUCHTSTELLE]: "Lichtmast auswählen",
  [CartStep.PRODUKT_SPEZIFIKATION]: "Produktspezifikation",
  [CartStep.WARENKORB]: "Warenkorb",
  [CartStep.BESTELLUEBERSICHT]: "Bestellübersicht",
  [CartStep.BESTELL_BESTAETIGUNG]: "Bestellbestätigung"
}

export function Cart() {
  const { navigateHome } = useAppNavigate();
  const { selectedMarker } = useGeoMap();
  const { 
    warenkorbPosition, 
    warenkorbKonfiguration,
    activeStep,
    setActiveStep,
    upsertWarenkorbKonfiguration,
    selectedArtikel,
    setSelectedArtikel,
    deleteAll,
    isEditing,
    setIsEditing,
    isBasketConfirmationSet
  } = useCartContext();
  const { data: artikelDetails } = useArtikelDetails(selectedArtikel?.id);
  const orderClient = useOrderClient();
  const { refetch: refetchWarenkorb } = useWarenkorb();
  const { setIsLoading } = useAppContext();
  const { data: warenkorb } = useWarenkorb();
  const [specificationExists, setSpecificationExists] = useState(false);
  const [agbAccepted, setAgbAccepted] = useState(false);

  function handleOnSelectArtikel(artikel: Artikel) {
    deleteAll();
    setSelectedArtikel(artikel);
    setActiveStep(CartStep.LEUCHTSTELLE);
  }
  
  useEffect(() => {
    return () => {
      setSelectedArtikel(undefined);
      setActiveStep(0);
    };
  // eslint-disable-next-line
  }, [])

  function isProduktspezifikationSubmitBtnDisabled() {
    if (!warenkorbKonfiguration) return true;
    if (!warenkorbPosition && !artikelDetails) return true;
    const requiredOptionenLength = (
      artikelDetails
        ? artikelDetails.optionen.length 
        : warenkorbPosition!.konfiguration.optionen.length
    );
    for(let i = 0; i < requiredOptionenLength; i++) {
      const option = warenkorbKonfiguration.optionen.find(wo => {
        const comparingOptionId = (
          artikelDetails 
            ? artikelDetails.optionen[i].id 
            : warenkorbPosition!.konfiguration.optionen[i].optionId
        );
        return wo.optionId === comparingOptionId;
      });
      if (!option) return true;
    }
    return false;
  }

  function isContinueButtonOnBasketDisable() {
    return !isBasketConfirmationSet;
  }

  async function handleOnWarenkorbKonfigurationSubmit() {
    if (!warenkorbKonfiguration) return;
    setIsLoading(true);
    try {
      await upsertWarenkorbKonfiguration(warenkorbKonfiguration, warenkorbPosition?.id);
      try {
        await refetchWarenkorb();
        setActiveStep(CartStep.WARENKORB);
      } catch (error) {
        console.error("Error refetch Warenkorb:", error);
        throw new Error();
      }
    } catch (error) {
      console.error("Error Warenkorb Konfiguration submit: ", error);
      throw new Error();
    } finally {
      setIsEditing(false);
      setIsLoading(false);
    }
  }

  async function handleOnBestelluebersichtSubmit() {
    setIsLoading(true);
    await orderClient.create();
    try {
      await refetchWarenkorb();   
    } catch (error) {
      throw new Error((error as AxiosError)?.response?.statusText);
    } finally {
      setActiveStep(CartStep.BESTELL_BESTAETIGUNG);
      setIsLoading(false);
    }
  }

  const positionForLightpointExists = () => warenkorb?.positionen.find(p => {
    const lightpointParam = p.konfiguration.parameter?.find(par => 
      par.parameterId === WarenkorbPositionParamterId.LightPoint
    );
    
    return lightpointParam?.wert === selectedMarker?.nummer;
  })!!;

  function handleOnLightPointSelectionSubmit() {
    if (positionForLightpointExists()) {
      setSpecificationExists(true);
    } else {
      setActiveStep(CartStep.PRODUKT_SPEZIFIKATION);
    }
  }

  return (
    <MainBox title={stepLabels[activeStep]}>
      {activeStep === CartStep.BESTELL_BESTAETIGUNG 
        ? <OrderConfirmation /> 
        : (
          <Stepper 
            activeStep={activeStep}
            onCancel={navigateHome}
            style={{ minHeight: "300px", width: "100%", maxWidth: "1200px", margin: "auto", display: "flex", flexDirection: "column" }}
            contentStyle={{ marginTop: 5, marginBottom: 3 }}
            onPrevClick={() => setActiveStep(activeStep - 1)}
            goBackBtnDesabled={
              (activeStep === CartStep.WARENKORB) ||
              ((activeStep === CartStep.PRODUKT_SPEZIFIKATION) && isEditing)
            }
            steps={[
              { 
                icon: <NavigationButton active={activeStep === CartStep.PRODUKT_AUSWAHL} size={40} Icon={<ProductSelectionIcon scaleFactor={0.65}/>} />, 
                label: stepLabels[CartStep.PRODUKT_AUSWAHL],
                hideSubmit: true,
                content: <ProductSelection onSelectArtikel={handleOnSelectArtikel}/>,
                submitDisabled: !selectedArtikel,
              },
              { 
                icon: <NavigationButton active={activeStep === CartStep.LEUCHTSTELLE} size={40} Icon={<LightPointIcon />} />,
                label: stepLabels[CartStep.LEUCHTSTELLE],
                submitBtnLabel: `${NEXT_LABEL} zur ${stepLabels[CartStep.PRODUKT_SPEZIFIKATION]}`,
                content: <LightPointSelection />,
                submitDisabled: !selectedMarker,
                onSubmit: handleOnLightPointSelectionSubmit
              },
              { 
                icon: <NavigationButton active={activeStep === CartStep.PRODUKT_SPEZIFIKATION} size={40} Icon={<ZoomIcon scaleFactor={1.2}/>} />,
                label: stepLabels[CartStep.PRODUKT_SPEZIFIKATION],
                submitBtnLabel: isEditing ? "Änderung übernehmen" : "Zum Warenkorb hinzufügen",
                content: <Produktspezifikation />,
                submitDisabled: isProduktspezifikationSubmitBtnDisabled(),
                onSubmit: handleOnWarenkorbKonfigurationSubmit,
              },
              { 
                icon: <NavigationButton active={activeStep === CartStep.WARENKORB} size={40} Icon={<CurrentCartIcon scaleFactor={0.5}/>} />,
                label: stepLabels[CartStep.WARENKORB],
                submitBtnLabel: NEXT_LABEL + ` zur ${stepLabels[CartStep.BESTELLUEBERSICHT]}`,
                content: <Warenkorb cartStep />,
                submitDisabled: isContinueButtonOnBasketDisable(),
                onSubmit: () => setActiveStep(CartStep.BESTELLUEBERSICHT)
              },
              { 
                icon: <NavigationButton active={activeStep === 4} size={40} Icon={<MyOrdersIcon scaleFactor={0.55}/>} />,
                label: stepLabels[CartStep.BESTELLUEBERSICHT],
                submitBtnLabel: "Kostenpflichtig bestellen",
                content: <Bestelluebersicht onAGBCheckboxChange={setAgbAccepted}/>,
                submitDisabled: !agbAccepted,
                onSubmit: handleOnBestelluebersichtSubmit
              }
            ]}
          />
        )
      }
      <SpecificationExistsDialog open={specificationExists} onClose={() => setSpecificationExists(false)} />
    </MainBox>
  );
}
