import { useEffect } from "react";
import { useQuery } from "react-query";
import { STATIC_DATA_STALE_TIME } from "..";
import { useAppContext } from "../../context";
import { Gemeinde } from "../../models";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";

export function useGemeinde() {
  const apiClient = useApiClient();
  const { customerScope } = useAppContext();
  const { gemeinden } = useApiItemPath();

  const { isError, error, ...query} = useQuery(
    "gemeinde", 
    () => apiClient.get<Gemeinde>({url: gemeinden}),
    { enabled: !!customerScope, refetchOnWindowFocus: false, staleTime: STATIC_DATA_STALE_TIME }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      throw new Error();
    }
  }, [isError, error])
  
  return query;
}