import { useEffect } from "react";
import { useQuery } from "react-query";
import { STATIC_DATA_STALE_TIME } from "..";
import { Artikel } from "../../models";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";

export function useArtikel() {
  const apiClient = useApiClient();
  const { artikel } = useApiItemPath();

  const { isError, error, ...query} = useQuery(
    ["artikel"], 
    () => apiClient.get<Artikel[]>({url: artikel.all}),
    { refetchOnWindowFocus: false, staleTime: STATIC_DATA_STALE_TIME }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      throw new Error();
    }
  }, [isError, error])
  
  return query;
}