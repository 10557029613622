import { useContext } from "react";
import { KeycloakContext } from "./KeycloakProvider";

export function useKeycloak() {
  const keycloak = useContext(KeycloakContext)

  if (!keycloak) {
    throw new Error(
      'useKeycloak hook must be used inside ReactKeycloakProvider context'
    )
  }

  if (!keycloak.clientId) {
    throw new Error('authClient has not been assigned correctly to KeycloakProvider')
  }

  return keycloak;
}