import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './components/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppContextProvider } from './context/AppContext/AppContext';
import { ReactQueryDevtools } from 'react-query/devtools'
import { authClient } from './dataQuery/auth';
import { Spinner } from './components/Spinner';
import { GeoMapProvider } from './context';
import { CartContextProvider } from './context/CartContext';
import { ErrorBoundary } from './components/App/ErrorBoundary';
import { CartOverviewContextProvider } from './context/CartOverviewContext/CartOverviewContext';
import { getConfiguration } from './config/Configuration';
import { AuthClientEvent, AuthClientTokens } from './context/KeycloakContext/types';
import { KeycloakProvider } from './context/KeycloakContext/KeycloakProvider';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

const queryClient = new QueryClient();

const handleKeycloakTokens = (tokens: AuthClientTokens) => {
  console.log("onKeycloakTokens: ", tokens.token);
};

const GOOGLE_MAPS_LIBRARIES = ["places", "geometry"];
const GOOGLE_MAPS_API_KEY = "AIzaSyAJKD2CxbWFEk5S2IVVD67fP7GdQJHy4T8";

const handleKeycloakEvent = (eventType: AuthClientEvent) => {
  console.log(eventType);
}

console.log("buildversion:", getConfiguration().buildVersion);

ReactDOM.render(
  <KeycloakProvider
    keycloak={authClient}
    onEvent={handleKeycloakEvent}
    onTokens={handleKeycloakTokens}
    LoadingComponent={<Spinner fullscreen />}
    initOptions={{ enableLogging: true, onLoad: "login-required" }}
  >
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AppContextProvider>
                <QueryClientProvider client={queryClient}>
                  <GeoMapProvider googleMapsApiKey={GOOGLE_MAPS_API_KEY} googleMapslibraries={GOOGLE_MAPS_LIBRARIES}>
                    <CartContextProvider>
                      <CartOverviewContextProvider>
                        <App />
                      </CartOverviewContextProvider>
                    </CartContextProvider>
                  </GeoMapProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </AppContextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  </KeycloakProvider>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
