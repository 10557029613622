import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface InputDescriptionProps {
  title: string;
  description: string;
  className?: string;
  sx?: SxProps;
}

export const InputDescription = (props: InputDescriptionProps) => (
  <Box className={props.className} sx={props.sx}>
    <Typography fontWeight={"700"}>{props.title}</Typography>
    <Typography>{props.description}</Typography>
  </Box>
);