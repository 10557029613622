import React from 'react';
import { useState } from "react";
import { LightPoint } from "../../models";
import { InfoWindow } from "./InfoWindow";
import { colors } from "../../theme";
import { Marker as GoogleMapsMarker } from "@react-google-maps/api";
import { useGeoMap } from '../../context';

interface Props {
  lightingPoint: LightPoint;
  lat: number;
  lng: number;
  onClick?: () => void;
  onCloseInfoWindow?: () => void;
  onShowFaultReport?: (incidentUuid: string) => void;
}

export const Marker = ({ lightingPoint, onClick, lat, lng, onCloseInfoWindow, onShowFaultReport }: Props) => {
  const [hover, toggleHoverEffect] = useState(false);
  const { selectedMarker } = useGeoMap();

  const handleOnCloseInfowWindow = () => {
    onCloseInfoWindow?.();
  };

  if (!lat || !lng) {
    return null;
  }

  return (
    <>
      <GoogleMapsMarker
        position={{ lat, lng }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: hover ? 4 : 3,
          fillColor: colors.status.completed,
          strokeColor: colors.status.completed,
          fillOpacity: 1,
          strokeOpacity: 1,
        }}
        onMouseOver={() => toggleHoverEffect(true)}
        onMouseOut={() => toggleHoverEffect(false)}
        onClick={onClick}
      />

      {(hover || selectedMarker?.nummer === lightingPoint.nummer) && (
        <InfoWindow
          lightPoint={lightingPoint}
          onClose={handleOnCloseInfowWindow}
          lat={lat}
          lng={lng}
          onShowFaultReport={onShowFaultReport}
        />
      )}
    </>
  );
};

export default Marker;
