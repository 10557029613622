import { useAppContext } from "../../context";
import { File } from "../../models/Image";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";

export function usePdfApi() {
  const apiClient = useApiClient();
  const { pdf, dateien } = useApiItemPath();
  const { setIsLoading } = useAppContext();

  return {
    get: async (id: string) => {
      setIsLoading(true);
      return apiClient.get<File>({url: dateien.get(id)}).finally(() => {
        setIsLoading(false);
      });
    },
    create: async () => {
      setIsLoading(true);
      return apiClient.get<File>({url: pdf.create}).finally(() => {
        setIsLoading(false);
      });
    }
  };
}