import { createContext, ReactNode, useContext, useMemo } from "react";
import { useWarenkorb } from "../../dataQuery/warenkorb/useWarenkorb";
import { WarenkorbKonfigurationOption, WarenkorbPosition, WarenkorbPositionOption } from "../../models/Warenkorb";

interface CartOverviewContextValue {
  sortedLightpointBasketPositionen?: WarenkorbPosition[];
  sortedSSPBasketPositions?: WarenkorbPosition[];
  lightpointBasketTableHeadPosition?: WarenkorbPosition;
}

const CartOverviewContext = createContext<CartOverviewContextValue>({});

export function CartOverviewContextProvider({ children }: { children: ReactNode; }) {
  const { data: warenkorb } = useWarenkorb();
  
  const lightpointBasketTableHeadPosition: WarenkorbPosition | undefined = useMemo(() => {
    if (!warenkorb?.positionen?.length) return undefined;
    let withMostOptionen: WarenkorbPosition = warenkorb.positionen[0];
    warenkorb.positionen.forEach(p => {
      if (p.konfiguration.optionen.length > withMostOptionen.konfiguration.optionen.length) {
        withMostOptionen = p;
      }
    })
    withMostOptionen.konfiguration.optionen.sort((a,b) => a.sequenzNummer - b.sequenzNummer);
    return withMostOptionen;
  }, [warenkorb]);

  const sortedSSPBasketPositions = useMemo(() => {
    const allSSPItems = warenkorb?.positionen.filter(p => 
     p.konfiguration.artikelId === "SSP"
    );
    allSSPItems?.forEach(p => 
      p.konfiguration.optionen.sort((a, b) => a.sequenzNummer - b.sequenzNummer)
    );
    return allSSPItems;
  }, [warenkorb]);

  const sortedLightpointBasketPositionen = useMemo(() => {
    const validPositionen = warenkorb?.positionen.filter(p => 
      !!p.konfiguration.optionen.every(o => isWarenkorbPositionOption(o)) && p.konfiguration.artikelId !== "SSP"
    );
    validPositionen?.forEach(p => 
      p.konfiguration.optionen.sort((a, b) => a.sequenzNummer - b.sequenzNummer)
    );
    return validPositionen;
  }, [warenkorb])

  return (
    <CartOverviewContext.Provider value={{sortedLightpointBasketPositionen, lightpointBasketTableHeadPosition, sortedSSPBasketPositions}}>
      {children}
    </CartOverviewContext.Provider>
  );
}

function isWarenkorbPositionOption(
  p : WarenkorbPositionOption | WarenkorbKonfigurationOption
): p is WarenkorbPositionOption 
{
  const wp = (p as WarenkorbPositionOption);
  return !!wp?.optionName && !!wp?.sequenzNummer;
}

export const useCartOverviewContext = () => useContext(CartOverviewContext);