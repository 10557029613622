import React from "react";

const HintIcon = () => {
    return (
      <svg width="18" height="19" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="-0.424805" y="0" width="18" height="19" fill="black">
          <rect fill="white" x="-0.424805" width="18" height="19" />
          <path d="M6.22048 14.0011C7.14193 14.9353 7.64915 16.1293 7.64915 17.3643V17.6186C7.64821 17.6459 7.65009 17.6733 7.65666 17.7016C7.6576 17.7052 7.66043 17.7088 7.66133 17.7125C7.66978 17.7444 7.68201 17.7735 7.69894 17.8018C7.70267 17.8073 7.70458 17.8137 7.70831 17.8182C7.72712 17.8464 7.75058 17.871 7.7769 17.8929C7.78158 17.8975 7.78628 17.9011 7.79192 17.9048C7.8201 17.9257 7.85112 17.9422 7.8849 17.9549C7.88868 17.9567 7.89245 17.9576 7.89618 17.9586C7.93188 17.9704 7.97038 17.9786 8.00985 17.9786C8.03895 17.9786 8.06714 17.974 8.09438 17.9677L12.295 17.0826C12.2987 17.0817 12.3006 17.0808 12.3044 17.0799C12.3185 17.0763 12.3325 17.0717 12.3466 17.0662C12.3551 17.0626 12.3635 17.0599 12.372 17.0562C12.3852 17.0508 12.3964 17.0435 12.4087 17.0362C12.4162 17.0316 12.4246 17.0271 12.4321 17.0216C12.4434 17.0134 12.4537 17.0042 12.464 16.9951C12.4706 16.9897 12.4772 16.9833 12.4828 16.9778C12.4932 16.9669 12.5016 16.9559 12.5101 16.9441C12.5148 16.9377 12.5195 16.9313 12.5242 16.9249C12.5317 16.9131 12.5382 16.9003 12.5439 16.8876C12.5476 16.8794 12.5514 16.8721 12.5552 16.8639C12.5599 16.852 12.5627 16.8393 12.5664 16.8265C12.5692 16.8165 12.5721 16.8064 12.573 16.7964C12.5739 16.7919 12.5758 16.7882 12.5758 16.7836C12.7054 15.761 13.192 14.7785 13.9463 14.0191C14.9147 13.0484 15.4482 11.7688 15.4482 10.4169C15.4482 8.975 14.8536 7.63419 13.7743 6.64255C12.696 5.65179 11.2851 5.14958 9.79451 5.22798C6.98788 5.37469 4.76552 7.62964 4.73452 10.3603C4.7223 11.7239 5.24925 13.0176 6.22048 14.0011ZM9.8358 5.92729C11.1236 5.85984 12.3474 6.29369 13.2801 7.15136C14.2147 8.00997 14.7285 9.16933 14.7285 10.4171C14.7285 11.5865 14.2673 12.6921 13.4286 13.5326C12.6273 14.3401 12.0947 15.3674 11.9031 16.4492L8.36185 17.1948C8.31771 15.8394 7.74945 14.5387 6.74158 13.517C5.90088 12.6657 5.44536 11.5473 5.45759 10.3679C5.48483 8.0045 7.40771 6.054 9.8358 5.92729Z" />
          <path d="M9.73246 11.5512V15.8841C9.73246 16.0774 9.89401 16.2341 10.0931 16.2341C10.2923 16.2341 10.4538 16.0774 10.4538 15.8841V11.5512L11.7576 10.286C11.8984 10.1493 11.8984 9.92783 11.7576 9.79113C11.6167 9.65439 11.3884 9.65439 11.2475 9.79113L10.0931 10.9113L8.93875 9.79113C8.79785 9.65439 8.56961 9.65439 8.42875 9.79113C8.28785 9.92783 8.28785 10.1493 8.42875 10.286L9.73246 11.5512Z" />
          <path d="M12.1221 17.7352L7.91212 18.6221C7.7177 18.6631 7.5937 18.8499 7.636 19.0386C7.67262 19.2027 7.82197 19.3148 7.9882 19.3148C8.01358 19.3148 8.03893 19.312 8.06523 19.3066L12.2752 18.4197C12.4697 18.3787 12.5936 18.1918 12.5514 18.0031C12.5081 17.8136 12.3165 17.6933 12.1221 17.7352Z" />
          <path d="M12.571 19.3385C12.5288 19.1498 12.3362 19.0296 12.1418 19.0706L7.93179 19.9574C7.73737 19.9984 7.61337 20.1853 7.65567 20.374C7.69229 20.538 7.84164 20.6502 8.00792 20.6502C8.03325 20.6502 8.05862 20.6474 8.08494 20.6419L12.2949 19.7551C12.4903 19.7141 12.6133 19.5272 12.571 19.3385Z" />
          <path d="M11.733 20.6073C11.7593 20.4159 11.6203 20.24 11.4221 20.2144C11.2248 20.1898 11.0436 20.3238 11.0173 20.5161C10.9572 20.9627 10.5589 21.3 10.092 21.3C9.84312 21.3 9.60736 21.2052 9.42984 21.033C9.37911 20.9837 9.33778 20.9327 9.3049 20.8808C9.2025 20.7148 8.98081 20.6611 8.80988 20.7604C8.63893 20.8598 8.5835 21.0749 8.6859 21.2408C8.74884 21.3419 8.82681 21.4385 8.91885 21.5279C9.23354 21.8323 9.64964 22 10.092 22C10.9195 22 11.6259 21.4012 11.733 20.6073Z" />
          <path d="M0.935892 10.5859H3.17986C3.379 10.5859 3.54056 10.4291 3.54056 10.2359C3.54056 10.0426 3.379 9.88586 3.17986 9.88586H0.935892C0.736754 9.88586 0.575195 10.0426 0.575195 10.2359C0.575195 10.4291 0.736754 10.5859 0.935892 10.5859Z" />
          <path d="M16.6455 10.2359C16.6455 10.4291 16.8071 10.5859 17.0062 10.5859H19.2503C19.4494 10.5859 19.611 10.4291 19.611 10.2359C19.611 10.0426 19.4494 9.88586 19.2503 9.88586H17.0062C16.8071 9.88586 16.6455 10.0426 16.6455 10.2359Z" />
          <path d="M10.0933 3.87746C10.2925 3.87746 10.454 3.72068 10.454 3.52746V1.35C10.454 1.15678 10.2925 1 10.0933 1C9.89416 1 9.73259 1.15678 9.73259 1.35V3.52746C9.73259 3.72068 9.89416 3.87746 10.0933 3.87746Z" />
          <path d="M14.9812 5.84271C15.0732 5.84271 15.1662 5.80898 15.2367 5.74062L16.8232 4.20116C16.9641 4.06443 16.9641 3.84295 16.8232 3.70622C16.6823 3.5695 16.454 3.5695 16.3131 3.70622L14.7266 5.24567C14.5857 5.38242 14.5857 5.6039 14.7266 5.7406C14.7971 5.80805 14.8891 5.84271 14.9812 5.84271Z" />
          <path d="M4.94941 14.7312L3.36289 16.2707C3.22199 16.4074 3.22199 16.6289 3.36289 16.7656C3.43333 16.834 3.52539 16.8677 3.61838 16.8677C3.71042 16.8677 3.80343 16.834 3.87387 16.7656L5.46036 15.2262C5.60127 15.0895 5.60127 14.8679 5.46036 14.7312C5.31854 14.5945 5.09029 14.5945 4.94941 14.7312Z" />
          <path d="M4.94949 5.73971C5.01995 5.80806 5.11199 5.84179 5.20498 5.84179C5.29704 5.84179 5.39003 5.80806 5.46051 5.73971C5.60137 5.603 5.60137 5.38148 5.46051 5.24478L3.874 3.70531C3.73309 3.56858 3.50485 3.56858 3.36397 3.70531C3.22308 3.84203 3.22306 4.06351 3.36397 4.20024L4.94949 5.73971Z" />
          <path d="M16.5677 16.8682C16.6598 16.8682 16.7527 16.8345 16.8232 16.7662C16.9641 16.6294 16.9641 16.4079 16.8232 16.2712L15.2367 14.7317C15.0958 14.595 14.8676 14.595 14.7267 14.7317C14.5858 14.8684 14.5858 15.09 14.7267 15.2267L16.3132 16.7662C16.3836 16.8336 16.4757 16.8682 16.5677 16.8682Z" />
        </mask>
        <path
          d="M6.22048 14.0011C7.14193 14.9353 7.64915 16.1293 7.64915 17.3643V17.6186C7.64821 17.6459 7.65009 17.6733 7.65666 17.7016C7.6576 17.7052 7.66043 17.7088 7.66133 17.7125C7.66978 17.7444 7.68201 17.7735 7.69894 17.8018C7.70267 17.8073 7.70458 17.8137 7.70831 17.8182C7.72712 17.8464 7.75058 17.871 7.7769 17.8929C7.78158 17.8975 7.78628 17.9011 7.79192 17.9048C7.8201 17.9257 7.85112 17.9422 7.8849 17.9549C7.88868 17.9567 7.89245 17.9576 7.89618 17.9586C7.93188 17.9704 7.97038 17.9786 8.00985 17.9786C8.03895 17.9786 8.06714 17.974 8.09438 17.9677L12.295 17.0826C12.2987 17.0817 12.3006 17.0808 12.3044 17.0799C12.3185 17.0763 12.3325 17.0717 12.3466 17.0662C12.3551 17.0626 12.3635 17.0599 12.372 17.0562C12.3852 17.0508 12.3964 17.0435 12.4087 17.0362C12.4162 17.0316 12.4246 17.0271 12.4321 17.0216C12.4434 17.0134 12.4537 17.0042 12.464 16.9951C12.4706 16.9897 12.4772 16.9833 12.4828 16.9778C12.4932 16.9669 12.5016 16.9559 12.5101 16.9441C12.5148 16.9377 12.5195 16.9313 12.5242 16.9249C12.5317 16.9131 12.5382 16.9003 12.5439 16.8876C12.5476 16.8794 12.5514 16.8721 12.5552 16.8639C12.5599 16.852 12.5627 16.8393 12.5664 16.8265C12.5692 16.8165 12.5721 16.8064 12.573 16.7964C12.5739 16.7919 12.5758 16.7882 12.5758 16.7836C12.7054 15.761 13.192 14.7785 13.9463 14.0191C14.9147 13.0484 15.4482 11.7688 15.4482 10.4169C15.4482 8.975 14.8536 7.63419 13.7743 6.64255C12.696 5.65179 11.2851 5.14958 9.79451 5.22798C6.98788 5.37469 4.76552 7.62964 4.73452 10.3603C4.7223 11.7239 5.24925 13.0176 6.22048 14.0011ZM9.8358 5.92729C11.1236 5.85984 12.3474 6.29369 13.2801 7.15136C14.2147 8.00997 14.7285 9.16933 14.7285 10.4171C14.7285 11.5865 14.2673 12.6921 13.4286 13.5326C12.6273 14.3401 12.0947 15.3674 11.9031 16.4492L8.36185 17.1948C8.31771 15.8394 7.74945 14.5387 6.74158 13.517C5.90088 12.6657 5.44536 11.5473 5.45759 10.3679C5.48483 8.0045 7.40771 6.054 9.8358 5.92729Z"
          fill="#FF9900"
        />
        <path
          d="M9.73246 11.5512V15.8841C9.73246 16.0774 9.89401 16.2341 10.0931 16.2341C10.2923 16.2341 10.4538 16.0774 10.4538 15.8841V11.5512L11.7576 10.286C11.8984 10.1493 11.8984 9.92783 11.7576 9.79113C11.6167 9.65439 11.3884 9.65439 11.2475 9.79113L10.0931 10.9113L8.93875 9.79113C8.79785 9.65439 8.56961 9.65439 8.42875 9.79113C8.28785 9.92783 8.28785 10.1493 8.42875 10.286L9.73246 11.5512Z"
          fill="#FF9900"
        />
        <path
          d="M12.1221 17.7352L7.91212 18.6221C7.7177 18.6631 7.5937 18.8499 7.636 19.0386C7.67262 19.2027 7.82197 19.3148 7.9882 19.3148C8.01358 19.3148 8.03893 19.312 8.06523 19.3066L12.2752 18.4197C12.4697 18.3787 12.5936 18.1918 12.5514 18.0031C12.5081 17.8136 12.3165 17.6933 12.1221 17.7352Z"
          fill="#FF9900"
        />
        <path
          d="M12.571 19.3385C12.5288 19.1498 12.3362 19.0296 12.1418 19.0706L7.93179 19.9574C7.73737 19.9984 7.61337 20.1853 7.65567 20.374C7.69229 20.538 7.84164 20.6502 8.00792 20.6502C8.03325 20.6502 8.05862 20.6474 8.08494 20.6419L12.2949 19.7551C12.4903 19.7141 12.6133 19.5272 12.571 19.3385Z"
          fill="#FF9900"
        />
        <path
          d="M11.733 20.6073C11.7593 20.4159 11.6203 20.24 11.4221 20.2144C11.2248 20.1898 11.0436 20.3238 11.0173 20.5161C10.9572 20.9627 10.5589 21.3 10.092 21.3C9.84312 21.3 9.60736 21.2052 9.42984 21.033C9.37911 20.9837 9.33778 20.9327 9.3049 20.8808C9.2025 20.7148 8.98081 20.6611 8.80988 20.7604C8.63893 20.8598 8.5835 21.0749 8.6859 21.2408C8.74884 21.3419 8.82681 21.4385 8.91885 21.5279C9.23354 21.8323 9.64964 22 10.092 22C10.9195 22 11.6259 21.4012 11.733 20.6073Z"
          fill="#FF9900"
        />
        <path
          d="M0.935892 10.5859H3.17986C3.379 10.5859 3.54056 10.4291 3.54056 10.2359C3.54056 10.0426 3.379 9.88586 3.17986 9.88586H0.935892C0.736754 9.88586 0.575195 10.0426 0.575195 10.2359C0.575195 10.4291 0.736754 10.5859 0.935892 10.5859Z"
          fill="#FF9900"
        />
        <path
          d="M16.6455 10.2359C16.6455 10.4291 16.8071 10.5859 17.0062 10.5859H19.2503C19.4494 10.5859 19.611 10.4291 19.611 10.2359C19.611 10.0426 19.4494 9.88586 19.2503 9.88586H17.0062C16.8071 9.88586 16.6455 10.0426 16.6455 10.2359Z"
          fill="#FF9900"
        />
        <path
          d="M10.0933 3.87746C10.2925 3.87746 10.454 3.72068 10.454 3.52746V1.35C10.454 1.15678 10.2925 1 10.0933 1C9.89416 1 9.73259 1.15678 9.73259 1.35V3.52746C9.73259 3.72068 9.89416 3.87746 10.0933 3.87746Z"
          fill="#FF9900"
        />
        <path
          d="M14.9812 5.84271C15.0732 5.84271 15.1662 5.80898 15.2367 5.74062L16.8232 4.20116C16.9641 4.06443 16.9641 3.84295 16.8232 3.70622C16.6823 3.5695 16.454 3.5695 16.3131 3.70622L14.7266 5.24567C14.5857 5.38242 14.5857 5.6039 14.7266 5.7406C14.7971 5.80805 14.8891 5.84271 14.9812 5.84271Z"
          fill="#FF9900"
        />
        <path
          d="M4.94941 14.7312L3.36289 16.2707C3.22199 16.4074 3.22199 16.6289 3.36289 16.7656C3.43333 16.834 3.52539 16.8677 3.61838 16.8677C3.71042 16.8677 3.80343 16.834 3.87387 16.7656L5.46036 15.2262C5.60127 15.0895 5.60127 14.8679 5.46036 14.7312C5.31854 14.5945 5.09029 14.5945 4.94941 14.7312Z"
          fill="#FF9900"
        />
        <path
          d="M4.94949 5.73971C5.01995 5.80806 5.11199 5.84179 5.20498 5.84179C5.29704 5.84179 5.39003 5.80806 5.46051 5.73971C5.60137 5.603 5.60137 5.38148 5.46051 5.24478L3.874 3.70531C3.73309 3.56858 3.50485 3.56858 3.36397 3.70531C3.22308 3.84203 3.22306 4.06351 3.36397 4.20024L4.94949 5.73971Z"
          fill="#FF9900"
        />
        <path
          d="M16.5677 16.8682C16.6598 16.8682 16.7527 16.8345 16.8232 16.7662C16.9641 16.6294 16.9641 16.4079 16.8232 16.2712L15.2367 14.7317C15.0958 14.595 14.8676 14.595 14.7267 14.7317C14.5858 14.8684 14.5858 15.09 14.7267 15.2267L16.3132 16.7662C16.3836 16.8336 16.4757 16.8682 16.5677 16.8682Z"
          fill="#FF9900"
        />
        <path
          d="M6.22048 14.0011C7.14193 14.9353 7.64915 16.1293 7.64915 17.3643V17.6186C7.64821 17.6459 7.65009 17.6733 7.65666 17.7016C7.6576 17.7052 7.66043 17.7088 7.66133 17.7125C7.66978 17.7444 7.68201 17.7735 7.69894 17.8018C7.70267 17.8073 7.70458 17.8137 7.70831 17.8182C7.72712 17.8464 7.75058 17.871 7.7769 17.8929C7.78158 17.8975 7.78628 17.9011 7.79192 17.9048C7.8201 17.9257 7.85112 17.9422 7.8849 17.9549C7.88868 17.9567 7.89245 17.9576 7.89618 17.9586C7.93188 17.9704 7.97038 17.9786 8.00985 17.9786C8.03895 17.9786 8.06714 17.974 8.09438 17.9677L12.295 17.0826C12.2987 17.0817 12.3006 17.0808 12.3044 17.0799C12.3185 17.0763 12.3325 17.0717 12.3466 17.0662C12.3551 17.0626 12.3635 17.0599 12.372 17.0562C12.3852 17.0508 12.3964 17.0435 12.4087 17.0362C12.4162 17.0316 12.4246 17.0271 12.4321 17.0216C12.4434 17.0134 12.4537 17.0042 12.464 16.9951C12.4706 16.9897 12.4772 16.9833 12.4828 16.9778C12.4932 16.9669 12.5016 16.9559 12.5101 16.9441C12.5148 16.9377 12.5195 16.9313 12.5242 16.9249C12.5317 16.9131 12.5382 16.9003 12.5439 16.8876C12.5476 16.8794 12.5514 16.8721 12.5552 16.8639C12.5599 16.852 12.5627 16.8393 12.5664 16.8265C12.5692 16.8165 12.5721 16.8064 12.573 16.7964C12.5739 16.7919 12.5758 16.7882 12.5758 16.7836C12.7054 15.761 13.192 14.7785 13.9463 14.0191C14.9147 13.0484 15.4482 11.7688 15.4482 10.4169C15.4482 8.975 14.8536 7.63419 13.7743 6.64255C12.696 5.65179 11.2851 5.14958 9.79451 5.22798C6.98788 5.37469 4.76552 7.62964 4.73452 10.3603C4.7223 11.7239 5.24925 13.0176 6.22048 14.0011ZM9.8358 5.92729C11.1236 5.85984 12.3474 6.29369 13.2801 7.15136C14.2147 8.00997 14.7285 9.16933 14.7285 10.4171C14.7285 11.5865 14.2673 12.6921 13.4286 13.5326C12.6273 14.3401 12.0947 15.3674 11.9031 16.4492L8.36185 17.1948C8.31771 15.8394 7.74945 14.5387 6.74158 13.517C5.90088 12.6657 5.44536 11.5473 5.45759 10.3679C5.48483 8.0045 7.40771 6.054 9.8358 5.92729Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M9.73246 11.5512V15.8841C9.73246 16.0774 9.89401 16.2341 10.0931 16.2341C10.2923 16.2341 10.4538 16.0774 10.4538 15.8841V11.5512L11.7576 10.286C11.8984 10.1493 11.8984 9.92783 11.7576 9.79113C11.6167 9.65439 11.3884 9.65439 11.2475 9.79113L10.0931 10.9113L8.93875 9.79113C8.79785 9.65439 8.56961 9.65439 8.42875 9.79113C8.28785 9.92783 8.28785 10.1493 8.42875 10.286L9.73246 11.5512Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M12.1221 17.7352L7.91212 18.6221C7.7177 18.6631 7.5937 18.8499 7.636 19.0386C7.67262 19.2027 7.82197 19.3148 7.9882 19.3148C8.01358 19.3148 8.03893 19.312 8.06523 19.3066L12.2752 18.4197C12.4697 18.3787 12.5936 18.1918 12.5514 18.0031C12.5081 17.8136 12.3165 17.6933 12.1221 17.7352Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M12.571 19.3385C12.5288 19.1498 12.3362 19.0296 12.1418 19.0706L7.93179 19.9574C7.73737 19.9984 7.61337 20.1853 7.65567 20.374C7.69229 20.538 7.84164 20.6502 8.00792 20.6502C8.03325 20.6502 8.05862 20.6474 8.08494 20.6419L12.2949 19.7551C12.4903 19.7141 12.6133 19.5272 12.571 19.3385Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M11.733 20.6073C11.7593 20.4159 11.6203 20.24 11.4221 20.2144C11.2248 20.1898 11.0436 20.3238 11.0173 20.5161C10.9572 20.9627 10.5589 21.3 10.092 21.3C9.84312 21.3 9.60736 21.2052 9.42984 21.033C9.37911 20.9837 9.33778 20.9327 9.3049 20.8808C9.2025 20.7148 8.98081 20.6611 8.80988 20.7604C8.63893 20.8598 8.5835 21.0749 8.6859 21.2408C8.74884 21.3419 8.82681 21.4385 8.91885 21.5279C9.23354 21.8323 9.64964 22 10.092 22C10.9195 22 11.6259 21.4012 11.733 20.6073Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M0.935892 10.5859H3.17986C3.379 10.5859 3.54056 10.4291 3.54056 10.2359C3.54056 10.0426 3.379 9.88586 3.17986 9.88586H0.935892C0.736754 9.88586 0.575195 10.0426 0.575195 10.2359C0.575195 10.4291 0.736754 10.5859 0.935892 10.5859Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M16.6455 10.2359C16.6455 10.4291 16.8071 10.5859 17.0062 10.5859H19.2503C19.4494 10.5859 19.611 10.4291 19.611 10.2359C19.611 10.0426 19.4494 9.88586 19.2503 9.88586H17.0062C16.8071 9.88586 16.6455 10.0426 16.6455 10.2359Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M10.0933 3.87746C10.2925 3.87746 10.454 3.72068 10.454 3.52746V1.35C10.454 1.15678 10.2925 1 10.0933 1C9.89416 1 9.73259 1.15678 9.73259 1.35V3.52746C9.73259 3.72068 9.89416 3.87746 10.0933 3.87746Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M14.9812 5.84271C15.0732 5.84271 15.1662 5.80898 15.2367 5.74062L16.8232 4.20116C16.9641 4.06443 16.9641 3.84295 16.8232 3.70622C16.6823 3.5695 16.454 3.5695 16.3131 3.70622L14.7266 5.24567C14.5857 5.38242 14.5857 5.6039 14.7266 5.7406C14.7971 5.80805 14.8891 5.84271 14.9812 5.84271Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M4.94941 14.7312L3.36289 16.2707C3.22199 16.4074 3.22199 16.6289 3.36289 16.7656C3.43333 16.834 3.52539 16.8677 3.61838 16.8677C3.71042 16.8677 3.80343 16.834 3.87387 16.7656L5.46036 15.2262C5.60127 15.0895 5.60127 14.8679 5.46036 14.7312C5.31854 14.5945 5.09029 14.5945 4.94941 14.7312Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M4.94949 5.73971C5.01995 5.80806 5.11199 5.84179 5.20498 5.84179C5.29704 5.84179 5.39003 5.80806 5.46051 5.73971C5.60137 5.603 5.60137 5.38148 5.46051 5.24478L3.874 3.70531C3.73309 3.56858 3.50485 3.56858 3.36397 3.70531C3.22308 3.84203 3.22306 4.06351 3.36397 4.20024L4.94949 5.73971Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M16.5677 16.8682C16.6598 16.8682 16.7527 16.8345 16.8232 16.7662C16.9641 16.6294 16.9641 16.4079 16.8232 16.2712L15.2367 14.7317C15.0958 14.595 14.8676 14.595 14.7267 14.7317C14.5858 14.8684 14.5858 15.09 14.7267 15.2267L16.3132 16.7662C16.3836 16.8336 16.4757 16.8682 16.5677 16.8682Z"
          stroke="white"
          mask="url(#path-1-outside-1)"
        />
      </svg>
    );
}

export default HintIcon
