import styled from "@emotion/styled";
import { Link, LinkProps } from "react-router-dom";
import theme from "../theme";

export const UnstyledLink = ({style, ...props}: LinkProps) => <Link {...props} style={{ textDecoration: "none", ...style }}/>;

export const LinkButton = styled.span`
  color: ${theme.palette.secondary.main};
  &:hover {
    cursor: pointer;
  }
`;