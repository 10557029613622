import { Box, Button, FormControlLabel, FormGroup } from "@mui/material";
import { useWarenkorb } from "../../dataQuery/warenkorb/useWarenkorb";
import { StepHeader } from "../Stepper/StepHeader";
import { WarenkorbTable } from "./WarenkorbTable";
import AddIcon from "@mui/icons-material/Add"
import { CartStep, useCartContext } from "../../context/CartContext/CartContext";
import { Checkbox } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { useAppNavigate } from "../../hooks/appNavigation";
import MainBox from "../../layout/MainBox";
import { stepLabels } from "../Cart";
import { EmptyOrderTable } from "../EmptyOrderTable/EmptyOrderTable";

interface WarenkorbProps {
  cartStep?: boolean;
}

export const Warenkorb = ({ cartStep }: WarenkorbProps) => {
  const { data: warenkorbTO, isLoading } = useWarenkorb();
  const { setBasketConfirmation, isBasketConfirmationSet } = useCartContext();
  const isWarenkorbEmpty = !warenkorbTO?.positionen.length;
  const { navigateToNewOrder, navigateToBestelluebersicht } = useAppNavigate();

  useEffect(() => {
    return () => {
      setBasketConfirmation(false);
    };
  // eslint-disable-next-line
  }, []);

  if (isWarenkorbEmpty) {
    return <EmptyOrderTable variant="warenkorb" isLoading={isLoading} />;
  }

  return (
    <WarenkorbContainer cartStep={cartStep}>
      <Box className={`w-full min-h-full my-5 text-right`}>
        <Button 
          onClick={navigateToNewOrder} 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
        >
          Produkt hinzufügen
        </Button>
      </Box>
      <Box>
        <WarenkorbTable />
        <FormGroup>
          <FormControlLabel 
            control={<Checkbox onChange={(e) => setBasketConfirmation(!!e.target.checked)} />} 
            label="Hiermit bestätige ich die Vollständigkeit meiner Angaben*" 
          />
        </FormGroup>
        {cartStep ? null :
        <Box className="text-center">
          <Button
            disabled={!isBasketConfirmationSet}
            onClick={navigateToBestelluebersicht} 
            className="mt-5 w-72" 
            variant="contained" 
            color="secondary"
            size="large"
          >
            Weiter zur {stepLabels[CartStep.BESTELLUEBERSICHT]}
          </Button>
        </Box>
        }
      </Box>
    </WarenkorbContainer>
  );
}

const title = "Warenkorb";
const description = <span>
  Hier finden Sie eine Übersicht über Ihre zur Bestellung vorgesehenen Produkte.<br/>
  Bei Bedarf können Sie hier noch weitere Produkte hinzufügen oder ggf. auch wieder entfernen.<br />
  {/* Außerdem können Sie sich, z.B. für interne Genehmigungsprozesse, ein PDF erstellen. */}
</span>;

interface WarenkorbContainerProps extends WarenkorbProps, PropsWithChildren {}

const WarenkorbContainer = (props: WarenkorbContainerProps) => props.cartStep 
  ? (
    <Box>
      <StepHeader
        title={title}
        description={description}
      />
      {props.children}
    </Box>
  ) : (
  <MainBox title={title} description={description}>
    {props.children}
  </MainBox>
);