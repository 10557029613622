import { Box, Button, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { StepperBar } from "../StepperBar";
import { When } from "react-if";
import { StickyBox } from "../../parts/StickyBox";

interface StepperProps {
  goBackBtnDesabled?: boolean;
  activeStep: number;
  title?: string;
  steps: StepInfo[];
  onCancel: () => void;
  style?: SxProps<Theme>;
  contentStyle?: SxProps<Theme>;
  onActiveStepChange?: (i: number) => void;
  submitStepIndex?: number;
  onPrevClick?: () => void;
}

interface StepInfo {
  label: string;
  icon: JSX.Element;
  content: JSX.Element;
  submitBtnLabel?: string;
  onSubmit?: () => void;
  hideSubmit?: boolean;
  submitDisabled: boolean;
}

export function Stepper({ activeStep, ...props}: StepperProps) {
  
  const stepBarSteps = props.steps.map(s => ({ label: s.label, icon: s.icon }));

  function handlePrevClick() {
    props?.onActiveStepChange?.(activeStep - 1);
    props?.onPrevClick?.();
  }

  function handleClickNext() {
    if (activeStep < props.steps.length - 1) {
      props?.onActiveStepChange?.(activeStep + 1);
    }
    props.steps[activeStep].onSubmit?.();
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        {props.title}
      </Typography>
      <Box sx={props.style}>
        <StepperBar 
          index={activeStep}
          steps={stepBarSteps}
        />
        <Box sx={props.contentStyle}>
          {props.steps[activeStep].content}
        </Box>
      </Box>
      <StickyBox
        className="justify-center items-center text-center py-2"
        stateHandlerDependency={activeStep}
      >
        <Box>
          <When condition={activeStep > 0}>
            <Button 
              className="w-72 mr-3"
              variant="outlined" 
              size="large"
              disabled={props.goBackBtnDesabled} 
              color="secondary" 
              onClick={handlePrevClick}
            >
              Zurück
            </Button>
          </When>
          <When condition={!props.steps[activeStep].hideSubmit}>
            <Button 
              className="w-72"
              variant="contained" 
              size="large"
              disabled={props.steps[activeStep].submitDisabled}
              color="secondary"
              onClick={handleClickNext}
            >
              {props.steps[activeStep].submitBtnLabel}
            </Button>
          </When>
        </Box>
        <Button 
          onClick={props.onCancel}
          className="p-0 mt-2 text-gray-500" 
        >
          Abbrechen
        </Button> 
      </StickyBox>
    </Box>
  );
}
