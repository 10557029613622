import { useEffect } from "react";
import { useQuery } from "react-query";
import { useAppContext } from "../../context";
import { useLightPointsApi } from "./lightPointsApi";
import { LightPointRequestQuery } from "./lightPointsApi.types";

export function useLightPointsByQuery(req?: LightPointRequestQuery) {
  const { getLightPointsByQuery } = useLightPointsApi();

  const { isError, error, ...query} = useQuery(["lightPoints", getKeyByQuery(req)], () => getLightPointsByQuery(req), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!req
  });

  useEffect(() => {
    if (isError) {
      console.log(error);
      throw new Error();
    }
  }, [isError, error])
  
  return query;
}

export function useLightPointsByKommune() {
  const { customerScope } = useAppContext();
  const { getLightPointsByKommune } = useLightPointsApi();

  const { isError, error, ...query} = useQuery(
    ["lightPoints", "kommune"], 
    getLightPointsByKommune, 
    { keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!customerScope }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      throw new Error();
    }
  }, [isError, error])
  
  return query;
}

const getKeyByQuery = (query?: LightPointRequestQuery) => !query ? null : (
  Object.values({ 
    nordOstLocation: query.nordOstLocation,
    suedWestLocation: query.suedWestLocation,
    nummer: query.nummer
  } as LightPointRequestQuery)
)
