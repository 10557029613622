import { AxiosError } from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useAppContext } from "../../context";
import { Bestellung } from "../../models/Bestellung";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";

export function useBestellungen() {
  const { customerScope } = useAppContext();
  const apiClient = useApiClient();
  const { order } = useApiItemPath();

  const { isError, error, remove, data, ...query} = useQuery<Bestellung[], AxiosError>(
    "bestellungen", 
    () => apiClient.get<Bestellung[]>({url: order}),
    { refetchOnWindowFocus: true, enabled: !!customerScope, retry: 1 }
  );

  useEffect(() => {
    if (isError) {
      if (error?.response?.status !== 404) {
        throw new Error(error?.response?.statusText);
      }
      if (data) {
        remove();
      }
    }
  }, [isError, error, data, remove])
  
  return { data, ...query };
}