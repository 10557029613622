import { Box, Button, Checkbox, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { StepHeader } from "../../Stepper/StepHeader";
import { InputDescription } from "../../../parts/InputDescription";
import { useArtikelDetails } from "../../../dataQuery/artikel/useArtikelDetails";
import { useAppContext } from "../../../context";
import { useEffect, useState } from "react";
import { Mapper } from "../../../utils/Mapper";
import { Pole, StandsicherheitFormModel, StandsicherheitRenderData, YearsToOrderFormModel } from "../../../models/Standsicherheit";

import { OrderNotice } from "../../OrderNotice/OrderNotice";
import "./Standsicherheit.css"
import { useAppNavigate } from "../../../hooks/appNavigation";
import { useCartContext } from "../../../context/CartContext";
import { useWarenkorbApi } from "../../../dataQuery/warenkorb/warenkorbApi";
import { WarenkorbKonfiguration, WarenkorbPosition } from "../../../models/Warenkorb";
import { useWarenkorb } from "../../../dataQuery/warenkorb/useWarenkorb";
import { Utils } from "../../../utils";
import { SSPExistsDialog } from "../../Cart/SSPExistsDialog";
import { colors } from "../../../theme";
import { Spinner } from "../../Spinner";

export function Standsicherheit() {
  const [sspExistsInBasket, setSspExistsInBasket] = useState(false);
  const { data, isLoading } = useArtikelDetails("SSP");
  const { setIsLoading } = useAppContext();
  const { navigateHome, navigateToWarenkorbPage } = useAppNavigate();
  const { 
    setWarenkorbPosition
  } = useCartContext();
  const { refetch: refetchWarenkorb, data: basket } = useWarenkorb();

  const warenkorbApi = useWarenkorbApi();

  const initFormModel :  StandsicherheitFormModel=  {
    yearsToOrder: [],
    yearsToOrderData:  [],
    comment: ""
  }

  const [comment, setComment] = useState<string>("");
  const [formModel, setFormModel] = useState<StandsicherheitFormModel>(initFormModel);
  const standsicherheitDto = Mapper.mapToStandsicherheitModel(data?.parameter[0].metaDaten);

  function orderForYearPossible(year: number, data: StandsicherheitRenderData): boolean {
    if (data.availableYearsToOrder?.includes(year)) {
      return true;
    }
    return false;
  }

  function orderExistsForYear(year: number, data: StandsicherheitRenderData): boolean {
    if (data.orderedYears?.includes(year)) {
      return true;
    }
    return false;
  }

  function setYearToOrder(year: number) {
    const indexOfElement = formModel.yearsToOrder.indexOf(year) 

    formModel.yearsToOrder.length=0;
    if (indexOfElement === -1) { 
      formModel.yearsToOrder.push(year);
    }
    else {
      formModel.yearsToOrder.splice(indexOfElement, 1);
    
    }
    formModel.yearsToOrderData = [];
    formModel.yearsToOrderData.push(...mapPoleToYearsFormModel(standsicherheitDto.poles, formModel.yearsToOrder));
  
   
    setFormModel({...formModel});
  }

  function mapPoleToYearsFormModel(poles: Pole[], years: number[]): YearsToOrderFormModel[] {
   
    const yearsToOrderData: YearsToOrderFormModel[] = [];
    if(years.length === 0) {
      return yearsToOrderData;
    }

    poles.forEach(pole => {
      const poleType = pole.poleType;
      const poleYearData = pole.yearData.filter(y => years.includes(y.year));
      if (poleYearData) {

        let netPrice = 0;
        let orderCount = 0;

        poleYearData.forEach(poleYearData => {
          netPrice = poleYearData.netPrice;
          orderCount += poleYearData.availableToOrder;
        });

        yearsToOrderData.push({
          poleType: poleType,
          netPrice,
          orderCount,
          year: poleYearData[0].year
        });
      }
    });
    return yearsToOrderData;

  }

  function mapPoleTypeToDisplayValue(poleType: string): string {
    if(poleType === "Holz") {
      return "Holzmasten";
    }
    if (poleType === "Standart") {
      return "Stahl-/Aluminium-/Betonmast"
    }
    return ""
  }

  function createBasketConfiguration(year:number): WarenkorbKonfiguration {
    let anzahlHolzMast = 0;
    let anzahlStandartMast = 0;
    standsicherheitDto.poles.forEach(pole => {
      const yearData = pole.yearData.filter( data => data.year === year)
      if(yearData.length === 0) {
        return;
      }
      if(pole.poleType === "Holz") {
        anzahlHolzMast =yearData[0].availableToOrder
      }
      if (pole.poleType === "Standart") {
        anzahlStandartMast = yearData[0].availableToOrder
      }
    });
    
    const configuration: WarenkorbKonfiguration = {
      artikelId: "SSP",
      optionen: [],
      eigenschaften: [{
        eigenschaftId: "SSPKOMM",
        wert: comment
      }],
      parameter: [{ parameterId: "BSTMNG", wert: {    
        "jahr": year,
        "anzahlHolzMast": anzahlHolzMast,
        "anzahlStandartMast": anzahlStandartMast
    } }]
    }
    return configuration;
  }

  function handleOnCancelClick() {
    navigateHome();
  }

  async function handleOnWarenkorbKonfigurationSubmit() {
    let sspExistsInBasket = false;
    // check if ssp article exists in basket
    basket?.positionen.forEach(p => { 
      if (p.konfiguration.artikelId === "SSP") {
        sspExistsInBasket = true;
        setSspExistsInBasket(true)
      }
    });
    if(sspExistsInBasket) {
      return;
    }

    let requests: Promise<WarenkorbPosition>[] = [];

      formModel.yearsToOrder.forEach(async year => {
        const basketConfigurationToAdd = createBasketConfiguration(year); 
          requests.push(warenkorbApi.upsertBasket(basketConfigurationToAdd, undefined));  
      });
      try {
       
        const wp = await Promise.all(requests);
        wp.forEach(p => {
        setWarenkorbPosition(p);
        });
        
        refetchWarenkorb();
        navigateToWarenkorbPage();
      } catch (error) {
        console.error(error);
      }

    }

  useEffect(() => {setIsLoading(isLoading)} , [isLoading, setIsLoading]);

  return !standsicherheitDto ? <Spinner /> : (
    <div>
      <Grid container spacing={3} className="mt-10">
        <Grid item xs={3} >
        <Box sx={{ maxWidth: 300 }}>
        <StepHeader
          
          title="Produktspezifikation"
          description={<span>
            Hier finden Sie die Übersicht Ihrer Standsicherheitsprüfungen und die Möglichkeit zur direkten Beauftragung des laufenden Jahres.
          </span>}
        />
        <InputDescription
          className="mb-10"
          title="Übersicht der fälligen Prüfungen"
          description="Die folgenden Sicherheitsprüfungen sind im System vermerkt"
        />
          </Box>
        </Grid>
        <Grid item xs={6} className="self-end">
          <TableContainer>
            <Table>
              <TableHead className="font-semibold">
                <TableRow key="table-row-head">
                  <TableCell  width="30%" key="table_cell_commune"></TableCell>
                  {standsicherheitDto.yearsToDisplay?.map(year => (
                    <TableCell  className="text-right" key={year+"_table_head"}>{year}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                  {standsicherheitDto.poles.map(pole => (
                    <TableRow>
                     <TableCell key={pole.poleType}>{mapPoleTypeToDisplayValue(pole.poleType)}</TableCell>
                      {pole.yearData.map(data => (
                      <TableCell className="text-right" key={pole.poleType + data.year}>{data.availableToOrder}</TableCell>
                        ))}
                    </TableRow>
                  ))
                }
                    <TableRow>
                    <TableCell>bereits beauftragt</TableCell>
                    {standsicherheitDto.yearsToDisplay?.map(year => (
                          <TableCell className="text-right" key={`bereits-bestellt ${year}`}>
                         { orderExistsForYear(year, standsicherheitDto) ? "ja" : "nein"}
                        </TableCell>
                      ))} 
                  </TableRow> 
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
        <Box sx={{ maxWidth: 300 }}>
          <OrderNotice className="h-full border-0 border-transparent">
            <div className="mt-2" >
              *Wegen Zeitversatz und aufgrund von Lieferzeiten und Datenpflege, können wir
              Ihnen an dieser Stelle leider keine tagesaktuellen Daten anzeigen
            </div>
          </OrderNotice>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <InputDescription
            className="mb-10"
            title="Beauftragung"
            description="Straßenbeleuchtungsmaste"
          />
          <br/>
          <br/>
          <br/>
        </Grid>
        <Grid item xs={6} className="self-end">
          <TableContainer>
            <Table>
              <TableHead className="font-semibold">
                <TableRow>
                  <TableCell width="30%">Jahr</TableCell>
                  {standsicherheitDto.yearsToDisplay?.map(year => (
                    <TableCell  className="text-right" key={year+"_table_head_order"}>{year}</TableCell>
                  ))}
                  
                </TableRow>
              </TableHead>
              <TableBody>
                    <TableRow>
                      <TableCell width="10%" > <Typography color={ colors.secondary.dark} > Ich möchte für folgendes Jahr beauftragen </Typography></TableCell>
                    {standsicherheitDto.yearsToDisplay?.map(year => (
                      <TableCell  className="text-right">
                        <Tooltip title={orderExistsForYear(year, standsicherheitDto) ? "Die Standsicherheitsprüfung für dieses Jahr wurde bereits beauftragt":""}>
                          <span>
                            
                          <Checkbox disabled={!orderForYearPossible(year, standsicherheitDto)} value={year} onChange={(e) => setYearToOrder(year)} checked={ formModel.yearsToOrder.includes(year)}  />
                          </span>
                        </Tooltip>
                      </TableCell>
                      ))}
                        
                    </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ maxWidth: 300 }}>
            <OrderNotice className="h-full border-0 border-transparent">
              <div className="mt-2">
                *Es kann nur eine Bestellung aller Masten innerhalb eines Jahres erfolgen, Teilbestellungen sind nicht möglich.
              </div>
              <div className="mt-2">
                *Standsicherheitsprüfungen können derzeit nur für einzelne Jahre beauftragt werden.
              </div>
            </OrderNotice>
          </Box>
        </Grid>

        <Grid item xs={3}>
        <InputDescription
            className="mb-10"
            title=""
            description="Sie erhalten im Anschluss noch die Möglichkeit, Ihre Angaben zu überprüfen und ggf. abzuändern."
          />
        </Grid>
        <Grid item xs={6} className="self-end">
        <TableContainer>
            <Table>
              <TableHead className="font-semibold">
                <TableRow key="summary-table-row-head">
                  <TableCell  width="30%" key="table_cell_pole_type">Masttyp</TableCell>
                  {
                     formModel.yearsToOrderData.length === 0 ? 
                     standsicherheitDto.poles.map(pole => (
                      <TableCell className="text-left" key={"summary-table-row-head"+pole.poleType}>{mapPoleTypeToDisplayValue(pole.poleType)}</TableCell>
                      ))
                     :
                      formModel.yearsToOrderData?.map(data => (
                      <TableCell className="text-left" key={"summary-table-row-head"+data.poleType}>{mapPoleTypeToDisplayValue(data.poleType)}</TableCell>
                      ))}
                </TableRow>
                <TableRow key="summary-table-row-check-count">
                  <TableCell  width="30%" key="summary-table-row-check-count-title">Summe zu prüfender Masten</TableCell>
                  {formModel.yearsToOrderData?.length > 0 ? 
                  formModel.yearsToOrderData?.map(data => (
                    <TableCell className="text-left" key={"summary-table-row-check-count-title"+ data.poleType + data.orderCount}>{data.orderCount}</TableCell>
                  )) :  <><TableCell className="text-left"></TableCell> <TableCell className="text-left"></TableCell> </>            
                }
                </TableRow>
                <TableRow key="summary-table-row-check-price">
                  <TableCell  width="30%" key="summary-table-row-check-price-title">Preis/Stück</TableCell>
                  {formModel.yearsToOrderData?.length > 0 ? 
                  formModel.yearsToOrderData?.map(data => (
                    <TableCell className="text-left" key={"summary-table-row-check-price-title"+data.poleType+data.netPrice}>{Utils.formatToEUR(data.netPrice)}</TableCell>
                  )) :  <><TableCell className="text-left"></TableCell> <TableCell className="text-left"></TableCell> </>}
                </TableRow>
                <TableRow key="summary-table-row-check-summary">
                  <TableCell  width="30%" key="summary-table-row-check-summary-title">Summe</TableCell>
                  {formModel.yearsToOrderData?.length > 0 ? 
                  formModel.yearsToOrderData?.map(data => (
                    <TableCell className="text-left" key={"summary-table-row-check-summary-"+data.poleType + data.netPrice}>{Utils.formatToEUR(data.netPrice * data.orderCount)} </TableCell>
                  )) :  <><TableCell className="text-left"></TableCell> <TableCell className="text-left"></TableCell> </>}
                </TableRow>
                <TableRow key="summary-table-row-check-note">
                  <TableCell  width="30%" key="summary-table-row-check-summary-note">Anmerkung</TableCell>
                  <TableCell className="text-left" key={"summary-table-row-check-note-"}>
                  <TextField 
                    multiline
                    maxRows={4}
                    value={comment}
                    inputProps={{ maxLength: 500 }} 
                    onChange={e => {
                      setComment( e.target.value)}
                    } 
                    variant="outlined" 
                    fullWidth 
                    placeholder={"Anmerkung"}
                  />
                  </TableCell>
                  <TableCell className="text-left" key={"summary-table-row-check-note-empty-cell"}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
        </Grid>

        <Grid item xs={3}>
        </Grid>

        <Grid item xs={6}>

        <Stack direction="row" spacing={2}>
          <Button 
                className="w-72 mr-3"
                variant="outlined" 
                size="large"
                color="secondary" 
                onClick={handleOnCancelClick}
              >
                Zurück
            </Button>
          <Button 
            className="w-72"
            variant="contained" 
            size="large"
            disabled={formModel.yearsToOrder.length === 0}
            color="secondary"
            onClick={handleOnWarenkorbKonfigurationSubmit}
            >
            Zum Warenkorb hinzufügen
          </Button>
        </Stack>

        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
      <SSPExistsDialog open={sspExistsInBasket} onClose={() => setSspExistsInBasket(false)} />
    </div>
  );
}
