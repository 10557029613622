import { useLocation, useNavigate } from "react-router-dom";
import { getPath } from "../components/App";
import { CartStep, useCartContext } from "../context/CartContext";

export function useAppNavigate() {
  const navigate = useNavigate();
  const { setActiveStep, deleteAll } = useCartContext();
  const { pathname } = useLocation();

  const appNavigate = (route: AppRoute) => navigate(route);

  return { 
    navigateToNewOrder: () => {
      deleteAll();
      if (pathname !== getPath("/einkaufswagen")) {
        appNavigate("/einkaufswagen");
      }
    },
    navigateToCartWarenkorb: () => {
      setActiveStep(CartStep.WARENKORB);
      appNavigate("/einkaufswagen");
    },
    navigateToWarenkorbPage: () => {
      appNavigate("/warenkorb");
    },
    navigateHome: () => {
      deleteAll();
      appNavigate("/");
    },
    navigateToBestelluebersicht: () => {
      setActiveStep(CartStep.BESTELLUEBERSICHT);
      appNavigate("/einkaufswagen");
    },
    navigateToAlleBestellungen: () => {
      appNavigate("/meine-bestellungen");
    },
    navigateToProduktSpecifikation: () => {
      setActiveStep(CartStep.PRODUKT_SPEZIFIKATION);
      appNavigate("/einkaufswagen");
    },
    navigateToStandsicherheit: () => {
      appNavigate("/standsicherheitspruefung");
    },
  }
}

export type AppRoute = "/" | "/einkaufswagen" | "/meine-bestellungen" | "/warenkorb" | "/standsicherheitspruefung";