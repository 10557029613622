import { IconProps } from "./index.types";

const getIconSizeByScaleFactor = (size: number, props: IconProps) => {
  if (props.fullwidth) return "100%";
  if (!props.scaleFactor) return size;
  return (size * props.scaleFactor).toString() + "px";
}

interface IconSize {
  width: number;
  height: number;
}
export const getScaledIconSize = (size: IconSize, props: IconProps) => ({
  width: getIconSizeByScaleFactor(size.width, props),
  height: getIconSizeByScaleFactor(size.height, props)
});