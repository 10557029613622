import { PreisKomponentenTable } from "./PreisKomponentenTable";
import { NonspecificBasket } from "../NonspecificBasket/NonspecificBasket";

export function BestelluebersichtTable() {

  return <NonspecificBasket 
    editable={false}
    expandable={true}
    getBasketPositionTableRowProps={(wp, i) => ({
      editable: false,
      index: i + 1,
      warenkorbPosition: wp,
      ColapsableComopnent: <PreisKomponentenTable preisKomponenten={wp.preisKomponenten} positionId={wp.id} />
    })}
  />;
}