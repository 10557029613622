import { Box, Button, darken, SxProps } from "@mui/material";
import { useAppContext } from "../../context";
import theme from "../../theme";
import { ContactViewType } from "./ContactView";
import PersonIcon from '@mui/icons-material/Person';

interface ContactLinkProps {
  type: ContactViewType
  withIcon?: boolean;
  label?: string;
  sx?: SxProps;
}

const contactLinkColor = {
  main: theme.palette.secondary.main,
  hover: darken(theme.palette.secondary.main, .3)
}

export function ContactLink(props: ContactLinkProps) {
  const { toggleContactView } = useAppContext();
  const label = props.label || "Ansprechperson";
  
  return props.withIcon 
    ? (
      <Button 
        onClick={() => toggleContactView(props.type)}
        color="secondary" 
        startIcon={props.withIcon ? <PersonIcon /> : undefined} 
        variant="text"
        sx={props.sx}
      >
        {label}
      </Button>
    ) 
    : (
      <Box 
        sx={{ 
          color: contactLinkColor.main, 
          ":hover": { color: contactLinkColor.hover, cursor: "pointer" } 
        }} 
        onClick={() => toggleContactView(props.type)}
        component="a"
      >
        {label}
      </Box>
    )
}