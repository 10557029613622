import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { StepHeader } from "../Stepper/StepHeader";
import { BestelluebersichtTable } from "./BestelluebersichtTable";
import DownloadIcon from '@mui/icons-material/Download';
import { agbLinkPath } from "../../routes/routes";
import { CartStep, useCartContext } from "../../context/CartContext";
import { useEffect } from "react";
import { LinkButton } from "../../parts/Link";
import { usePdfApi } from "../../dataQuery/file/pdfApi";
import { Utils } from "../../utils";

interface BestelluebersichtProps {
  onAGBCheckboxChange: (v: boolean) => void;
}

export const Bestelluebersicht = (props: BestelluebersichtProps) => {
  const { setActiveStep } = useCartContext();
  const pdfApi = usePdfApi();

  useEffect(() => {
    return () => {
      props.onAGBCheckboxChange(false);
    };
  // TODO: Fix eslint warning and remove disable
  // eslint-disable-next-line
  }, [])

  async function handleOnCreatePdf() {
    const pdf = await pdfApi.create();
    Utils.downloadFile(pdf);
  }

  return (
    <Box>
      <StepHeader
        title="Bestellübersicht"
        description={<span>
          Hier finden Sie eine Übersicht über Ihre zur Bestellung vorgesehenen Produkte und können die Bestellung nun abschließen.<br />
          Bei Bedarf können Sie noch weitere Produkte hinzufügen oder ggf. auch wieder entfernen - klicken Sie hierzu auf die Schaltfläche <LinkButton onClick={() => setActiveStep(CartStep.WARENKORB)}>{'< zurück'}</LinkButton>.<br/>
          Außerdem können Sie sich, z.B. für interne Genehmigungsprozesse, ein PDF erstellen.
        </span>}
      />
      <BestelluebersichtTable />
      <Box display={"flex"} flexDirection="row" justifyContent={"space-between"} marginY={2} alignItems="flex-end">
        <FormGroup>
          <FormControlLabel 
            control={<Checkbox onChange={(e) => props.onAGBCheckboxChange(e.target.checked)} />} 
            label={<span>
              Ich akzeptiere die <a className="wfk-link" target="_blank" href={agbLinkPath} rel="noreferrer">AGBs</a>*
            </span>} 
          />
        </FormGroup>
        <Button 
          onClick={handleOnCreatePdf}
          size="small" 
          variant="contained" 
          startIcon={<DownloadIcon />}
        >
          PDF erstellen
        </Button>
      </Box>
      <Typography>
        *Die Auftragshöhe orientiert sich anhand Ihrer Angaben, Kundenseitige Änderungswünsche und unverhältnismäßige Änderungen können zu einem Mengen und Massenveränderung und somit einer preislichen Anpassung führen.
        <br />
        *Der Ausführungszeitraum wird mit Ihnen nach der Beauftragung abgestimmt.
        <br />
        *Bei einem Mastversetzen wird vor Ort durch den Leistungserbringer geprüft, ob ein Masttausch notwendig ist. Dies wird bei der finalen Abrechnung berücksichtigt und bei keinem Bedarf vom Materialpreis abgezogen.
      </Typography>
    </Box>
  );
}