import { getScaledIconSize } from ".."
import { IconProps } from "../index.types"

export const CurrentCartIcon = (props: IconProps) => (
  <svg {...getScaledIconSize({ width: 54, height: 46 }, props)} viewBox="0 0 54 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.0336 0.120186C43.8191 0.0683165 45.5472 0.38026 47.16 1.056C49.2911 1.93995 51.1345 3.39591 52.2864 5.21609C53.2656 6.6721 53.7839 8.3881 53.8992 10.0519C54.0145 11.8198 53.5537 13.5877 52.6894 15.2C51.9983 16.4481 51.0192 17.5922 49.8669 18.4761C48.6571 19.4119 47.2171 20.1403 45.7197 20.5041C44.0495 20.972 42.2635 21.0761 40.5359 20.816C37.7134 20.4 35.064 18.9959 33.2784 16.916C32.1839 15.616 31.3776 14.1082 31.032 12.4959C30.6286 10.7279 30.7439 8.90776 31.3775 7.24395C32.3567 4.59204 34.5456 2.30404 37.3677 1.10813C38.8655 0.535749 40.4783 0.171944 42.0336 0.120037L42.0336 0.120186ZM41.6881 4.74804C41.4 4.90401 41.2273 5.16409 41.2273 5.47602V10.0521H36.6192C36.3311 10.0521 35.9855 10.2081 35.8702 10.4163C35.6975 10.6764 35.6975 11.0402 35.8702 11.3002C36.043 11.5603 36.3311 11.7163 36.6766 11.7163H41.2847V15.8764C41.2847 16.1883 41.4575 16.4484 41.7456 16.6044C42.0336 16.7603 42.4945 16.7603 42.7822 16.5525C43.0124 16.3965 43.1856 16.1365 43.1856 15.8764V11.7163H48.2545C48.5425 11.7163 48.8881 11.5603 49.0608 11.3002C49.2336 11.0402 49.2336 10.6764 49.0608 10.4163C48.8881 10.1559 48.6 9.99988 48.2545 9.99988H43.1856V5.42379C43.1856 5.16372 43.0702 4.90364 42.7822 4.69581C42.3792 4.59207 41.9762 4.59207 41.6881 4.74804L41.6881 4.74804Z" fill="white"/>
    <path d="M0.331103 3.96791C0.561328 3.60373 1.02218 3.39589 1.54091 3.39589H8.3375C8.79835 3.39589 9.31665 3.65596 9.54731 4.01978C9.66262 4.17575 9.72008 4.38395 9.72008 4.5918C10.0656 6.15189 10.4112 7.71198 10.7567 9.27161H28.1519C27.9216 11.1955 28.2093 13.1197 28.9008 14.9395C29.9953 17.7473 32.2416 20.2436 35.1216 21.7514C37.5408 23.0514 40.4208 23.6753 43.2433 23.5193C44.6833 23.4152 46.1808 23.1551 47.5058 22.6872C47.4483 23.1552 47.4483 23.6231 47.333 24.0913C47.2177 24.8193 46.9296 25.4954 46.4692 26.1193C46.0083 26.7954 45.2594 27.3152 44.453 27.6794C43.6466 27.9914 42.7828 28.1473 41.9185 28.0955H15.0769C15.6531 29.2395 16.4594 30.3313 17.7267 30.9033C18.245 31.1634 18.8786 31.3193 19.5122 31.3712C19.8577 31.4231 20.2611 31.4231 20.6067 31.4231H44.9139C45.6628 31.4231 46.2964 31.9951 46.2386 32.6712C46.2386 33.3473 45.605 33.9193 44.9139 33.9193H20.6643C19.8579 33.9193 18.9941 33.9193 18.1877 33.7114C16.6324 33.3995 15.2502 32.5674 14.2711 31.4756C13.1766 30.3316 12.4277 28.8756 12.0247 27.4196C10.4119 20.2957 8.79921 13.1196 7.2439 5.99561H1.59921C1.13836 5.99561 0.735373 5.78777 0.447311 5.47546C0.0435147 5.00754 0.0435148 4.38365 0.331196 3.96765L0.331103 3.96791Z" fill="white"/>
    <path d="M20.0518 36.0132C21.4918 35.9091 22.9893 36.4292 24.0263 37.3132C25.0055 38.1452 25.5816 39.2371 25.6965 40.4334C25.8118 41.5255 25.4663 42.6692 24.8327 43.5535C24.0838 44.5934 22.8165 45.3737 21.4344 45.6334C20.2825 45.8412 19.0152 45.6852 17.9208 45.2173C16.4808 44.5412 15.3863 43.2412 15.0983 41.7852C14.8102 40.4333 15.1558 38.9774 16.1349 37.8852C17.0566 36.793 18.5541 36.065 20.0519 36.0132L20.0518 36.0132Z" fill="white"/>
    <path d="M40.7088 36.156C42.2641 36.0519 43.819 36.6239 44.856 37.612C45.5471 38.2359 46.0079 39.0161 46.2385 39.8478C46.6419 41.3556 46.2385 43.0198 45.0866 44.1638C43.9921 45.3078 42.3219 45.984 40.6513 45.828C38.9811 45.7239 37.3679 44.8922 36.5041 43.5922C35.5824 42.3441 35.4096 40.7321 35.928 39.328C36.4463 38.0799 37.5408 36.9881 38.8655 36.4679C39.4416 36.3119 40.0753 36.156 40.7089 36.156L40.7088 36.156Z" fill="white"/>
  </svg>
);

