import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Link, Typography } from "@mui/material";
import { useAppNavigate } from "../../hooks/appNavigation";

export function SSPExistsDialog({ onClose, ...props }: DialogProps) {
  
  const { navigateToWarenkorbPage } = useAppNavigate();

  function handleOnClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    onClose?.(event, reason);
  }

  return (
    <Dialog onClose={handleOnClose} {...props}>
      <DialogTitle>Standsicherheitsprüfung existiert bereits im Warenkorb! </DialogTitle>
      <DialogContent>
        <Typography>
          Dieses Produkt befindet sich bereits in Ihrem 
          <Link
            variant="body2"
            onClick={() => {
              navigateToWarenkorbPage();
            }}
          >
            Warenkorb
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClose({}, "backdropClick")}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}