import { Card, Typography, CardContent, Grid } from '@mui/material';
import { Box } from '@mui/system';
import theme from "../../theme";
import MainBox from '../../layout/MainBox';
import { ContactLink } from '../ContactView/ContactLink';
import { useAppContext } from '../../context';
import { useAppNavigate } from '../../hooks/appNavigation';
import { ListIcon, PlusIcon, ArchiveIcon } from '../../assets/icons';

export function Home() {
  const { user } = useAppContext();
  const { 
    navigateToNewOrder, 
    navigateToWarenkorbPage,
    navigateToAlleBestellungen
  } = useAppNavigate();

  return (
    <MainBox title='Einstieg'>
      <span className='text-center'>
        <Typography variant='h2' fontWeight={500} color={"black"}>
          {`${user?.firstname} ${user?.surname},`}<br/>
          Herzlich Willkommen zum Bestellkorb Öffentliche Beleuchtung.
        </Typography> 
        <br/>
        <Typography>
          Hier können Sie Austauschen und Versetzen von Lichtmasten bestellen und bei uns im Auftrag geben
        </Typography>
        <Box className="flex justify-center">
          <Grid className="my-10 w-96 md:w-auto" container spacing={3}>
            <Grid item xs={12} md={4}>
              <HomeNavigationButton 
                Icon={<ListIcon className="h-4"/>} 
                label="Zum Warenkorb" 
                onClick={navigateToWarenkorbPage}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <HomeNavigationButton 
                Icon={<PlusIcon className="h-5"/>} 
                label="Neue Bestellung" 
                onClick={navigateToNewOrder}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <HomeNavigationButton 
                Icon={<ArchiveIcon  className="h-4"/>} 
                label="Meine Bestellungen" 
                onClick={navigateToAlleBestellungen}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography>
          Wenn Sie Unterstützung bei der Bestellung benötigen,<br/>
          wenden Sie sich bitte an Ihre Netze BW <ContactLink type='anprech-person'/>
        </Typography>
      </span>
    </MainBox>
  );
}

export interface HomeNavigationButtonProps extends NavigationButtonProps {
  className?: string;
  label: string;
}

export function HomeNavigationButton({label, className, ...ButtonProps}: HomeNavigationButtonProps) {
  return (
    <Card 
      className={`items-center flex p-2 w-full md:w-72 hover:bg-gray-200 cursor-pointer ${className}`}
      sx={{ border:`solid ${theme.palette.secondary.main} 2px` }}
      onClick={ButtonProps.onClick} 
      elevation={0}
    >
      <NavigationButton {...ButtonProps}/>
      <CardContent sx={{whiteSpace: "nowrap" ,marginLeft: 1, color: "black", fontWeight: "bold" }}>{label}</CardContent>
    </Card>
  );
}

export interface NavigationButtonProps {
  Icon: JSX.Element;
  onClick?: () => void;
  active?: boolean;
  size?: number;
}
export const NavigationButton = (props: NavigationButtonProps) => (
  <Box
    className={`flex items-center justify-center rounded-full ${!props.size ? "w-12 h-12": ""}`}
    bgcolor={props.active ? theme.palette.secondary.main : theme.palette.primary.main} 
    width={props.size}
    height={props.size}
    onClick={props.onClick}
  >
    {props.Icon}
  </Box>
)
