import { Button, Dialog, DialogActions, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LightPoint } from "../../models";
import { LightPointAddress } from "../../parts/LightPointAddress";

interface LightPointSelectDialogProps {
  onClose: () => void;
  lightPoints: LightPoint[];
  onSelect: (l: LightPoint) => void;
}

export const LightPointSelectDialog = (props: LightPointSelectDialogProps) => (
  <Dialog onClose={props.onClose} open={props.lightPoints.length > 0}>
    <DialogTitle>Bitte wählen Sie die gewünschte Leuchtstelle aus</DialogTitle>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nummer</TableCell>
          <TableCell>Adresse</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.lightPoints.map((lightpoint) =>
          <TableRow
            key={lightpoint.nummer}
            className="cursor-pointer"
            hover 
            role="button" 
            onClick={() => props.onSelect(lightpoint)}
          >
            <TableCell>
              {lightpoint.nummer}
            </TableCell>
            <TableCell>
              <LightPointAddress lightPointInfo={{
                Hausnummer: lightpoint.hausnummer,
                Strasse: lightpoint.strasse,
                Stadt: lightpoint.stadt
              }}/>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
    <DialogActions className="mt-4">
      <Button onClick={props.onClose} color="inherit" variant="contained">Schließen</Button>
    </DialogActions>
  </Dialog>
);