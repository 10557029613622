import { useEffect } from "react";
import { useQuery } from "react-query";
import { STATIC_DATA_STALE_TIME } from "..";
import { ArtikelDetails, ArtikelId } from "../../models";
import { useApiClient } from "../apiClient/apiClient";
import { useApiItemPath } from "../apiClient/apiItem";

export function useArtikelDetails(id?: ArtikelId) {
  const apiClient = useApiClient();
  const { artikel } = useApiItemPath();

  const { isError, error, ...query} = useQuery(
    ["artikel-detail", id], 
    () => apiClient.get<ArtikelDetails>({url: artikel.details(id!)}),
    { refetchOnWindowFocus: true, staleTime: 0, enabled: !!id, cacheTime:0 }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      throw new Error();
    }
  }, [isError, error])
  
  return query;
}