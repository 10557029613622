import { Box, Divider, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { When } from "react-if";
import { useCartOverviewContext } from "../../context/CartOverviewContext/CartOverviewContext";
import { useWarenkorb } from "../../dataQuery/warenkorb/useWarenkorb";
import { WarenkorbPosition } from "../../models/Warenkorb";
import { Utils } from "../../utils";
import { LightpointBasketPositionTableRow, LightpointBasketPositionTableRowProps } from "./LightpointBasket/LightpointBasketPositionTableRow";
import { LightpointBasketTableHead } from "./LightpointBasket/LightpointBasketTableHead";
import { SSPBasketTableHead } from "./SSPBasket/SSPpointBasketTableHead";
import { SSPBasketPositionTableRow } from "./SSPBasket/SSPBasketPositionTableRow";

interface NonspecificBasketProps { 
  editable: boolean;
  expandable: boolean;
  getBasketPositionTableRowProps: (wp: WarenkorbPosition, index: number) => LightpointBasketPositionTableRowProps;
}

export function NonspecificBasket(props: NonspecificBasketProps) {
  const { sortedLightpointBasketPositionen, lightpointBasketTableHeadPosition, sortedSSPBasketPositions } = useCartOverviewContext();
  const { data: warenkorb } = useWarenkorb();
  const tableRef = useRef<HTMLTableElement>(null);
  const priceCellRef = useRef<HTMLTableCellElement>(null);

  const mwstr = !warenkorb ? undefined : warenkorb.bruttoPreis - warenkorb.nettoPreis;

  const sortedLightpointBasketPositionenCount = sortedLightpointBasketPositionen?.length ? sortedLightpointBasketPositionen.length : 0;

  return !lightpointBasketTableHeadPosition ? null : (
    <div>
      {sortedLightpointBasketPositionen?.length === 0 ? null :
          <TableContainer ref={tableRef} id="lightpoint-basket-table">      
            <Box marginTop={5}>
              <Typography variant="h3">
              Masttausch / Mastversetzen
              </Typography>
            </Box>
            <Table>
              <LightpointBasketTableHead ref={priceCellRef} editable={props.editable} warenkorbPosition={lightpointBasketTableHeadPosition}/>
              <TableBody>
                {sortedLightpointBasketPositionen?.map((wp, i) => <LightpointBasketPositionTableRow  {...props.getBasketPositionTableRowProps(wp, i)} key={wp.id}/>)}
              </TableBody>
            </Table>
          </TableContainer>
      }

      {sortedSSPBasketPositions?.length === 0 ? null :
      <TableContainer  ref={tableRef} id="ssp-basket-table">
         <Box marginTop={5}>
              <Typography variant="h3">
              Standsicherheit
              </Typography>
            </Box>
        <Table >
          <SSPBasketTableHead ref={priceCellRef} editable={props.editable} warenkorbPosition={sortedSSPBasketPositions ? sortedSSPBasketPositions[0] : undefined }/>
          <TableBody>
            {sortedSSPBasketPositions?.map((wp, i) => <SSPBasketPositionTableRow expandable={props.expandable}  warenkorbPosition={wp} index={sortedLightpointBasketPositionenCount + i + 1} editable={props.editable}    key={wp.id}/>)}
          </TableBody>
        </Table>
      </TableContainer>
      }



      <TableTotal 
        priceCellRef={priceCellRef} 
        tableRef={tableRef} 
        totalPrices={[
          { label: "Summe netto", value: warenkorb?.nettoPreis },
          { label: "Umsatzsteuer 19%", value: mwstr },
          { label: "Gesamtsumme", value: warenkorb?.bruttoPreis, total: true },
        ]}
      />
    </div>
  );
}

interface TableTotalProps {
  tableRef: React.RefObject<HTMLTableElement>;
  priceCellRef: React.RefObject<HTMLTableCellElement>;
  totalPrices: Array<{
    value?: number;
    label: string;
    total?: boolean;
  }>;
};

function TableTotal({ priceCellRef, tableRef, totalPrices }: TableTotalProps) {
  const [width, setWidth] = useState<number|undefined>();

  const handleWidth = useCallback((table: HTMLTableElement, priceCell: HTMLTableCellElement) => {
    const { relativePriceColPositionRight, tableWidth } = getPriceCollAndTableDimension(table, priceCell);
    if (isPriceColWithinTableView(relativePriceColPositionRight, tableWidth)) {
      setWidth(relativePriceColPositionRight);
    } else {
      setWidth(undefined);
    }
  }, []);

  useEffect(() => {
    const table = tableRef.current;
    const px = priceCellRef.current;
    if (!table || !px) return;
    handleWidth(table, px);
  }, [handleWidth, priceCellRef, tableRef]);

  useEffect(() => {
    const table = tableRef.current;
    const px = priceCellRef.current;
    if (!table || !px) return;
    const clbk = () => handleWidth(table, px);
    table.addEventListener("scroll", clbk);
    window.addEventListener("resize", clbk);
    return () => { 
      table.removeEventListener("scroll", clbk);
      window.removeEventListener("resize", clbk);
    }
  }, [handleWidth, priceCellRef, tableRef]);

  const valueMinWidth = (priceCellRef.current?.getBoundingClientRect().width || 0);

  return (
    <div className="w-full">
      {totalPrices.map((tp, i) => !tp.value ? null : <div key={tp.label + i.toString()} className={`${!tp.total ? "" : "bg-gray-200"}`}>
          <div className={`flex flex-row-reverse relative text-right`} style={{ width }}>
            <Typography minWidth={valueMinWidth} className={`p-4 ${tp.total ? "font-bold underline" : ""}`}>{Utils.formatToEUR(tp.value)}</Typography>
            <Typography className={`p-4 ${tp.total ? "font-bold" : ""}`}>{tp.label}</Typography>
          </div>
          <When condition={!tp.total}><Divider/></When>
      </div>)}
    </div>
  );
}

const getPriceCollAndTableDimension = (table: HTMLTableElement, priceCell: HTMLTableCellElement) => {
  const absPriceColPositionRight = priceCell.getBoundingClientRect().right;
  const tableWidth = table.getBoundingClientRect().width;
  const absTablePositionLeft = table.getBoundingClientRect().left;
  const relativePriceColPositionRight = absPriceColPositionRight - absTablePositionLeft;
  return ({
    relativePriceColPositionRight,
    tableWidth,
  });
}

const isPriceColWithinTableView = (relativePriceColPositionRight: number, tableWidth: number) => {
  return relativePriceColPositionRight < tableWidth;
}