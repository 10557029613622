import Keycloak, { KeycloakInstance } from "keycloak-js";

export const authClient = Keycloak({
  url: "https://login.idm.enbw.com/auth/",
  realm: "appid-0892",
  clientId: "StB-Warenkorb",
});

export const getGroups = (token?: { [prop: string]: any }): string[] => {
  return (
    (token &&
      "groups" in token &&
      (token["groups"] as any) instanceof Array &&
      (token["groups"] as Array<any>).every((value) => typeof value === "string") &&
      token["groups"]) ||
    []
  );
};

/**
 * Checks if user has only reading access to FaultReports.
 * @returns `true` if groups claim includes FGM-ADMIN_READER OR user is NOT authenticated. Otherwise, returns `false`
 */
 export const checkIfReaderOnly = (keycloak: KeycloakInstance): boolean => {
  const { authenticated, tokenParsed } = keycloak;

  if (!authenticated) return true; // if not authenticated - this will not be used.

  const groups = getGroups(tokenParsed);
  return groups.includes("FGM-ADMIN_READER");
}

export const hasGroupsInScope = (token?: { [prop: string]: any }): boolean => {
  return Boolean(
    token && "scope" in token && typeof token["scope"] === "string" && (token["scope"] as string).includes("groups")
  );
}