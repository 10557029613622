import React, { ReactNode, useRef, useEffect, useState } from "react";
import { 
  TextField, 
  CircularProgress, 
  Autocomplete, 
  Alert,
  Collapse,
  IconButton,
  AlertColor,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';
import { Box } from "@mui/system";
import { useGeoMap } from "../../context";
import { useLightPointsApi } from "../../dataQuery/lightPoints";
import { fitMapBounds } from "../GeoMap";
import { LightPoint } from "../../models";
import { LightPointSelectDialog } from "./LightPointSelectDialog";
import { Spinner } from "../Spinner";
import { Utils } from "../../utils";

interface GeoSearchBarProps {
  topComponent?: ReactNode;
  layout?: "row" | "column";
}

export const GeoSearchBar = ({layout="column" ,...props}: GeoSearchBarProps) => {

  // HOOKS ---------------------------------------------------------------
  const { map, selectedMarker, locality, blockOnMoveMapEffects } = useGeoMap();
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [lightingNo, setLightingNo] = useState("");
  const [showNoLightPointsToast, setShowNoLightPointsToast] = useState(false);
  const { getLightPointsByQuery } = useLightPointsApi();
  const [lightPointsToSelect, setLightPointsToSelect] = useState<LightPoint[]>([]);

  const autocompleteService = useRef(new google.maps.places.AutocompleteService());

  useEffect(() => {
    setLightingNo(selectedMarker?.nummer || "");
    setAddress(getLightPointsAddress(selectedMarker));
  }, [selectedMarker]);

  useEffect(() => {
    if (!address) return setSearchOptions([]);
    const addressRestrictedToLocality = locality ? locality + ", " + address : undefined;
    const input = addressRestrictedToLocality ?? address;
    const request: google.maps.places.AutocompletionRequest = {
      input,
      componentRestrictions: { country: "de" },
      types: ["address"]
    };

    autocompleteService.current.getPlacePredictions(request, (results) => {
      setSearchOptions(results?.map((prediction) => prediction.description) || []);
    });
  }, [address, locality]);
  // HOOKS END -----------------------------------------------------------

  if (!map) return <Spinner />;

  const handleOnSelectLightPoint = (lightPoint: LightPoint) => {
    setLightPointsToSelect([]);
    fitMapBounds({ map, lightPoints: [ lightPoint ] });
  }

  async function getLightPointsByNumber() {
    if (!lightingNo) return [] as LightPoint[];
    const formatedLightingNo = lightingNo.padStart(5, "0");
    setLightingNo(formatedLightingNo);
    const lightPoints = await getLightPointsByQuery({ nummer: formatedLightingNo });
    if (!lightPoints?.length) {
      setShowNoLightPointsToast(true);
      return null;
    } else if (lightPoints.length > 1) {
      setLightPointsToSelect(lightPoints);
      return null;
    }
    blockOnMoveMapEffects(true);
    return lightPoints;
  }

  // TODO: ErrorBoundry for not critical errors
  async function handleSearchClick(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setShowNoLightPointsToast(false);
    setIsLoading(true);
    const geocoderResult = lightingNo ? undefined : await geocodeAddress(address);
    const addressViewport = getCustomStreetViewport(geocoderResult);
    const lightPoints = await getLightPointsByNumber();
    setIsLoading(false);
    if (lightPoints === null) return;
    fitMapBounds({ map, addressViewport, lightPoints });
  };

  return (
    <>
      <form onSubmit={e => handleSearchClick(e)} className="w-full max-w-5xl">
        {props.topComponent}
        <Box className="flex justify-between gap-5" flexDirection={layout}>
          <Autocomplete
            freeSolo
            renderInput={(params) => <TextField {...params} label="Adresse" placeholder="Straßenname ggf. Hausnr." />}
            inputValue={address}
            options={searchOptions}
            onInputChange={(_, v) => setAddress(v)}
            onChange={(_, value, reason) => reason === "selectOption" ? setAddress(value || "") : ""}
            fullWidth
            id={"searchbar-address-autocomplete"}
          />
          <TextField
            fullWidth
            label="Leuchtstelle"
            value={lightingNo}
            onChange={(e) => setLightingNo(e.target.value)}
            placeholder="Leuchtstelle suchen"
          />
          <ClosableToast
            onClose={() => setShowNoLightPointsToast(false)}
            open={showNoLightPointsToast}
            text="Keine Leuchtstellen gefunden"
            variant="error"
          />
          <Button fullWidth={layout !== "row"} variant="contained" type="submit" size="large">
            {isLoading ? <CircularProgress color="secondary" size="26px"/> : "Suche starten"}
          </Button>
        </Box>
      </form>
      <LightPointSelectDialog
        onClose={() => setLightPointsToSelect([])}
        lightPoints={lightPointsToSelect} 
        onSelect={handleOnSelectLightPoint} 
      />
    </>
  );
};

const getLightPointsAddress = (lightPoint?: LightPoint): string => (
  !lightPoint ? "" :
    Utils.formatLightPointInfoAddress({ 
      Hausnummer: lightPoint.hausnummer, 
      Strasse: lightPoint.strasse, 
      Stadt: lightPoint.stadt
    }).address
);

function getCustomStreetViewport(geocoderResult?: google.maps.GeocoderResult) {
  if (geocoderResult === undefined) return;
  const location = geocoderResult.geometry.location;
  const distanceAwayFromLocation = 0.0009;
  const nordWest: google.maps.LatLngLiteral = {
    lat: location.lat() + distanceAwayFromLocation,
    lng: location.lng() - distanceAwayFromLocation,
  };
  const southEast: google.maps.LatLngLiteral = {
    lat: location.lat() - distanceAwayFromLocation,
    lng: location.lng() + distanceAwayFromLocation,
  };
  const customAddressViewport = new google.maps.LatLngBounds().extend(nordWest).extend(southEast);
  return customAddressViewport;
}

interface ClosableToastProps {
  open: boolean;
  variant: AlertColor;
  onClose: () => void;
  text: string;
}

const ClosableToast = (props: ClosableToastProps) => (
  <Collapse in={props.open}>
    <Alert
      severity={props.variant}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={props.onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {props.text}
    </Alert>
  </Collapse>
);

const geocodeAddress = async (address: string): Promise<google.maps.GeocoderResult | undefined> => {
  if (!address) return;
  try {
    const geocoder = new google.maps.Geocoder();
    const geocoderResponce = await geocoder.geocode({ address });
    const geocoderResult = geocoderResponce.results.shift();
    if (!geocoderResult) {
      console.error("No geographical information could be retrieved for the given address", address);
      return;
    }
    return geocoderResult;
  } catch (err) {
    console.error(err);
  }
};
