import { Box, Typography } from "@mui/material";
import { Component, PropsWithChildren } from "react";

interface State {
  hasError: boolean;
  errorMsg?: string;
}

export class ErrorBoundary extends Component<PropsWithChildren, State> {
  constructor(props: {}) {
    super(props);
    this.state = { 
      hasError: false,
      errorMsg: "",
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return { 
      hasError: true,
      errorMsg: error?.message
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box textAlign={"center"}>
          <Typography variant={"h3"} color="red" marginTop={"20%"} marginBottom={2}>Fehler</Typography>
          <Typography variant={"h6"} marginBottom={1}>
            Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.
          </Typography>
          {!this.state.errorMsg ? null :
            <Typography variant="inherit">"{this.state.errorMsg}"</Typography>
          }
        </Box>
      );
    }

    return this.props.children; 
  }
}