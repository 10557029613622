import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { useAppContext } from '../../context';
import { useArtikel } from '../../dataQuery/artikel/useArtikel';
import { useGemeinde } from '../../dataQuery/gemeinde/useGemeinde';
import { useLightPointsByKommune } from '../../dataQuery/lightPoints';
import { AppRoute } from '../../hooks/appNavigation';
import { Footer } from '../../layout/Footer';
import { Sidebar } from '../../layout/Sidebar';
import { AlleBestellungen } from '../AlleBestellungen/AlleBestellungen';
import { Cart } from '../Cart';
import ContactView from '../ContactView/ContactView';
import { Home } from '../Home';
import { Spinner } from '../Spinner';
import { LoadingDialog } from '../Spinner/LoadingDialog';
import { Warenkorb } from '../Warenkorb';
import './App.css';
import { Standsicherheit } from '../Produktspezifikation/Standsicherheit/Standsicherheit';

export function App() {
  const { 
    contactViewConfig, 
    toggleContactView, 
    customerScope,
    isLoading
  } = useAppContext();

  useGemeinde();
  useArtikel();
  useLightPointsByKommune();

  if (!customerScope) {
    return <Spinner />;
  }

  return (
    <Box className="App--root">
      <Box className="App--content--container pt-8 px-10">
        <Routes>
          <Route path={getPath("/")} element={<Home />}/>
          <Route path={getPath('/einkaufswagen')} element={<Cart/>}/>
          <Route path={getPath("/warenkorb")} element={<Warenkorb />} />
          <Route path={getPath("/meine-bestellungen")} element={<AlleBestellungen />}/>
          <Route path={"/standsicherheitspruefung"} element={<Standsicherheit />} />
        </Routes>
        <Footer />
      </Box>
      <ContactView open={contactViewConfig.open} type={contactViewConfig.type} onClose={() => toggleContactView(contactViewConfig.type)} />
      <LoadingDialog open={isLoading} />
      <Sidebar />
    </Box>
  );
}

export const getPath = (r: AppRoute) => r;

export default App;
