import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface EditMenuProps {
  onEdit: () => void;
  onDelete: () => void;
  isEditable: boolean;
}

export default function EditMenu(props: EditMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleOnEdit() {
    props.onEdit();
    handleClose();
  }

  function handleOnDelete() {
    props.onDelete();
    handleClose();
  }

  return (
    <div>
      <IconButton onClick={handleClick}><MoreVertIcon /></IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!props.isEditable ? null : <MenuItem onClick={handleOnEdit}>bearbeiten</MenuItem>}
      
        <MenuItem onClick={handleOnDelete}>löschen</MenuItem>
      </Menu>
    </div>
  );
}
