import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

export const FeedbackButton = () => {
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  return <>
    <Button
      color="warning"
      variant="contained" 
      startIcon={<FeedbackIcon />} 
      onClick={() => setOpenFeedbackDialog(true)}
    >
      Feedback
    </Button>
    <Dialog open={openFeedbackDialog} onClose={() => setOpenFeedbackDialog(false)} maxWidth="lg">
      <DialogActions>
        <IconButton
          size='small'
          edge="start"
          color="inherit"
          onClick={() => setOpenFeedbackDialog(false)}
          aria-label="close"
        >
          <CloseIcon fontSize='small'/>
        </IconButton>
      </DialogActions>
      <DialogTitle variant='h4'>
        Haben Sie Feedback?
      </DialogTitle>
      <DialogContent>
        <img src={"feedbackGiff.gif"} alt={"...lädt"}/>
      </DialogContent>
    </Dialog>
  </>;
}