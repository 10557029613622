
import { getScaledIconSize } from ".."
import { IconProps } from "../index.types"

export const SSPVector = (props: IconProps) => (
  <svg {...getScaledIconSize({width:83, height:83}, props)} viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.9382 57.8474H35.5634V59.7599H26.9382V57.8474Z" fill="#EE7700"/>
  <path d="M29.3085 40.4578H33.206V57.1906H29.3085V40.4578Z" fill="#EE7700"/>
  <path d="M50.8884 6.64H45.4305C37.0045 6.64 30.1584 13.4856 30.1584 21.912V39.7997H32.3496V21.912C32.3496 14.6944 38.2194 8.8312 45.4305 8.8312H45.7625C45.7361 8.93727 45.7162 9.05029 45.7162 9.1633C45.7162 10.5709 47.9871 11.6797 50.8819 11.6797C53.7837 11.6797 56.0476 10.5774 56.0476 9.1633C56.061 7.74234 53.7901 6.63998 50.8883 6.63998L50.8884 6.64ZM50.8884 11.2351C49.1886 11.2351 47.668 10.8169 46.8315 10.1995C47.7476 9.25646 49.8328 8.63255 52.15 8.63255C53.4381 8.63255 54.6201 8.8053 55.6159 9.15732C55.6228 10.2851 53.458 11.235 50.8883 11.235L50.8884 11.2351Z" fill="#EE7700"/>
  <path d="M39.2198 34.3809L38.2651 32.9487L39.2199 31.5166C39.3393 31.3376 39.3393 31.0392 39.2199 30.8601L38.2651 29.3683L39.2199 27.9361C39.3989 27.6377 39.3393 27.2797 39.0409 27.1007C38.7426 26.9217 38.3845 26.9813 38.2055 27.2797L37.012 29.0699C36.8926 29.2489 36.8926 29.5473 37.012 29.7263L38.0264 31.1585L37.0715 32.5907C36.9522 32.7696 36.9522 33.068 37.0715 33.2471L38.0264 34.6793L37.0715 36.1114C36.9522 36.2904 36.9522 36.5888 37.0715 36.7678L38.265 38.5581C38.444 38.8564 38.8021 38.9161 39.1004 38.7371C39.3988 38.5581 39.4585 38.2 39.2794 37.9016L38.2651 36.5292L39.2199 35.097C39.3989 34.8583 39.3989 34.6196 39.2198 34.3809L39.2198 34.3809Z" fill="#EE7700"/>
  <path d="M23.0828 31.4569L24.0375 32.8891L23.0827 34.3212C22.9633 34.5002 22.9633 34.7986 23.0827 34.9777L24.0375 36.4695L23.0827 37.9017C22.9037 38.2001 22.9633 38.5581 23.2617 38.7371C23.5601 38.9161 23.9181 38.8565 24.0971 38.5581L25.2906 36.7679C25.41 36.5889 25.41 36.2905 25.2906 36.1115L24.2763 34.6793L25.2311 33.2471C25.3504 33.0681 25.3504 32.7698 25.2311 32.5907L24.2763 31.1585L25.2311 29.7264C25.3504 29.5474 25.3504 29.249 25.2311 29.0699L24.0376 27.2797C23.8586 26.9814 23.5005 26.9217 23.2022 27.1007C22.9038 27.2797 22.8441 27.6378 23.0232 27.9362L24.0375 29.3086L23.0827 30.7408C22.9037 30.9794 22.9037 31.2182 23.0828 31.4569L23.0828 31.4569Z" fill="#EE7700"/>
  </svg>
);


