import { getScaledIconSize } from "..";
import { IconProps } from "../index.types"

export const DoubleLightPointVector = (props: IconProps) => (
<svg {...getScaledIconSize({width:51, height:54}, props)} viewBox="0 0 51 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M40.4993 2.73813C40.4993 3.01047 40.6253 3.27587 40.8513 3.51486C41.854 2.44587 44.0318 1.76866 46.5284 1.76866C47.7433 1.76866 48.8789 1.93447 49.8683 2.23369C49.3505 1.33745 47.4446 0.639969 45.2602 0.639969H45.2005C42.6442 0.659885 40.4994 1.60939 40.4994 2.73809L40.4993 2.73813Z" fill="#EE7700"/>
  <path d="M46.5286 2.20692C44.1915 2.20692 42.1063 2.84424 41.1902 3.80064C42.0336 4.41155 43.5607 4.82332 45.2605 4.82332C47.8367 4.82332 50.0015 3.87382 50.0214 2.74512C49.0121 2.3931 47.8167 2.20691 46.5286 2.20691V2.20692Z" fill="#EE7700"/>
  <path d="M9.80355 3.80064C8.89389 2.83771 6.80868 2.20692 4.47152 2.20692C3.18348 2.20692 1.98804 2.39265 0.97876 2.74467C0.985244 3.87341 3.16356 4.82287 5.73964 4.82287C7.43948 4.82333 8.96656 4.41157 9.80344 3.80065L9.80355 3.80064Z" fill="#EE7700"/>
  <path d="M10.1344 3.51489C10.3669 3.2759 10.4928 3.01004 10.4928 2.73815C10.4928 1.60941 8.34137 0.65995 5.78521 0.64003H5.73194C3.54074 0.64003 1.6417 1.3371 1.1239 2.23375C2.0998 1.93501 3.24894 1.76872 4.46382 1.76872C6.96082 1.76872 9.13862 2.44587 10.1344 3.51493L10.1344 3.51489Z" fill="#EE7700"/>
  <path d="M10.7 1.96148H11.2313C18.7411 1.96148 24.8361 8.07028 24.8361 15.5664V33.6864H26.1576V15.5664C26.1576 8.06992 32.2664 1.96148 39.7625 1.96148H40.2938C40.6458 1.41678 41.3493 0.958719 42.2923 0.639999H39.7625C33.4675 0.639999 27.8105 4.62424 25.7058 10.5536C25.6725 10.6398 25.5928 10.7065 25.4932 10.7065C25.3936 10.7065 25.314 10.6467 25.2806 10.5536C23.176 4.62412 17.5188 0.639999 11.2311 0.639999H8.70776C9.64429 0.95866 10.3548 1.41674 10.6999 1.96148H10.7Z" fill="#EE7700"/>
  <path d="M26.3833 34.1258H26.3769H26.3704H24.6242H24.6177H24.6112H23.4292V51.1834H27.5727L27.5717 34.1258H26.3833Z" fill="#EE7700"/>
  <path d="M21.0453 51.6281H29.9428V53.7596H21.0453V51.6281Z" fill="#EE7700"/>
</svg>
);
