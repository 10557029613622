export const CockpitIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.66667C8.648 2.66667 2.66667 8.648 2.66667 16C2.66667 23.352 8.648 29.3333 16 29.3333C23.352 29.3333 29.3333 23.352 29.3333 16C29.3333 8.648 23.352 2.66667 16 2.66667ZM16 0C24.8227 0 32 7.17733 32 16C32 24.8227 24.8227 32 16 32C7.17733 32 0 24.8227 0 16C0 7.17733 7.17733 0 16 0ZM5.30533 12.7907C5.49333 12.2213 5.724 11.6653 5.99333 11.1347L9.26533 12.6093C8.976 13.1333 8.74533 13.6893 8.576 14.2653L5.30533 12.7907ZM22.0693 11.5853C21.708 11.1133 21.296 10.6867 20.844 10.3093L23.364 7.68533C23.8013 8.07733 24.2133 8.50533 24.588 8.96133L22.0693 11.5853ZM19.7827 9.56667C19.2773 9.272 18.7413 9.03867 18.1853 8.86933L19.564 5.444C20.1107 5.63333 20.6467 5.868 21.1613 6.14267L19.7827 9.56667ZM23.4613 14.388C23.3 13.808 23.0773 13.2493 22.7973 12.7213L26.0907 11.2987C26.3533 11.836 26.576 12.396 26.7547 12.9653L23.4613 14.388ZM10.8827 6.12133C11.3987 5.848 11.936 5.616 12.4827 5.42933L13.848 8.86C13.292 9.02667 12.7547 9.25867 12.248 9.552L10.8827 6.12133ZM7.41467 8.96133C7.79067 8.50533 8.20133 8.076 8.63867 7.68533L11.1587 10.3093C10.7067 10.6867 10.296 11.1147 9.93467 11.5853L7.41467 8.96133ZM15.136 4.88267C15.428 4.86 15.7187 4.848 16.0013 4.848C16.284 4.848 16.576 4.86 16.868 4.88267V8.59467C16.58 8.56133 16.2893 8.544 16.0013 8.544C15.7133 8.544 15.4227 8.56133 15.136 8.59467V4.88267ZM16 23.2587C17.7253 23.2587 19.124 21.86 19.124 20.1347C19.124 18.9573 18.472 17.932 17.5107 17.4L16 10.32L14.4893 17.3987C13.5267 17.9307 12.876 18.956 12.876 20.1333C12.876 21.86 14.2747 23.2587 16 23.2587Z"
      fill="white"
    />
    <mask id="mask0" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.66667C8.648 2.66667 2.66667 8.648 2.66667 16C2.66667 23.352 8.648 29.3333 16 29.3333C23.352 29.3333 29.3333 23.352 29.3333 16C29.3333 8.648 23.352 2.66667 16 2.66667ZM16 0C24.8227 0 32 7.17733 32 16C32 24.8227 24.8227 32 16 32C7.17733 32 0 24.8227 0 16C0 7.17733 7.17733 0 16 0ZM5.30533 12.7907C5.49333 12.2213 5.724 11.6653 5.99333 11.1347L9.26533 12.6093C8.976 13.1333 8.74533 13.6893 8.576 14.2653L5.30533 12.7907ZM22.0693 11.5853C21.708 11.1133 21.296 10.6867 20.844 10.3093L23.364 7.68533C23.8013 8.07733 24.2133 8.50533 24.588 8.96133L22.0693 11.5853ZM19.7827 9.56667C19.2773 9.272 18.7413 9.03867 18.1853 8.86933L19.564 5.444C20.1107 5.63333 20.6467 5.868 21.1613 6.14267L19.7827 9.56667ZM23.4613 14.388C23.3 13.808 23.0773 13.2493 22.7973 12.7213L26.0907 11.2987C26.3533 11.836 26.576 12.396 26.7547 12.9653L23.4613 14.388ZM10.8827 6.12133C11.3987 5.848 11.936 5.616 12.4827 5.42933L13.848 8.86C13.292 9.02667 12.7547 9.25867 12.248 9.552L10.8827 6.12133ZM7.41467 8.96133C7.79067 8.50533 8.20133 8.076 8.63867 7.68533L11.1587 10.3093C10.7067 10.6867 10.296 11.1147 9.93467 11.5853L7.41467 8.96133ZM15.136 4.88267C15.428 4.86 15.7187 4.848 16.0013 4.848C16.284 4.848 16.576 4.86 16.868 4.88267V8.59467C16.58 8.56133 16.2893 8.544 16.0013 8.544C15.7133 8.544 15.4227 8.56133 15.136 8.59467V4.88267ZM16 23.2587C17.7253 23.2587 19.124 21.86 19.124 20.1347C19.124 18.9573 18.472 17.932 17.5107 17.4L16 10.32L14.4893 17.3987C13.5267 17.9307 12.876 18.956 12.876 20.1333C12.876 21.86 14.2747 23.2587 16 23.2587Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);
