import CSS from 'csstype';
import theme from '../../theme';
import { Link } from 'react-router-dom';

interface SidebarIconProps {
  icon: JSX.Element;
  alt: string;
  target: string;
  selected: boolean;
  disabled: boolean;
}

export const SidebarIconButton = (props: SidebarIconProps) => {
  return (
    <>
      {props.disabled ? (
        <div style={{ ...iconStyle, cursor: 'not-allowed' }}>
          {props.icon}
        </div>
    ) : (
        <Link
          to={props.target}
          style={{ ...iconStyle, backgroundColor: props.selected ? theme.palette.secondary.main : undefined }}
        >
          {props.icon}
        </Link>
      )}
    </>
  );
};

const iconStyle: CSS.Properties = {
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
};
