import { ArtikelDetails } from "../models";
import { AnnualTotalAmount, Pole, StandsicherheitDto, StandsicherheitRenderData } from "../models/Standsicherheit";

export namespace Mapper {
  export function mapToStandsicherheitModel(metaData?: StandsicherheitDto): StandsicherheitRenderData  {

    if (!metaData) {
      return {
        annualSums: [],
        comment: "not immplemented yet",
        poles: [],
        availableYearsToOrder: [],
        orderedYears: [],
        yearsToDisplay: [],
      };
    }

    const poleArray : Pole[] = []; 
    const yearsToDisplay: number[] = [];
    const availableYearsToOrder: number[] = [];
    const orderedYears: number[] = [];
    

    //map the data from the server to the data for the frontend
    const annualSums: AnnualTotalAmount[]  = [];
    metaData.jahresSummen.forEach(j => {



      j.mastPreise.forEach(mastpreise => {
        let existingPole = poleArray.find(p => p.poleType === mastpreise.mastArt)
        if (!existingPole) {
          existingPole = {
            poleType: mastpreise.mastArt,
            yearData:[]
           }
            poleArray.push(existingPole);
          }

          if( existingPole.yearData.find(y => y.year === Number(j.jahr))){
            return;
          }
          existingPole.yearData.push({
            year: Number(j.jahr),
            availableToOrder: mastpreise.anzahlPrognose,
            ordered: mastpreise.anzahlAuftrag,
            netPrice: mastpreise.einzelPreis,
            }
            )
          }
           )
           }
    )

 
    metaData.jahresSummen.forEach(j => {   
      if(yearsToDisplay.includes(Number(j.jahr))){
        return;
      }
      yearsToDisplay.push(Number(j.jahr));
    });



    metaData.jahresSummen.forEach(j => {
      if (j.availableForOrder) {
        availableYearsToOrder.push(Number(j.jahr));
      }
      if(j.isOrdered){
        orderedYears.push(Number(j.jahr));
      }
    });
  



    return {
      poles: poleArray,
      annualSums,
      yearsToDisplay,
      availableYearsToOrder,
      orderedYears,
      comment: "not immplemented yet",
    };
 
  }

 
}