import { getScaledIconSize } from ".."
import { IconProps } from "../index.types"

export const LightPointVector = (props: IconProps) => (
  <svg {...getScaledIconSize({width:31, height:54}, props)} viewBox="0 0 31 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.938232 51.8474H9.56336V53.7599H0.938232V51.8474Z" fill="#EE7700"/>
    <path d="M3.30847 34.4578H7.20603V51.1906H3.30847V34.4578Z" fill="#EE7700"/>
    <path d="M24.8884 0.640008H19.4305C11.0045 0.640008 4.15845 7.48561 4.15845 15.912V33.7997H6.34965V15.912C6.34965 8.69445 12.2194 2.83121 19.4305 2.83121H19.7625C19.7361 2.93727 19.7162 3.05029 19.7162 3.1633C19.7162 4.57087 21.9871 5.67975 24.8819 5.67975C27.7837 5.67975 30.0476 4.5774 30.0476 3.1633C30.061 1.74234 27.7901 0.639984 24.8883 0.639984L24.8884 0.640008ZM24.8884 5.23513C23.1886 5.23513 21.668 4.81689 20.8315 4.19948C21.7476 3.25646 23.8328 2.63255 26.15 2.63255C27.4381 2.63255 28.6201 2.80531 29.6159 3.15733C29.6228 4.28514 27.458 5.23506 24.8883 5.23506L24.8884 5.23513Z" fill="#EE7700"/>
  </svg>
);

