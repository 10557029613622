import { WithId } from ".";

interface GenericWarenkorbKonfiguration<O> {
  artikelId: string;
  optionen: O[];
  bildIds?: string[];
  pdfDokumentId?: string;
  eigenschaften?: EigenschaftsKonfiguration[];
  parameter?: ParameterKonfiguration[];
  artikelName?: string;
}

export interface WarenkorbKonfiguration extends GenericWarenkorbKonfiguration<WarenkorbKonfigurationOption> {}

export interface WarenkorbKonfigurationOption {
  optionId: string;
  auswahlId: string;
}

export interface WarenkorbPositionOption extends WarenkorbKonfigurationOption {
  optionName: string;
  auswahlName: string;
  sequenzNummer: number;
}

export interface EigenschaftsKonfiguration {
  eigenschaftId: string;
  wert: string;
}

export interface SSPParameterValue {
  jahr: number;
  anzahlHolzMast: number;
  anzahlStandartMast: number;
}

export interface ParameterKonfiguration {
  parameterId: string;
  wert: string | SSPParameterValue;
}

export interface WarenkorbPositionKonfiguration extends GenericWarenkorbKonfiguration<WarenkorbPositionOption> {
  artikelName: string;
}

export interface SSPBasketPosition extends WithId {
  nettoPreis: number;
  bearbeitetVon: Person;
  bearbeitetAm: Date;
  konfiguration: WarenkorbKonfiguration;
  zusatzInformationen: ZusatzInformationen; 
}

export interface WarenkorbPosition extends WithId {
  nettoPreis: number;
  bearbeitetVon: Person;
  bearbeitetAm: Date;
  konfiguration: WarenkorbPositionKonfiguration;
  preisKomponenten: PreisKomponente[];
  zusatzInformationen: ZusatzInformationen; 
}

export interface Person {
  vorname: String;
  nachname: String;
  email?: String;
}

export interface WarenkorbTO extends WithId {
  positionen: WarenkorbPosition[];
  nettoPreis: number;
  bruttoPreis: number;
}
 
export interface ZusatzInformationen {
  leuchtstelle: LightPointInfo;
  jahr?: string
  holz?: string;
  standart?: string;
}

export interface PreisKomponente {
  beschreibung: string;
  leistung: string;
  nettoPreis: number;
  anzahl: number;
  einzelpreis: number;
}

export interface LightPointInfo {
  Stadt?: string;
  Plz?: string;
  Strasse?: string;
  Hausnummer?: string | null;
  Netzgebiet?: string;
}

export enum WarenkorbPositionParamterId {
  LightPoint = "LSTNR"
}
