import { Box, Button } from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import { ContactLink } from "../components/ContactView/ContactLink";
import { agbLinkPath } from "../routes/routes";

export const Footer = () => {
  return (
    <Box className="text-right my-3">
      <ContactLink type="anprech-person" withIcon/>
      <Button href={agbLinkPath} target="_blank" color='secondary' variant="text" startIcon={<ArticleIcon />}>AGBs</Button>
    </Box>
  );
}