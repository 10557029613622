import { useAppContext } from "../../context/AppContext/AppContext";

enum Item {
  Gemeinden = "gemeinden",
  Artikel = "artikel",
  Leuchtstellen = "leuchtstellen",
  Warenkorb = "warenkorb",
  Dateien = "dateien",
  Bilder = "bilder",
  Order = "order",
  Pdf = "pdf"
}

type ItemBasePathLiteralExtended = `${ItemBasePathLiteral}/${string}`;
export type ApiItemPath = ItemBasePathLiteralExtended | ItemBasePathLiteral;

export function useApiItemPath() {
  const itemBasePath = useItemBasePath();
  const { customerScope } = useAppContext();

  return {
    gemeinden: itemBasePath.gemeinden,
    leuchtstellen: {
      byKommune: itemBasePath.leuchtstellen,
      byQuery: `${itemBasePath.leuchtstellen}/suche`,
    },
    artikel: {
      all: itemBasePath.artikel,
      details: (id: string) => `${itemBasePath.gemeinden}/${itemBasePath.artikel}/${id}/details` as ApiItemPath,

    },
    warenkorb: {
      add: `${itemBasePath.warenkorb}/positionen`,
      get: itemBasePath.warenkorb
    },
    dateien: {
      get: (id: string) => `${itemBasePath.warenkorb}/dateien/${id}` as ApiItemPath
    },
    bilder: itemBasePath.bilder,
    order: itemBasePath.order,
    pdf: {
      create: itemBasePath.pdf
    }
  } as const;
}

type ItemBasePath = ReturnType<typeof useItemBasePathStruct>;

type ItemBasePathLiteral = ItemBasePath[keyof ItemBasePath];

type ItemBasePathObject = {
  [k in Item]: ItemBasePathLiteral;
}

export function useItemBasePath(): ItemBasePathObject {
  return useItemBasePathStruct();
}

function useItemBasePathStruct() {
  const { customerScope } = useAppContext();

  return {
    artikel: Item.Artikel,
    gemeinden: `${Item.Gemeinden}/${customerScope}`,
    order: `${Item.Gemeinden}/${customerScope}/bestellungen`,
    leuchtstellen: `${Item.Gemeinden}/${customerScope}/${Item.Leuchtstellen}`,
    warenkorb: `${Item.Gemeinden}/${customerScope}/${Item.Warenkorb}`,
    dateien: `${Item.Gemeinden}/${customerScope}/${Item.Warenkorb}/dateien`,
    bilder: `${Item.Gemeinden}/${customerScope}/${Item.Warenkorb}/bilder`,
    pdf: `${Item.Gemeinden}/${customerScope}/${Item.Warenkorb}/pdf`,
  } as const;
}