import { Image } from "../../models/Image";
import { 
  Button, 
  Card, 
  CardActions, 
  CardContent, 
  CardMedia, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid, 
  IconButton, 
  Typography 
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { When } from "react-if";
import Tooltip from '@mui/material/Tooltip';
import { Utils } from "../../utils";

interface ImageListDialogProps {
  open: boolean;
  onClose: () => void;
  onDeleteAll?: () => void;
  onDelete?: (id: string) => void;
  images: Image[];
}

export function ImageDialog(props: ImageListDialogProps) {
  function handleOnDeleteAll() {
    props.onClose();
    props?.onDeleteAll?.();
  }

  // show max 5 images in a row
  const imageGridBreakpoint = props.images.length > 4 ? 12/5 : 12 / props.images.length;
  
  const showDeleteButton = !!props.onDelete && props.images.length > 1;

  return (
    <Dialog
      maxWidth={"lg"}
      open={props.open && !!props.images.length}
      onClose={props.onClose}
      scroll="paper"
    >
      <DialogTitle><b>Bilder</b></DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
        {props.images.map((image, index) =>
          <Grid key={image.fileName + "ImageDialog" + index} item xs={imageGridBreakpoint}>
            <Card elevation={4} sx={{ marginBottom: "10px" }}>
              <When condition={() => showDeleteButton}>
                <CardActions className="justify-end">
                  <Tooltip title="Löschen">
                    <IconButton onClick={() => props.onDelete?.(image.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </When>
              <CardMedia
                title={image.fileName}
                component="img"
                sx={{ height: "80%", maxHeight: "30rem" }}
                image={Utils.getDataUrl(image)}
                alt={image.fileName}
              />
              <CardContent>
                <Tooltip title={image.fileName}>
                  <Typography 
                    className="px-1 overflow-hidden text-black" 
                    variant="h6"
                  >
                    {image.fileName}
                  </Typography>
                </Tooltip>
              </CardContent>
            </Card>
          </Grid>
        )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={props.onClose}>Schließen</Button>
        <When condition={!!props.onDeleteAll}>
          <Button color="error" onClick={handleOnDeleteAll}>Löschen</Button>
        </When>
      </DialogActions>
    </Dialog>
  );
}