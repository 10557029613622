import { WarenkorbPosition, WarenkorbPositionParamterId } from "../models/Warenkorb";

export namespace ProductUtils {
  export const getLightPointParam = (position: WarenkorbPosition) => {
    return position.konfiguration.parameter?.find(par => 
      par.parameterId === WarenkorbPositionParamterId.LightPoint
    );
  }

  export const isLightPointPosition = (position: WarenkorbPosition) => !!getLightPointParam(position);
}