import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { ReactNode, useState } from "react";
import { WarenkorbPosition, WarenkorbPositionOption } from "../../../models/Warenkorb";
import theme from "../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { v4 as uuid } from 'uuid';
import { Utils } from "../../../utils/Utils";
import { LightPointAddress } from "../../../parts/LightPointAddress";
import { useCartOverviewContext } from "../../../context/CartOverviewContext/CartOverviewContext";
import EditMenu from "../EditMenu";

interface RowProps {
  warenkorbPosition: WarenkorbPosition;
  ColapsableComopnent: ReactNode | null;
}

interface EditableBasketPositionTableRowProps extends RowProps{
  editable: true;
  onEdit: (wk: WarenkorbPosition) => void;
  onDelete: (wk: WarenkorbPosition) => Promise<void>;
  onShowImages: (ids: string[]) => void;
}

interface BestelluebersichtRowProps extends RowProps {
  editable: false;
  index: number;
}

export type LightpointBasketPositionTableRowProps = EditableBasketPositionTableRowProps | BestelluebersichtRowProps;
export const isWarenKorbRowProps = (
  p: EditableBasketPositionTableRowProps | BestelluebersichtRowProps
) : p is EditableBasketPositionTableRowProps => p.editable;

export function LightpointBasketPositionTableRow(props: LightpointBasketPositionTableRowProps) {
  const [rowOpen, setUnfoldRow] = useState(false);
  const { lightpointBasketTableHeadPosition: tableHeadPosition } = useCartOverviewContext();
  const { warenkorbPosition } = props;

  if (!tableHeadPosition) {
    return null;
  }


  return <>
    <TableRow id="cart-tabel" key={warenkorbPosition.id}>
      {isWarenKorbRowProps(props) ? null :
        <TableCell>{props.index}</TableCell>
      }
      <TableCell>
        {warenkorbPosition.konfiguration.artikelName}
      </TableCell>
      <TableCell>
        { warenkorbPosition.zusatzInformationen?.leuchtstelle ?
        <LightPointAddress cleanBreak lightPointInfo={warenkorbPosition.zusatzInformationen.leuchtstelle} />
        : null
        }
      </TableCell>
      {renderOptionen(warenkorbPosition.konfiguration.optionen, tableHeadPosition.konfiguration.optionen )}
      <TableCell>
        {warenkorbPosition.bearbeitetVon?.vorname + " " + warenkorbPosition.bearbeitetVon?.nachname}
      </TableCell>
      <TableCell className="text-right">
        {Utils.formatToLocalDate(new Date(warenkorbPosition.bearbeitetAm))}
      </TableCell>
      <TableCell id="cart-netto-price" className="whitespace-nowrap text-right">
        {Utils.formatToEUR(warenkorbPosition?.nettoPreis)}
      </TableCell>
      { !isWarenKorbRowProps(props) ? null :
        <TableCell>
          <EditMenu 
            isEditable={true}
            onEdit={() => props.onEdit(warenkorbPosition)} 
            onDelete={() => props.onDelete(warenkorbPosition)} 
          />
        </TableCell>
      }
      {!isWarenKorbRowProps(props) ? null :
        <TableCell>
          {!warenkorbPosition.konfiguration.bildIds?.length ? null :
            <IconButton
              size="small"
              onClick={() => props.onShowImages(warenkorbPosition.konfiguration.bildIds!)}
            >
              <AttachFileIcon />
            </IconButton>
          }
        </TableCell>
      }
      {!props.ColapsableComopnent ? null :
      <TableCell>
          <IconButton
            size="small"
            onClick={() => setUnfoldRow(!rowOpen)}
          >
            {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
      </TableCell>
      }
    </TableRow>
    {!props.ColapsableComopnent ? null :
      <TableRow>
        <TableCell style={{ padding: 0, backgroundColor: theme.palette.grey[300] }} colSpan={12}>
          <Collapse className="px-4" in={rowOpen} timeout="auto" unmountOnExit>
            {props.ColapsableComopnent}
          </Collapse>
        </TableCell>
      </TableRow>
    }
  </>;
}

function renderOptionen(tableBodyOptions: WarenkorbPositionOption[], tableHeadOptions: WarenkorbPositionOption[]) {
  const elements: JSX.Element[] = [];
  tableBodyOptions.sort((a, b) => a.sequenzNummer - b.sequenzNummer)
  for (let i = 0; i < tableHeadOptions.length; i++) {
    const rowOption = tableBodyOptions[i];
    const rowOptionSequenzNummerNotForThisColumn = () => (
      tableHeadOptions?.[i] && 
        (tableHeadOptions[i].sequenzNummer !== rowOption?.sequenzNummer)
    );
    while (rowOptionSequenzNummerNotForThisColumn()) {
      elements.push(<TableCell key={uuid()} />);
      i++;
    }
    if (rowOption) {
      elements.push(
        <TableCell className="whitespace-nowrap" key={uuid()}>
          {rowOption.auswahlName}
        </TableCell>
      );
    }
  }
  return elements;
}