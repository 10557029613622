import { Box, Typography } from "@mui/material";

interface StepHeaderProps {
  title: string;
  description: JSX.Element;
}

export const StepHeader = (props: StepHeaderProps) => {
  return (
    <Box className="mb-10">
      <Typography variant="h2" marginBottom={1}>{props.title}</Typography>
      <Typography>{props.description}</Typography>
    </Box>
  );
}